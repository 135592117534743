import React, { useCallback, useEffect } from "react";
import { RequestSystem } from "../api/system-requests";
import { ISession } from "../interface/session";
import { useNotifications } from "./notifications";

const blankSession: ISession = { id: 'new', type: 'guest', valid: false, permissions:[]};


const AUTH_KEY:string = process.env.REACT_APP_AUTH_KEY ? process.env.REACT_APP_AUTH_KEY : 'auted'
type AuthContextType = {
  auth: ISession;
  setAuth?: (value: ISession) => void;
  logout?: () => void;
  hasPermission?: (roles: string[]) => boolean;
};

export const AuthContext = React.createContext<AuthContextType | undefined>(
  {
    auth: { ...blankSession }
  }
);

type Props = {
  children: React.ReactNode;
};
export const AuthProvider = ({ children }: Props) => {
  const INTERVAL_TIME = 660000;
  const localStorageStr = localStorage.getItem(AUTH_KEY);
  //console.log(localStorageStr)
  const { addNotification } = useNotifications()!;

  let initSession: ISession = { ...blankSession };
  if (localStorageStr) {
    initSession = JSON.parse(localStorageStr);
  }

  const [init, setInit] = React.useState<boolean>(false);
  const [auth, setAuth] = React.useState<ISession>(initSession);

  let interval: any;
  const logout = () => {

    const newSession: ISession = { ...blankSession };
    console.log("auth:LOGOUT", newSession);
    localStorage.removeItem(AUTH_KEY);
    clearLoop();
    RequestSystem(addNotification, '').then(session => {
      console.log("auth:LOGOUT", session);
      setAuth(session);
      localStorage.setItem(AUTH_KEY, JSON.stringify(session));
      interval = setInterval(loop, INTERVAL_TIME)

    }).catch(() => {
      setAuth({ ...initSession })
    });
    //localStorage.setItem('auth2', JSON.stringify(newSession));
    // setTimeout(() => {
    //   console.log("auth:LOGOUT", auth)
    //   requestSystem(newSession);
    // }, 1000);
  }

  const hasPermission = (roles: string[]): boolean => {
   // console.log("hasPermission", auth.user?.permissions)
    let result = false;
    roles.push('**');
    for (let index = 0; index < roles.length; index++) {
      const requiredRole: string = roles[index];
      if (auth.user && auth.user.permissions){
        //console.log(requiredRole, auth.user.permissions.findIndex((dd: string) => dd === requiredRole))
        if (auth.user.permissions.findIndex((dd: string) => dd === requiredRole) >= 0) {
          result = true;
        }
      }
    }
    return result;
    //    if(result===true){
  }

  //auto save to context!
  useEffect(() => {
    console.log("auth auth:useEffect", auth)
   // localStorage.setItem('auth2', JSON.stringify(auth));
  }, [auth]);


  const loop = useCallback(() => {
    const fuck = localStorage.getItem(AUTH_KEY);
    if (fuck !== null) {
      const off = JSON.parse(fuck)
      console.log(off);
      let token = '';
      if (off && off.token) {
        token = off.token;
      }
      RequestSystem(addNotification, token).then(session => {
        console.log("RequestSystemLoop", session);
        localStorage.setItem(AUTH_KEY, JSON.stringify(session));
        setAuth(session);
      }).catch(() => {
        setAuth({ ...initSession })
      });
    }

  }, [setAuth])

  const clearLoop = useCallback(() => {
    clearInterval(interval);
  }, [interval])

  useEffect(() => {
    return () => {
      clearLoop();
      console.log("auth cleaned up");
    };
  }, []);




  useEffect(() => {
    if (init === false) {
      setInit(true);
      console.log("auth auth:useEffect:init");
      let token = '';
      if (auth && auth.token) {
        token = auth.token;
      }
      RequestSystem(addNotification, token).then(session => {
        console.log("RequestSystem", session);
        setAuth(session);
        localStorage.setItem(AUTH_KEY, JSON.stringify(session));
        interval = setInterval(loop, INTERVAL_TIME)
      }).catch(() => {
        setAuth({ ...initSession })
      });
    }

  });

  const value = React.useMemo(
    () => ({
      auth,
      setAuth,
      logout,
      hasPermission
    }),
    [ auth,
      setAuth,
      logout,hasPermission]
  );

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => React.useContext(AuthContext);