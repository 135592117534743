
import React from 'react';
import { Nav } from "react-bootstrap";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import {  IUserSellerProfile } from "../../interface/user";

import MyProfileHome from "./MyProfileHome";
import MyProfileOrders from "./MyProfileOrders";

export interface IProfileSubpageProp {
    user: IUserSellerProfile;

}


const MyProfile = () => {
    let match = useRouteMatch();

    // let [loading, setLoading] = useState<boolean>(false);
    // let [user, setUser] = useState<IUserSellerProfile>();

        // useEffect(() => {
        //     //   console.log("get product types");
        //     //  const query = qs.parse(props.location.search.substring(1));
        //     //setQuery({ name: query.cat });

        //     if (loading === true) {



        //     }
        // }, [match])

    return (
    <div className="loginbody container">
        <h2>Profile</h2>

        <div className="">
            <Nav className="">
                <Nav.Item className="">
                    <Nav.Link className="profile-class font-weight-bold" to={`${match.url}/`} as={Link}>My Profile</Nav.Link>
                </Nav.Item>
            <Nav.Item>
                <Nav.Link className="profile-class font-weight-bold" to={`${match.url}/orders`} as={Link}>My Orders</Nav.Link>
            </Nav.Item>
        </Nav>
        <hr className="gold" />
        </div>
        

        <Switch>
            <Route path={`${match.path}`} exact>
              <MyProfileHome />
            </Route>
            <Route path={`${match.path}/orders`}>
                <MyProfileOrders />
            </Route>
        </Switch>
    </div>);
}

export default MyProfile;