import arrayMutators from 'final-form-arrays';
import React from "react";
import { Field, Form } from 'react-final-form';
import { ICheckout } from '../interface/checkout';


interface IAdminProductsSearchForm {
    checkout: ICheckout;
    setCheckout?: (check: ICheckout) => void
}

const AdminCheckoutShipping = ({ checkout, setCheckout }: IAdminProductsSearchForm) => {
    const onSubmit = (data: any) => {

    }
    return <>{checkout.type !== 'shop' && checkout.status === 'shipping-await' && <div className="row">
        <h2>Shipping Info</h2>

        <Form <any>

            onSubmit={onSubmit}

            initialValues={{}}
            mutators={{
                // expect (field, value) args from the mutator
                ...arrayMutators,
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                }
            }}
            render={({ handleSubmit, form, submitting, pristine, values }) => {


                return <form onSubmit={handleSubmit}>
                    <div className='row'>

                        <div className='col-12'>
                            Shipping Code
                            <Field
                                name={`_description`}
                                component='input'
                                type='text'
                                className="form-control"
                                id="description"
                            />



                        </div>
                        <div className='col-12'>
                            <br />
                            <button type="button" className='btn btn-warning' onClick={() => {

                            }}>Posted</button>

                        </div>
                    </div>
                </form>

            }} />


    </div >}</>

}

export default AdminCheckoutShipping
