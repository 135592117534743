import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { RequestGetStocks } from '../../api/stock-requests';
import { RequestGetStores } from '../../api/store-requests';
import { useNotifications } from '../../context/notifications';



const AdminQRScanner = () => {
    const [result, setResult] = useState<any>();
    const [thing, setThing] = useState<any>();
    const [type, setType] = useState<string>();

    const { addNotification } = useNotifications()!;
    const handleBarcode = (barcode: any) => {
        console.log('scanned result', barcode, barcode.text);
        setResult(barcode)
        const result: string = barcode.text;
        try {
            const code = parseInt(result.substring(1));

            if (result.toLowerCase().startsWith('s')) {
                RequestGetStocks(addNotification, { barcode: code })
                    .then(stock => {
                        setType('stock')
                        setThing(stock[0]);
                    })
            } else if (result.toLowerCase().startsWith('l')) {

                RequestGetStores(addNotification, { barcode: code })
                    .then(store => {
                        setType('store')
                        setThing(store[0]);
                    })
            }
        } catch (ex) {
            console.log("caught exc", ex)
        }

    }
    return (
        <div className="row">
            {thing === undefined && <div className="col-12">


            </div>}
            {thing && <div className="col-12">
                {type === 'stock' && <>
                    <h2>{thing.name}</h2>
                    {JSON.stringify(thing)}
                    <Link className="btn btn-info" to={`/admin/store/${thing.id}`}>goto Store</Link>
                </>}
                {type === 'store' && <>
                    <h2>{thing.name}</h2>
                    {JSON.stringify(thing)}
                    <Link className="btn btn-info" to={`/admin/store/${thing.id}`}>goto Store</Link>

                </>}


                <button className="btn btn-danger" type="button" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    setThing(undefined);
                    setType(undefined);
                }}>Reset Search</button>
            </div>}
        </div>
    );
}

export default AdminQRScanner;