
import React from 'react';
const PaymentGatewayResponse = ()=> {
    return (
        <>
           Payment Gateway Response
        </>
    );
}

export default PaymentGatewayResponse;