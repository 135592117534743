import React from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../context/auth';
import RequestForm from './RequestForm';
const Request = () => {
    const { auth } = useAuth()!;
    return (
        <>
            <Helmet>
                <title>Request Parts / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <div className="loginbody container">
                <h2>Request Parts</h2>
                <p>To request parts please use the form below. </p>
                <RequestForm />
            </div>

        </>
    );
}

export default Request;