import { faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { RequestGetCheckouts } from '../../api/checkout-requests';
import { RequestSysNotificationFunction } from '../../api/sys-notification-requests';
import DataTable from '../../components/system/DataTable';
import DebugButton from '../../components/system/DebugButton';
import Loading from '../../components/system/loading';
import { useAuth } from '../../context/auth';
import { useNotifications } from '../../context/notifications';
import { ICheckout } from '../../interface/checkout';
import { IContact } from '../../interface/contact';
import { ILog } from '../../interface/log';
import { ISysNotification } from '../../interface/sys-notification';

const AdminHome = () => {
    const [checkouts, setCheckouts] = useState<ICheckout[]>();
    const [contacts, setContacts] = useState<IContact[]>();

    const [loaded, setLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [redirectTo, setRedirectTo] = useState<string>('');
    const [notifications, setNotes] = useState<ISysNotification[]>();


    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;
    const [logs, setLogs] = useState<ILog[]>([])

    useEffect(() => {
        if (loading === true) {
            setLoading(false)


            // const prodCall = () => {


            // const getContacts = () => {

            //     return RequestGetContacts(addNotification, { status: 'new' }).then(user => {
            //         setContacts(user);
            //     })
            // }


            const getCheckouts = () => {

                return RequestGetCheckouts(addNotification, { status: 'payment-complete' }).then(user => {
                    setCheckouts(user);
                })
            }
            const getNotes = () => {

                return RequestSysNotificationFunction(addNotification, 'my', 'all').then(my => {
                    setNotes(my);
                })
            }


            Promise.allSettled([getCheckouts(), getNotes()]).then(() => {
                setLoaded(true)
            });
        }
    }, []);

    const readNotification = (note: ISysNotification, rredirect: boolean) => {
        RequestSysNotificationFunction(addNotification, 'read', note.id).then(my => {
            console.log(my)

            if (notifications && my === true) {
                const dd = [...notifications]
                const index = dd.findIndex(sdd => sdd.id === note.id)

                dd.splice(index, 1)
                setNotes(dd)

                if (rredirect === true) {
                    setRedirectTo(note.url)
                    setRedirect(true)
                }
            }
        })
    }
    return (<div>


        <div className="col">
            {checkouts && <>
                <DataTable

                    colHeadings={[
                        { name: "Name", field: "name", sortable: true },
                        { name: "Status", field: "status", sortable: true },


                        {
                            name: "Total", field: "totalTotal", sortable: true, r: (value: any, index) => {
                                return <>

                                    {value.totalTotal !== null && value.totalTotal && value.totalTotal.toFixed(2)}

                                </>
                            }
                        },

                        {
                            name: "Actions", field: "id", r: (value: any, index) => {
                                return <div className="btn-group">
                                    <DebugButton data={value} alwaysDisplay={true} />
                                    <Link className="btn btn-primary" to={`/admin/checkouts/${value.id}`}>Edit</Link>

                                </div>;
                            }, sortable: true
                        },

                    ]}

                    data={checkouts.reverse()}
                />

                <DebugButton data={checkouts} /></>}
        </div>
        <div className="col">
            {contacts && <>
                <DataTable

                    colHeadings={[
                        { name: "Name", field: "name", sortable: false },

                        { name: "Status", field: "status", sortable: false },
                        { name: "Type", field: "type", sortable: false },

                        {
                            name: "Actions", field: "id", r: (value: any, index) => {
                                return <div className="btn-group">
                                    <DebugButton data={value} alwaysDisplay={true} />
                                    <Link className="btn btn-primary" to={`/admin/contacts/${value.id}`}>View</Link>

                                </div>;
                            }, sortable: false
                        },

                    ]}

                    data={contacts}
                />
                <DebugButton data={contacts} /></>}
        </div>


        {redirect === true && <Redirect to={redirectTo} />}
        {loaded === false ? <Loading /> : <div className="col-10">
            <div className="row">

                {notifications && notifications.length > 0 && <div className="col-6">
                    {notifications.map((note, index) => {
                        return <div>
                            <h2>{note.name}</h2>
                            <p>{note.description}</p>
                            <div className="btn-group">
                                <button className="btn btn-danger" type="button" onClick={(ev) => {
                                    ev.preventDefault();
                                    readNotification(note, false)
                                }}><FontAwesomeIcon icon={faTrash} /></button>
                                {note.url !== '' && <button className="btn btn-success" type="button" onClick={(ev) => {
                                    ev.preventDefault();
                                    readNotification(note, true)
                                }}><FontAwesomeIcon icon={faSearch} /></button>}
                                <DebugButton data={note} />
                            </div>
                        </div>
                    })}</div>}



            </div>
        </div>}
    </div>);
}

export default AdminHome;