import { IUser } from "../interface/user";
import axiosInstance from "./instance";

export const RequestGetUsers = (addNotification: any, data?: any): Promise<IUser[]> => {
    return new Promise<IUser[]>((resolve, reject) => {
        let url = `/users/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                const type = typeof value;
                console.log(type)
                if(type === 'object'){
                    url += key + '=' +  JSON.stringify(value) + "&";
                } else {
                    url += key + '=' + value + "&";
                }
               
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}
export const RequestGetUsersPost = (addNotification: any, data?: any): Promise<IUser[]> => {
    return new Promise<IUser[]>((resolve, reject) => {
        let url = `/users/__search`;
        // if (data) {
        //     url += "?";
        //     for (let [key, value] of Object.entries(data)) {
        //         const type = typeof value;
        //         console.log(type)
        //         if(type === 'object'){
        //             url += key + '=' +  JSON.stringify(value) + "&";
        //         } else {
        //             url += key + '=' + value + "&";
        //         }
               
        //     }
        // }
        axiosInstance(addNotification).post(url, data).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetUser = (addNotification: any, id: string): Promise<IUser> => {
    return new Promise<IUser>((resolve, reject) => {

        axiosInstance(addNotification).get(`/users/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewUser = (addNotification: any, thing: IUser): Promise<IUser> => {
    return new Promise<IUser>((resolve, reject) => {

        axiosInstance(addNotification).post(`/users/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteUser = (addNotification: any, thing: IUser): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/users/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateUser = (addNotification: any, user: IUser): Promise<IUser> => {
    return new Promise<IUser>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/users/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestUserFunction = (addNotification: any, func:string, id: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/users/${func}/${id}`;
        
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
