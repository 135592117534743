import queryString from 'query-string';
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { RequestDeleteContact, RequestGetContacts, RequestSetContactStatus } from '../../../api/contact-requests';
import { RequestNewLog } from "../../../api/log-requests";
import DataTable from "../../../components/system/DataTable";
import DateDisplay from "../../../components/system/DateDisplay";
import DebugButton from "../../../components/system/DebugButton";
import Loading from "../../../components/system/loading";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { IContact } from "../../../interface/contact";
import { ELogType, ESeverity } from "../../../interface/log";
import AdminContact from './Admin.Contact';



const AdminContactsPge = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [things, setThings] = useState<IContact[]>();
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;
    const [searchStore, setSeachStore] = useState<string>();
    const location = useLocation();
    let search: any = queryString.parse(location.search)

    useEffect(() => {
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react stores onunload'
        });
    }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }
    const onArchive = (value: IContact) => {
        try {

            setLoading(true);
            RequestSetContactStatus(addNotification, value, 'archived').then(res => {
                console.log(res);
                let thingsD: IContact[] = [];
                if (things) {
                    thingsD = [...things];
                }
                const index = thingsD.findIndex(thing => thing._id === value._id);


                thingsD[index] = res;
                window.location.reload();
                setThings(thingsD);
                setLoading(false);
                addNotification && addNotification('Success', 'Contact Updated!', ENotificationType.Primary);
            });

        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Contacts - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }
    const onUnArchive = (value: IContact) => {
        try {

            setLoading(true);
            RequestSetContactStatus(addNotification, value, 'new').then(res => {
                console.log(res);
                let thingsD: IContact[] = [];
                if (things) {
                    thingsD = [...things];
                }
                const index = thingsD.findIndex(thing => thing._id === value._id);

                window.location.reload();
                thingsD[index] = res;

                setThings(thingsD);
                setLoading(false);
                addNotification && addNotification('Success', 'Contact Updated!', ENotificationType.Primary);
            });

        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Contacts - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }



    useEffect(() => {
        try {
            if (loading === true || search.status !== searchStore) {
                // eslint-disable-next-line
                {/*<!-- OPTIONAL 2 -->*/ }
                RequestGetContacts(addNotification, { status: search.status }).then(thing => {
                    //   console.log(stores)
                    setThings(thing);
                    setSeachStore(search.status)
                    setLoading(false);
                })
                // eslint-disable-next-line
                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Contacts - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }, [loading, addNotification, search]);

    const onDelete = (value: IContact) => {
        try {

            const conf = window.confirm("Delete?");
            if (conf) {
                setLoading(true);
                RequestDeleteContact(addNotification, value).then(res => {
                    //  console.log(res);
                    let thingsD: IContact[] = [];
                    if (things) {
                        thingsD = [...things];
                    }
                    const index = thingsD.findIndex(thing => thing._id === value._id);
                    thingsD.splice(index, 1);

                    setThings(thingsD);
                    setLoading(false);
                    addNotification && addNotification('Success', 'Contact Deleted!', ENotificationType.Primary);
                });
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Contacts - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }



    return (<>
        {loading === true && <Loading />}
        {things &&

            <div className="container">
                <div className="col-12">
                    <div className="card text-white bg-success">
                        <div className="card-body">
                            <div className="card-title"><h4>Contacts Admin</h4>
                                <p className="bg-primary">here we have the contact info from the contact form / request form / also any per order conversations will appear here. when they exist</p>
                            </div>
                        </div>

                    </div>

                </div>

                <Helmet>
                    <title>Contacts / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
                </Helmet>



                {/*<!-- <Link to="./contacts/new">New</Link>-->*/}
                <div className='btn-group'>
                    <Link className={`btn ${search.status === 'new' ? 'btn-primary' : 'btn-outline-primary'}`} to={`/admin/contacts?status=new`}>New</Link>
                    <Link className={`btn ${search.status === 'archived' ? 'btn-outline-success' : 'btn-success'}`} to={`/admin/contacts?status=archived`}>Archived</Link>
                </div>


                {/*<!-- OPTIONAL 3 -->*/}



                <DataTable
                    defaultSort='created'
                    colHeadings={[
                        { name: "Name", field: "name", sortable: true },
                        { name: "Phone", field: "phone", sortable: true },
                        { name: "Status", field: "status", sortable: true },
                        { name: "Type", field: "type", sortable: true },
                        {
                            name: "Updated", field: "updated", r: (value: any, index) => {
                                return <><DateDisplay date={value.created} /></>;
                            }, sortable: true
                        },
                        {
                            name: "Created", field: "created", r: (value: any, index) => {
                                return <><DateDisplay date={value.created} /></>;
                            }, sortable: true
                        },

                        {
                            name: "Actions", field: "id", r: (value: any, index) => {
                                return <div className="btn-group">
                                    <DebugButton data={value} alwaysDisplay={true} />
                                    <Link className="btn btn-primary" to={`${match.url}/${value.id}?`}>View</Link>
                                    <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        onDelete(value);
                                    }}>Delete</button>
                                    {value.status !== 'archived' && <button className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        onArchive(value);
                                    }}>Archive</button>}
                                    {value.status === 'archived' && <button className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        onUnArchive(value);
                                    }}>Un-Archive</button>}
                                </div>;
                            }, sortable: false
                        },

                    ]}

                    data={things}
                />

                {/*<!-- OPTIONAL END -->*/}
            </div>}
    </>
    );
};
const AdminContacts = () => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${match.path}/:id`}>
                <AdminContact />
            </Route>
            <Route path={`${match.path}`} exact>
                <AdminContactsPge />
            </Route>

        </Switch>
    </>
    );
}

export default AdminContacts;