import { useEffect, useState } from "react";
import { Redirect, useLocation, useRouteMatch } from "react-router-dom";
import JSONPretty from 'react-json-prettify';
import { ELogType, ESeverity, ILog } from "../../interface/log";
import { ENotificationType, useNotifications } from "../../context/notifications";
import { RequestGetLog, RequestNewLog } from "../../api/log-requests";

interface IAdminLogProp {
    //   types: IProductCategory[];
    //   manufacturers: IManufacturer[];
}
const AdminLog = ({ }: IAdminLogProp) => {
    let location = useLocation();
    const [currentId, setCurrentId] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<ILog>();
    const { addNotification } = useNotifications()!;
    const match: any = useRouteMatch("/admin/logs/:id");

    //const [stores, setLogs] = useState<ILog[]>();



    {/*<!-- OPTIONAL 1 -->*/ }

    {/*<!-- OPTIONAL END -->*/ }

    useEffect(() => {
        return function cleanup() {
            console.log("cleanup", loading, thing)
            setLoading(false);
            setThing(undefined);
        }
    }, []);

    useEffect(() => {
        try {
            const id = match.params.id;

            if (currentId !== id && loading===true) {
                

                {/*<!-- OPTIONAL 2 -->*/ }
                const prodCall = () => {

                    return RequestGetLog(addNotification, id).then(Log => {
                        setThing(Log);
                        if (Log._id) {
                            setCurrentId(Log._id)
                            setLoading(false)
                        }

                    })
                }

                // const gClients = (): Promise<any> => {
                //     return RequestGetClients(addNotification).then(clients => {
                //         setClients(clients);
                //     })
                // }

                // const getStores = () => {
                //     return fetch(`/api/admin/stores`)
                //         .then((response) => response.json())
                //         .then((json: IStore[]) => {
                //             console.log(json);
                //             setStores(json);
                //         })
                // }


                Promise.allSettled([prodCall()]).then(() => {
                    setLoading(false)
                });


                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { userId: '', type: ELogType.reactException, text: 'React try catch exception - Log - useEffect', payload: ex, requestId: '', sessionId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);




    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        {redirect && <Redirect to="../Logs" />}
        {loading === false && thing && <>


            {/*<!-- OPTIONAL 3 -->*/}
            
            <div className="text-left">
                 <JSONPretty json={thing.payload} />

            </div>
            
            
            <div className="text-left">
                 <JSONPretty json={thing} />

            </div>
            {/*<!-- OPTIONAL END -->*/}
        </>}

    </>
    );
}

export default AdminLog;