import arrayMutators from 'final-form-arrays';
import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { Helmet } from 'react-helmet';
import { Link, Prompt, Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { RequestNewLog } from '../../../api/log-requests';
import { RequestGetManufacturer } from '../../../api/manufacturer-requests';
import { RequestGetProductCategory } from '../../../api/product-category-requests';
import { RequestGetProductManufacturer } from '../../../api/product-manufacturer-requests';
import { RequestGetProduct, RequestNewProduct, RequestUpdateProduct } from '../../../api/product-requests';
import { RequestGetStocks } from '../../../api/stock-requests';
import { promiseOptionsManufacturer, promiseOptionsProductCategory, promiseOptionsProductManufacturer } from '../../../components/promises';
import UploadImages from '../../../components/system//UploadImages/UploadImages';
import DataTable from '../../../components/system/DataTable';
import DebugButton from '../../../components/system/DebugButton';
import InputCheckboxTemplate from '../../../components/system/FormStuff/InputCheckboxTemplate';
import InputSelectTemplate from '../../../components/system/FormStuff/InputSelectTemplate';
import InputTextTemplate from '../../../components/system/FormStuff/InputTextTemplate';
import { AsyncSelectCreateAdapter } from '../../../components/system/FormStuff/ReactSelect';
import { composeValidators, required } from '../../../components/system/FormStuff/validators';
import ImageDisplay from '../../../components/system/ImageDisplay';
import Loading from '../../../components/system/loading';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { ELogType, ESeverity } from '../../../interface/log';
import { IProduct } from '../../../interface/product';
import { IProductCategory } from '../../../interface/product-category';
import { IStock } from '../../../interface/stock';


const AdminProduct = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [startedLoading, setStartedLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [redirectTo, setRedirectTo] = useState<string>('')
    const [thing, setThing] = useState<IProduct>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }

    const [categories, setCategories] = useState<IProductCategory[]>();

    const [stock, setStock] = useState<IStock[]>();

    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/products/:id");

    // useEffect(() => {

    //     setSearch(query)
    // }, [location.search]);


    // hide fields
    useEffect(() => {

    }, [])



    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        const load = async () => {
            try {
                setStartedLoading(true)
                const id = match.params.id;
                //  setThing(await RequestGetStore(addNotification, id))
                const product = await RequestGetProduct(addNotification, id);
                if (product.productCategoryId) {
                    const cat = await RequestGetProductCategory(addNotification, product.productCategoryId)
                    product._productCategoryId = { value: cat.id, label: cat.name }
                }
                if (product.productManufacturerId) {
                    const cat = await RequestGetProductManufacturer(addNotification, product.productManufacturerId)
                    product._productManufacturerId = { value: cat.id, label: cat.name }
                }
                if (product.manufacturerIds) {
                    product._manufacturers = [];
                    for await (const aId of product.manufacturerIds) {
                        const cat = await RequestGetManufacturer(addNotification, aId)
                        product._manufacturers.push({ value: cat.id, label: cat.name })
                    }

                }

                // if (product.manufacturerId) {
                //     const cat = await RequestGetManufacturer(addNotification, product.manufacturerId)
                //     product._manufacturerId = { value: cat.id, label: cat.name }
                // }
                setThing(product)
                setStock(await RequestGetStocks(addNotification, { productId: id }))
                setLoading(false)

            } catch (ex: any) {
                console.error(ex)

                RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Store - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
                addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
            }

        }

        if (startedLoading === false) load()






        // try {
        //     const id = match.params.id;
        //     console.log(id);
        //     if (loading === true && startedLoading === false) {
        //         setStartedLoading(true)
        //         let query: any = queryString.parse(location.search)
        //         {/*<!-- OPTIONAL 1 -->*/ }



        //         RequestGetProduct(addNotification, id).then(user => {
        //             if (categories) {
        //                 const ind = categories.findIndex(dd => dd.id === user.productCategoryId);
        //                 const cat = { ...categories[ind] }
        //                 user.productCategory = cat;
        //                 user.productCategoryDetails = cat.details;
        //             }
        //             setThing(user);
        //             setLoading(false)
        //         }).catch(eee => {
        //             console.log(eee)
        //         })



        //         {/*<!-- OPTIONAL END -->*/ }
        //     }
        // } catch (ex) {
        //     RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Product - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
        //     addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        // }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IProduct>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Product Created!';
                ppp = RequestNewProduct(addNotification, data);
            } else {
                notificationText = 'Product Saved!';
                ppp = RequestUpdateProduct(addNotification, data);
            }


            ppp.then(thingX => {
                console.log(thingX);
                setRedirectTo(`/admin/products?productCategoryId=${thingX.productCategoryId}`)
                setThing(thingX);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Product - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 1 -->*/ }



    let AAA: any;

    const addImage = (img: any) => {
        console.log("addImage", img)
        AAA(img);
    }


    const [takenPartNumbers, setTakenPartNumbers] = useState<string[]>();


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to={redirectTo} />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Product / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 1 -->*/}

            <div className="row">
                <div className="col-12 bold-cursor">

                    <Form <IProduct>

                        onSubmit={onSubmit}

                        initialValues={thing}
                        mutators={{
                            // expect (field, value) args from the mutator
                            ...arrayMutators,
                            setValue: ([field, value], state, { changeValue }) => {
                                changeValue(state, field, () => value)
                            }
                        }}

                        render={({ handleSubmit, form, submitting, pristine, values }) => {


                            return <form onSubmit={handleSubmit} id="productForm">
                                <p>Part Number:  <span id="productPartNumber">{values.partNumber}</span></p>
                                <DebugButton data={values} alwaysDisplay={false} />
                                <div className="row">
                                    <div className="col-12">



                                        <Field name="_productCategoryId"
                                            title="Category"
                                            component={AsyncSelectCreateAdapter}
                                            promiseOptions={promiseOptionsProductCategory}
                                        // defaultValue={{ label: "Россия", value: "RU" }}
                                        />


                                        <OnChange name={`productCategoryId`}>
                                            {(value, previous) => {
                                                console.log(value)
                                                if (categories) {
                                                    // const supI = categories.findIndex(dd => dd.id === value)
                                                    // const cat = categories[supI];
                                                    // if (cat !== undefined) {
                                                    //     form.change('productCategory', cat);
                                                    //     form.change('productCategoryDetails', cat.details);
                                                    // } else {
                                                    //     form.change('productCategoryDetails', []);
                                                    // }

                                                }
                                            }}
                                        </OnChange>


                                        <Field name="description">
                                            {({ input, meta }) => (<div className='row'>
                                                <div className="col-sm-12 col-md-2">
                                                    <label>Description</label>
                                                    <p className="descriptionText">Detailed Description</p>
                                                </div>
                                                <div className="col-sm-12 col-md-8">

                                                    <textarea id="description" type="text" className="hiContrastFormField" {...input} placeholder="...." />
                                                </div>
                                                <div className="col-sm-12 col-md-2">
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            </div>
                                            )}
                                        </Field>





                                        <Field name="_productManufacturerId"
                                            title="Part Manufacturer"
                                            component={AsyncSelectCreateAdapter}
                                            promiseOptions={promiseOptionsProductManufacturer}

                                        />


                                        <FieldArray name="_manufacturers">
                                            {({ fields }) => (
                                                <div className="">
                                                    {fields.map((name, index) => {
                                                        return <div key={index} className="">
                                                            <button className='btn btn-danger' type='button' onClick={() => {
                                                                fields.remove(index)
                                                            }}>Delete ({index + 1})</button>
                                                            <Field name={name}
                                                                title={`Bike Manufacturer (${index + 1})`}
                                                                component={AsyncSelectCreateAdapter}
                                                                promiseOptions={promiseOptionsManufacturer}
                                                                description="What Bike Manufacture is this for"
                                                                inputId={"manufacturerId"}
                                                            />

                                                        </div>
                                                    })}
                                                    <button className='btn btn-success' type='button' onClick={() => {
                                                        fields.push({})
                                                    }}>Add Bike Manufacture</button>
                                                </div>)}
                                        </FieldArray>








                                        <FieldArray name="productCategoryDetails">
                                            {({ fields }) => (<>
                                                <div className="row">
                                                    {fields.map((name, index) => {

                                                        if (values.productCategoryDetails) {
                                                            const detail = values.productCategoryDetails[index];
                                                            let display = false;
                                                            if (!detail.conditionalField || detail.conditionalField === '') {
                                                                display = true;
                                                            } else {
                                                                const condIndex = values.productCategoryDetails.findIndex(d => d.field === detail.conditionalField);
                                                                if (condIndex !== -1) {
                                                                    const cond = values.productCategoryDetails[condIndex].field;

                                                                    const condVal = values.productDetails[cond];

                                                                    if (condVal === detail.conditionalValue) {
                                                                        display = true;
                                                                    }
                                                                }
                                                            }



                                                            return <div key={name} className="col-12">
                                                                {/* {JSON.stringify(display)}{JSON.stringify(detail)} */}
                                                                {display && detail.type === 'text' && <>
                                                                    <Field name={`productDetails.${detail.field}`}>
                                                                        {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text={detail.title} />}
                                                                    </Field>

                                                                </>}



                                                                {display && detail.type === 'number' && <>
                                                                    <Field name={`productDetails.${detail.field}`}
                                                                        parse={(value, name) => {
                                                                            console.log("parse", value, name)
                                                                            return parseInt(value);
                                                                        }}
                                                                        format={(value, name) => {
                                                                            console.log("format", value, name)
                                                                            return parseInt(value);
                                                                        }}>
                                                                        {({ input, meta }) =>
                                                                            <InputTextTemplate input={input} meta={meta} iType="number" text={detail.title} />}
                                                                    </Field>
                                                                </>}

                                                                {display && detail.type === 'select' && <>
                                                                    <Field name={`productDetails.${detail.field}`}>
                                                                        {({ input, meta }) =>
                                                                            <InputSelectTemplate input={input} meta={meta} text={detail.title}><option></option>

                                                                                {detail.options && detail.options.map((cat, index) => {
                                                                                    return <option value={cat.value}>{cat.text}</option>;
                                                                                })}
                                                                            </InputSelectTemplate>}
                                                                    </Field>

                                                                </>}

                                                                {display === true && detail.type === 'yesno' && <Field name={`productDetails.${detail.field}`}>
                                                                    {({ input, meta }) =>
                                                                        <InputCheckboxTemplate input={input} meta={meta} text={detail.title} desc={detail.title} />
                                                                    }
                                                                </Field>}
                                                            </div>

                                                        } else {
                                                            return <>Error</>
                                                        }

                                                    })}
                                                </div>
                                            </>)}
                                        </FieldArray>



                                        <Field
                                            name={`newSalePrice`}

                                            validate={composeValidators(required)}
                                            component={InputTextTemplate}
                                            iType="number"
                                            iiType="float"
                                            text="Sale Price GBP"
                                            description="Sale Price per item"
                                        />


                                        <Field
                                            name="weight"

                                            component={InputTextTemplate}
                                            iType="number"
                                            iiType="float"
                                            text="Weight"
                                            description="in kg"
                                        />


                                        <Field
                                            name="productManufacturerCode"

                                            component={InputTextTemplate}

                                            text="Manufacturer Code"

                                        />


                                    </div>






                                    <div className="col-12">

                                        <h3>Product Images</h3>
                                        <FieldArray name="imagesObj">
                                            {({ fields }) => (
                                                <div className="row">
                                                    {fields.map((name, index) => {
                                                        let src: string = '';


                                                        if (values.imagesObj && values.imagesObj[index]) {
                                                            const id = values.imagesObj[index].id

                                                            return <div key={name} className="col-4">
                                                                <ImageDisplay image={values.imagesObj[index]} />
                                                                <div>
                                                                    <label>Name - doesnt work yet</label>
                                                                    <Field name={`${name}.name`} component="input" />
                                                                </div>
                                                                <div className="btn-group">
                                                                    {values.imagesObj[index] && values.imagesObj[index].id !== values.imageId && <button className="btn btn-success" type="button" onClick={() => {


                                                                        form.change('imageId', id)
                                                                        values.imagesObj && form.change('image', values.imagesObj[index])

                                                                    }}>Set Primary</button>}
                                                                    <button className="btn btn-danger" type="button" onClick={() => {
                                                                        if (values.imagesObj) {
                                                                            const imageIds: string[] = values.imagesIds
                                                                            const index: number = values.imagesObj && imageIds.findIndex(dd => dd === id);
                                                                            imageIds.splice(index, 1);
                                                                            form.change('imagesIds', imageIds)


                                                                            fields.remove(index)
                                                                        }


                                                                    }}>Remove</button>
                                                                </div>
                                                            </div>

                                                        }
                                                    })}

                                                    <div className="col-12">
                                                        <UploadImages aspect="none" name="product" attachId={thing.id} attachTo={"product"} save={(dd: any) => {

                                                            const imageIds: string[] = values.imagesIds
                                                            imageIds.push(dd.id);

                                                            if (imageIds.length === 1) {
                                                                form.change('imageId', dd.id)
                                                            }
                                                            form.change('imagesIds', imageIds)
                                                            fields.push(dd)
                                                        }} />
                                                    </div>
                                                </div>
                                            )}
                                        </FieldArray>

                                    </div>
                                    <br />
                                    <div className="col-12">
                                        <DebugButton data={values} alwaysDisplay={true} />

                                        <button className="btn btn-primary" type="submit">Save</button>
                                    </div>
                                </div>















                            </form>

                        }} />


                    {stock && <><h2>Stock In Store</h2>
                        <p data-testid="totalStock">{stock.map(d => d.qty).reduce((partialSum, a) => partialSum + a, 0)}</p>

                        <DataTable <IStock> colHeadings={[
                            // { field: 'id', name: 'id', sortable: true },
                            { field: 'store', name: 'Store/Location', sortable: true, r: (val) => <Link to={`/admin/stores/${val.store && val.store.id}`}>{val.store && val.store.id}</Link> },
                            { field: 'quality', name: 'Quality', sortable: true },
                            { field: 'purchasePrice', name: 'Purchase Price', sortable: true },
                            { field: 'qty', name: 'Qty', sortable: true },
                            {
                                field: 'id', name: 'Act', sortable: false, r: ((val => {
                                    return <div className='btn-group'>
                                        <DebugButton data={val} />
                                        {/* <Link to={`/admin/stocks/${val.id}`} className='btn btn-warning'>View Stock</Link> */}
                                    </div>
                                }))
                            }
                        ]} data={stock} />
                    </>
                    }

                </div>

            </div>
            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminProduct;