import { faCogs, faEnvelopeOpen, faRegistered } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import { useAuth } from "../context/auth";

const Splash = () => {
	const { auth } = useAuth()!;
	return (
		<>
			<Helmet>
				<title>Splash  / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
			</Helmet>

			<div id="splash" className="loginbody mainpage">
				<div className="splash-div">
					<h2 className="">Coming Soon</h2>

					<p>We are currently undergoing some major changes to our website. However in the meantime please :</p>
					<hr className="gold" />
					<div className="row p-4">
						<div className="col-md-4">
							<div className="dbox w-100 text-center">
								<div className="icon d-flex align-items-center justify-content-center">

									<Link to="/request"><FontAwesomeIcon icon={faCogs} size="2x" /></Link>
								</div>
								<div className="text">
									<p className="font-weight-bold"><Link to="/request">Request Parts</Link></p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="dbox w-100 text-center">
								<div className="icon d-flex align-items-center justify-content-center">
									<Link to="/register"> <FontAwesomeIcon icon={faRegistered} size="2x" /></Link>
								</div>
								<div className="text">
									<p className="font-weight-bold"> <Link to="/register"> Register for an account </Link></p>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="dbox w-100 text-center">
								<div className="icon d-flex align-items-center justify-content-center">
									<Link to="/contact"><FontAwesomeIcon icon={faEnvelopeOpen} size="2x" /></Link>
								</div>
								<div className="text">
									<p className="font-weight-bold"><Link to="/contact">Contact Us</Link></p>
								</div>
							</div>
						</div>
					</div>

				</div>
			</div>
		</>
	);
}

export default Splash;