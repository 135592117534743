import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import JSONPretty from 'react-json-prettify';

interface IDebugButon {
  data: any;
  alwaysDisplay?: boolean
}
const DebugButton = ({ data, alwaysDisplay }: IDebugButon) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development' || alwaysDisplay === true) {
    return (<>

      <button type="button" onClick={(ev: React.MouseEvent) => {
        ev.preventDefault();
        setShow(true);
      }} className="btn btn-success"><FontAwesomeIcon icon={faSearch}/></button>

      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Debug</Modal.Title>
        </Modal.Header>
        <Modal.Body>            <div className="text-left">
          <JSONPretty json={data} />

        </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
            </Button>
        </Modal.Footer>
      </Modal>
    </>);
  } else {
    return <></>
  }

}

export default DebugButton;