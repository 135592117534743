import { IProduct } from "../interface/product";
import axiosInstance from "./instance";

export const RequestGetProducts = (addNotification: any, data?: any): Promise<IProduct[]> => {
    return new Promise<IProduct[]>((resolve, reject) => {
        let url = `/products/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        console.log(url)
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetProduct = (addNotification: any, id: string): Promise<IProduct> => {
    return new Promise<IProduct>((resolve, reject) => {

        axiosInstance(addNotification).get(`/products/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewProduct = (addNotification: any, thing: IProduct): Promise<IProduct> => {
    return new Promise<IProduct>((resolve, reject) => {

        axiosInstance(addNotification).post(`/products/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteProduct = (addNotification: any, thing: IProduct): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/products/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateProduct = (addNotification: any, user: IProduct): Promise<IProduct> => {
    return new Promise<IProduct>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/products/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestProductFunction = (addNotification: any, func: string, id: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/products/${func}/${id}`;

        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
