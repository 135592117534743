import Select from "react-select";
import AsyncSelectAdapter from 'react-select/async';
import AsyncCreatableSelect from 'react-select/async-creatable';




export interface IReactSelectValue {
    value: string;
    label: string;
    __isNew__?: boolean
}


export const BasicAsyncSelectAdapter = ({ promiseOptions, input, meta, ...rest }: any) => {
    // console.log(input);

    // console.log(promiseOptions);
    // const useOptionsStore = () => useDebouncedSearch(input.name, (text: string) => promiseOptions(text));
    // const { inputText, setInputText, loaadSearchResults } = useOptionsStore();


    return <div>
        <AsyncSelectAdapter
            className="react-select-hi-contrast"
            {...input}
            {...rest}

            isClearable
            // inputValue={inputText}
            // onInputChange={setInputText}
            loadOptions={promiseOptions}
            styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
            cacheOptions
            defaultOptions
        />
    </div>





};





export const AsyncSelectCreateAdapter = ({ input, title, description, meta, promiseOptions, defaultOptions, ...rest }: any) => {
    // console.log(defaultOptions);

    // const useOptionsStore = () => useDebouncedSearch(input.name, (text: string) => promiseOptions(text));
    // const { inputText, setInputText, loaadSearchResults } = useOptionsStore();


    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            background: "#023950",
            // match with the menu
            borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "yellow" : "green",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "red" : "blue"
            }
        }),
        menu: (base: any) => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0
        }),
        menuList: (base: any) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        })
    };

    return <div className="row pb-2 pt-2">
        <div className="col-sm-12 col-md-2">
            <label>{title}</label>
            <p className="descriptionText">{description}</p>
        </div>
        <div className="col-sm-12 col-md-8">

            <AsyncCreatableSelect
                className="react-select-hi-contrast"

                {...input}
                {...rest}
                customStyles={customStyles}
                cacheOptions
                defaultOptions={defaultOptions}
                isClearable

                // inputValue={inputText}
                // onInputChange={setInputText}
                loadOptions={promiseOptions}
                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
            />
        </div>
        <div className="col-sm-12 col-md-2">
            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
        </div>

    </div>


};



export const XAsyncSelectAdapter = ({ input, title, description, meta, promiseOptions, ...rest }: any) => {
    // console.log(input);

    // const useOptionsStore = () => useDebouncedSearch(input.name, (text: string) => promiseOptions(text));
    // const { inputText, setInputText, loaadSearchResults } = useOptionsStore();


    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            background: "#023950",
            // match with the menu
            borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "yellow" : "green",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "red" : "blue"
            }
        }),
        menu: (base: any) => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0
        }),
        menuList: (base: any) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        })
    };


    return <div className="row pb-2 pt-2">
        <div className="col-sm-12 col-md-2">
            <label>{title}</label>
            <p className="descriptionText">{description}</p>
        </div>
        <div className="col-sm-12 col-md-8">

            <AsyncSelectAdapter
                className="react-select-hi-contrast"

                {...input}
                {...rest}
                cacheOptions
                customStyles={customStyles}
                defaultOptions
                isClearable
                // inputValue={inputText}
                // onInputChange={setInputText}
                loadOptions={promiseOptions}
                styles={{ menu: base => ({ ...base, zIndex: 9999 }) }}
            />
        </div>
        <div className="col-sm-12 col-md-2">
            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
        </div>

    </div>


};
export const SelectAdapter = ({ input, title, description, meta, ...rest }: any) => {
    // console.log(input);

    const customStyles = {
        control: (base: any, state: any) => ({
            ...base,
            background: "#023950",
            // match with the menu
            borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            // Overwrittes the different states of border
            borderColor: state.isFocused ? "yellow" : "green",
            // Removes weird border around container
            boxShadow: state.isFocused ? null : null,
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: state.isFocused ? "red" : "blue"
            }
        }),
        menu: (base: any) => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            // kill the gap
            marginTop: 0
        }),
        menuList: (base: any) => ({
            ...base,
            // kill the white space on first and last option
            padding: 0
        })
    };


    return <div className="row pb-md-2 pt-md-2">
        <div className="2">
            <label>{title}</label>
            <p className="descriptionText">{description}</p>
        </div>
        <div className="col-sm-12 col-md-8">
            <Select
                className="react-select-hi-contrast"
                isClearable
                customStyles={customStyles}
                {...input}
                {...rest} />
        </div>
        <div className="col-sm-12 col-md-2">
            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
        </div>

    </div>


};
