import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { RequestGetCheckoutCountries } from "../../api/checkout-requests";
import { RequestNewContactPublic } from "../../api/contact-requests";
import DebugButton from "../../components/system/DebugButton";
import { composeValidators, mustBeEmail, required } from "../../components/system/FormStuff/validators";
import Loading from "../../components/system/loading";
import { useNotifications } from "../../context/notifications";
import { ICheckoutCountry } from "../../interface/checkout";

const ContactForm = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [finished, setFinished] = useState<boolean>(false);
    const [other, setOther] = useState<any>({});
    const [startedLoading, setStartedLoading] = useState<boolean>(false);
    const [countries, setCo] = useState<ICheckoutCountry[]>();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { addNotification } = useNotifications()!;
    let location = useLocation();

    useEffect(() => {

        const parsed = queryString.parse(location.search);
        console.log(parsed)
        if (parsed) {
            setOther(parsed)
        } else {
            setOther({});
        }
    }, [location.search]);

    useEffect(() => {
        if (startedLoading === false) {
            setStartedLoading(true);

            RequestGetCheckoutCountries(addNotification, {}).then(c => {
                //    console.log("sdsdfsdf",c);
                setCo(c);


                setLoading(false)
                //  addNotification && addNotification('Success', 'Saved Order', ENotificationType.Success)
            }).catch(ex => {

            })
        }
    }, [])

    const onSubmit = (data: any) => {
        setLoading(true)
        executeRecaptcha && executeRecaptcha("contact_form").then(token => {
            data.id = 'new';
            data.recapture = token
            data.type = 'contact';
            data.status = 'new'
            console.log(data);
            RequestNewContactPublic(addNotification, data).then(contact => {
                setFinished(true);
                setLoading(false)
            })
        }).catch(() => {
            setLoading(false)
        });
    }

    return (<>
        {loading === true && <Loading />}
        {finished === true ? <>thanks for your message, we will contact you shortly</> : <Form
            onSubmit={onSubmit}
            initialValues={{
                other
            }}
            validate={(values: any) => {
                const errors: any = {};
                if (values.agree !== true) {

                    errors.agree = 'you must agree to terms'
                }
                return errors;
            }}
            render={({ handleSubmit, form, submitting, pristine, values, errors }) => {

                return <form onSubmit={handleSubmit}>


                    <div className="p-2">
                        <h2>Contact Form</h2><DebugButton data={values} />
                    </div>

                    <div className="card cardrequest">
                        <div className="card-body">
                            <div className="p-2">
                                <Field name="name" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div className="input-container">
                                            <label className={`${input.value !== '' && 'filled'}`}>Your Name:</label>
                                            <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="p-2">
                                <Field name="phone" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div className="input-container">
                                            <label className={`${input.value !== '' && 'filled'}`}>Phone Number:</label>
                                            <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="p-2">
                                <Field name="email" validate={composeValidators(required, mustBeEmail)}>
                                    {({ input, meta }) => (
                                        <div className="input-container">
                                            <label className={`${input.value !== '' && 'filled'}`}>E-Mail:</label>
                                            <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="p-2">
                                <Field name="message" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div className="input-container">
                                            <label className={` ${input.value !== '' && 'filled'}`}>Message:</label>
                                            <textarea type="text" className={`form-control-tweaktarea ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </div></div>
                    <div className="p-4 pull-left">
                        <div className="row">
                            <div className="col-1 flex0">
                                <Field
                                    name="agree"
                                    component="input"
                                    type="checkbox"
                                    className=""
                                    id="agree"
                                />
                            </div>
                            <div className="col-10">
                                <label htmlFor="agree" className="pr-3"> By clicking here you are agreeing to our <Link to="/terms">Terms and Conditions</Link>
                                    {errors && errors['agree'] && <span className="text-danger paddingquantity">(required)</span>}</label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-1 flex0">
                                <Field
                                    name="marketing"
                                    component="input"
                                    type="checkbox"
                                    // value={true}
                                    className=""
                                    id="marketing"
                                />
                            </div>
                            <div className="col-10">
                                <label htmlFor="marketing" className="pr-3">Click here to opt-in our Mailing List</label>
                            </div>
                        </div>
                    </div>
                    <div className="button-group p-4">
                        <button className="signin-button buttonfix" type="submit">SEND</button>
                    </div>
                </form>
            }} />}

    </>);
}

export default ContactForm;