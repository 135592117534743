import { IProductManufacturer } from "../interface/product-manufacturer";
import axiosInstance from "./instance";

export const RequestGetProductManufacturers = (addNotification: any, data?: any): Promise<IProductManufacturer[]> => {
    return new Promise<IProductManufacturer[]>((resolve, reject) => {
        let url = `/productManufacturers/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetProductManufacturer = (addNotification: any, id: string): Promise<IProductManufacturer> => {
    return new Promise<IProductManufacturer>((resolve, reject) => {

        axiosInstance(addNotification).get(`/productManufacturers/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewProductManufacturer = (addNotification: any, thing: IProductManufacturer): Promise<IProductManufacturer> => {
    return new Promise<IProductManufacturer>((resolve, reject) => {

        axiosInstance(addNotification).post(`/productManufacturers/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteProductManufacturer = (addNotification: any, thing: IProductManufacturer): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/productManufacturers/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateProductManufacturer = (addNotification: any, user: IProductManufacturer): Promise<IProductManufacturer> => {
    return new Promise<IProductManufacturer>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/productManufacturers/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestProductManufacturerFunction = (addNotification: any, func:string, id: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/productManufacturers/${func}/${id}`;
        
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
