import React, { useEffect, useState } from "react";
import { RequestGetProductCategorys } from "../../api/product-category-requests";
import { RequestGetProducts } from "../../api/product-requests";
import Products from "../../components/Products";
import { useNotifications } from "../../context/notifications";
import { IProduct } from "../../interface/product";
import { IProductCategory } from "../../interface/product-category";


interface IProductsTypeProp {

}
const ProductType = ({ }: IProductsTypeProp) => {
    //   const match: any = useRouteMatch("/type/:id");
    const { addNotification } = useNotifications()!;

    const [loading, setLoading] = useState<boolean>(false);

    const [loaded, setLoaded] = useState<boolean>(false);
    const [products, setProducts] = useState<IProduct[]>();
    const [type, setType] = useState<IProductCategory[]>();
    const [_id, _setId] = useState<string>('');
    useEffect(() => {
        // const id = match.params.id;
        // console.log("get product types",id,  match, _id !== id);
        // const query = qs.parse(props.location.search.substring(1));
        // setQuery({ name: query.cat });

        if (loading === false) {
            setLoading(true)

            //  const index = types.findIndex((t) => t.id === id);
            RequestGetProductCategorys(addNotification, {}).then(dd => {
                setType(dd);
                RequestGetProducts(addNotification, {}).then(things => {
                    setProducts(things)
                    setLoaded(true)
                })
            })

        }
    }, [])

    return (<>
        {products && <div className="col-12" id="shop">
            {/* <h3>{type.name}</h3>
             */}
            <Products products={products} />
        </div>}

    </>
    );
}

export default ProductType;