import CameraPhoto, { FACING_MODES } from 'jslib-html5-camera-photo';
import React from 'react';

interface IJsCamera {
    onTakePhoto: (dataUri: any) => void
    onCameraError: (error: any) => void;
}
export default class JsCamera extends React.Component {

    cameraPhoto: any;
    videoRef: any;
    state = {
        dataUri: '',
        visible: false
    }
    props: IJsCamera;
    constructor(props: IJsCamera, context: any) {
        super(props, context);
        this.props = props
        this.cameraPhoto = null;
        this.videoRef = React.createRef();
        this.state = {
            dataUri: '',
            visible: false,
        }
    }
    // downHandler = ({ key }: any): void => {
    //     if (key === ' ') {
    //         console.log("Down")
    //     }

    // }
    // If released key is our target key then set to false
    upHandler = ({ key }: any): void => {
        if (key === ' ') {
            this.takePhoto()
            if (document !== null && document.getElementById !== null) {
                const d = document.getElementById("imageName______")
                if (d !== null) {
                    d.focus();
                }
            }
        }
    };
    componentDidMount() {
        // We need to instantiate CameraPhoto inside componentDidMount because we
        // need the refs.video to get the videoElement so the component has to be
        // mounted.
        this.cameraPhoto = new CameraPhoto(this.videoRef.current);

        let facingMode = FACING_MODES.ENVIRONMENT;
        let idealResolution = { width: 640, height: 480 };
        this.startCamera(facingMode, idealResolution);


        //     window.addEventListener("keydown", this.downHandler);
        window.addEventListener("keyup", this.upHandler);
    }

    componentWillUnmount() {
        this.stopCamera()
        //   window.removeEventListener("keydown", this.downHandler);
        window.removeEventListener("keyup", this.upHandler);
    }

    startCamera(idealFacingMode: any, idealResolution: any) {
        this.cameraPhoto.startCamera(idealFacingMode, idealResolution)
            .then(() => {
                console.log('camera is started !');
                this.setState({ visible: true })
            })
            .catch((error: any) => {
                console.error('Camera not started!', error);
                this.props.onCameraError(error);
            });
    }

    startCameraMaxResolution(idealFacingMode: any) {
        this.cameraPhoto.startCameraMaxResolution(idealFacingMode)
            .then(() => {
                console.log('camera is started !');
            })
            .catch((error: any) => {
                this.props.onCameraError(error);
            });
    }

    takePhoto() {
        const config = {
            sizeFactor: 1
        };

        let dataUri = this.cameraPhoto.getDataUri(config);
        this.setState({ dataUri });
        this.props.onTakePhoto(dataUri);
        this.stopCamera()
    }

    stopCamera() {
        this.cameraPhoto.stopCamera()
            .then(() => {
                console.log('Camera stoped!');
            })
            .catch((error: any) => {
                this.props.onCameraError(error);
            });
    }


    render() {

        return <>
            <div className='text-center' style={(this.state.visible === false ? { visibility: "hidden" } : undefined)}>


                {/* <button onClick={() => {
                    let facingMode = FACING_MODES.ENVIRONMENT;
                    let idealResolution = { width: 640, height: 480 };
                    this.startCamera(facingMode, idealResolution);
                }}> Start environment facingMode resolution ideal 640 by 480 </button>

                <button onClick={() => {
                    let facingMode = FACING_MODES.USER;
                    this.startCamera(facingMode, {});
                }}> Start user facingMode resolution default </button>

                <button onClick={() => {
                    let facingMode = FACING_MODES.USER;
                    this.startCameraMaxResolution(facingMode);
                }}> Start user facingMode resolution maximum </button> */}
                < div className='btn-group' >
                    <button className='btn btn-success' onClick={() => {
                        this.takePhoto();
                    }}> Take photo </button>

                    <button className='btn btn-danger' onClick={() => {
                        this.stopCamera();
                    }}> Stop </button>
                </div >
                <video
                    style={{ width: "100%" }}
                    ref={this.videoRef}
                    autoPlay={true}
                />
                <div className=''>You can take a photo by pressing the spacebar.</div>
                <img
                    className='img-fluid'
                    alt="imgCamera"
                    src={this.state.dataUri}
                />
            </div >
        </>
    }
}