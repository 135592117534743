import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCart } from "../context/cart";
import { IProduct } from "../interface/product";



interface IAddToCartButton {
  data: IProduct;
  showLink?: boolean;
}
const AddToCartButton = ({ data, showLink }: IAddToCartButton) => {
  const { items, addToCart } = useCart()!;

  const [qty, setQty] = useState<number>(1)
  let index: number = -1

  if (items) {
    console.log(items)
    index = items.findIndex(dd => data.id === dd.productId);
  }

  //  console.log(index, items);
  return (<div className="input-group">{data.stock && data.stock >= 0 ? <>{showLink && showLink === true && <Link className="btn btn-info" to={`/product/${data.id}`}><FontAwesomeIcon icon={faInfo} /></Link>}

    <span className="input-group-text">Qty</span>
    <input id={`addToCartQty_${data.id}`} type="text" className="form-control" placeholder="" value={qty} onChange={e => setQty(parseInt(e.target.value))} />

    <button id={`addToCartButton_${data.id}`} onClick={(ev: React.MouseEvent) => {
      ev.preventDefault();
      addToCart(data, qty)
    }} className="btn btn-success">{index === -1 ? 'Add to cart' : 'Add again to cart'}</button></> : <>Out stock</>}

  </div>);
}

export default AddToCartButton;