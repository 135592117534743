import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Redirect } from 'react-router-dom';
import { RequestGetCheckoutCountries, RequestNewCheckout } from '../../api/checkout-requests';
import DataTable from '../../components/system/DataTable';
import DebugButton from '../../components/system/DebugButton';
import EBoundary from '../../components/system/EBoundary';
import { composeValidators, required } from '../../components/system/FormStuff/validators';
import Loading from '../../components/system/loading';
import MoneyDisplay from '../../components/system/MoneyDisplay';
import { useAuth } from '../../context/auth';
import { useCart } from '../../context/cart';
import { useNotifications } from '../../context/notifications';
import { ICheckout, ICheckoutCountry, ICheckoutItem } from '../../interface/checkout';
import PaypalButton from './PaypalButton';
import StripeButton from './StripeButtons';

const CartForm = () => {
    const { auth } = useAuth()!;
    const { items, clearCart } = useCart()!;
    const [redirect, setRedirect] = useState<boolean>(false);
    const { addNotification } = useNotifications()!;
    const [loading, setLoading] = useState<boolean>(true);
    const [startedLoading, setStartedLoading] = useState<boolean>(false);
    const [countries, setCo] = useState<ICheckoutCountry[]>();
    const [thing, setThing] = useState<ICheckout>();
    const [response, setResponse] = useState<ICheckout>();
    useEffect(() => {
        if (startedLoading === false) {
            RequestGetCheckoutCountries(addNotification, {}).then(c => {
                console.log(c);
                setCo(c);
                setLoading(false)
            })
            setStartedLoading(true)
        }


        let values: any = {};


        let cartItems: ICheckoutItem[] = [...items];

        console.log(cartItems)

        values.id = 'new';
        values.gatewayType = 'stripe';
        values.addressBillingCountry = 'GB'
        values.addressDeliveryCountry = 'GB'
        values.cartItems = [...cartItems];
        values.subTotal = cartItems.map(sss => sss.qty * sss.price).reduce((a, b) => a + b, 0);
        //  values.subTotal = parseFloat(values.subTotal).toFixed(2)
        values.totalWeight = cartItems.map(sss => sss.qty * sss.weight).reduce((a, b) => a + b, 0);
        values.shippingTotal = + 10.00; //SOME CALC TO FIGURE SHIPPING

        //values.shippingTotal = parseFloat(values.shippingTotal).toFixed(2)
        values.totalTotal = parseFloat(values.shippingTotal) + parseFloat(values.subTotal);
        // values.totalTotal = parseFloat(values.totalTotal).toFixed(2)


        if (auth.user) {
            values.userId = auth.user.id;

            values.firstName = auth.user.firstName;
            values.lastName = auth.user.lastName;
            values.mobile = auth.user.mobile;
            values.telephone = auth.user.mobile;
            values.email = auth.user.email;
            // values.telephone = auth.user.telephone;

            values.addressBillingStreet = auth.user.addressStreet;
            values.addressBillingTown = auth.user.addressTown;
            values.addressBillingCity = auth.user.addressCity;
            values.addressBillingPostCode = auth.user.addressPostCode;
            values.addressBillingCountry = auth.user.addressCountry
            values.addressBillingCounty = auth.user.addressCounty;

        }
        setThing(values)




    }, [items]);




    const onSubmit = (data: any) => {
        console.log("onSubmit", data);
        data.id = 'new';
        data.type = 'web';
        //   values.gatewayType = 'pay-pal';
        RequestNewCheckout(addNotification, data).then(check => {
            console.log(check);
            setResponse(check);
        })

    }


    const complete = (cart: ICheckout) => {
        console.log(cart);
        clearCart();
        setRedirect(true);

    }




    return (<>
        {redirect && <Redirect to="../checkout/complete" />}
        {loading === true ? <Loading /> :

            <div>


                {response === undefined ? <>{thing && thing.cartItems.length > 0 ? <Form <ICheckout>
                    onSubmit={onSubmit}
                    initialValues={thing}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {

                        return <form onSubmit={handleSubmit} id="checkoutForm">

                            <DebugButton data={values} />

                            <h2>Order Confirmation</h2>

                            <DataTable colHeadings={[
                                { field: 'partNumber', name: 'Part Number', sortable: false },
                                { field: 'qty', name: 'Quantity', sortable: false },
                                { field: 'price', name: 'Price', sortable: false },
                                { field: 'itemTotal', name: 'Total', sortable: false },
                            ]} data={values.cartItems} />

                            <div className="row">
                                <div className="col-6 p-4 ml-auto mr-3 text-right">
                                    <table className="w-100">
                                        <tbody>
                                            <tr>
                                                <td>Sub-Total</td>
                                                <td>£ <span id="checkout_subTotal"><MoneyDisplay value={values.subTotal} /></span></td>
                                            </tr>
                                            <tr>
                                                <td>Shipping-Total</td>
                                                <td>£  <span id="checkout_shippingTotal"><MoneyDisplay value={values.shippingTotal} /></span></td>
                                            </tr>
                                            <tr>
                                                <td>Grand-Total</td>
                                                <td>£  <span id="checkout_totalTotal"><MoneyDisplay value={values.totalTotal} /></span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>


                            <div className="row">  <h2 className="text-left col-12">Person details</h2>
                                <div className="col-6 p-4 text-left">

                                    <Field name="company">
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Company</label>
                                                <input type="text" className="form-control" {...input} placeholder="Company" id="company" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="firstName" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>First Name</label>
                                                <input type="text" className="form-control" {...input} placeholder="" id="firstName" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="lastName" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Last Name</label>
                                                <input type="text" className="form-control" {...input} placeholder="" id="lastName" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-6 p-4 text-left">
                                    <Field name="telephone" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Telephone</label>
                                                <input type="text" className="form-control" {...input} placeholder="" id="phone" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="phone" >
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Mobile Phone</label>
                                                <input type="text" className="form-control" {...input} placeholder="" id="mobilePhone" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <Field name="email" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Email</label>
                                                <input type="text" className="form-control" {...input} placeholder="" id="email" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <h2>Billing Address</h2>



                                    <Field name="addressBillingStreet" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div className="form-group">
                                                <label>Street</label>
                                                <input type="text" className="form-control" {...input} placeholder="" id="addressBillingStreet" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="addressBillingCity" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div className="form-group">
                                                <label>City</label>
                                                <input type="text" className="form-control" {...input} placeholder="" id="addressBillingCity" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="addressBillingTown">
                                        {({ input, meta }) => (
                                            <div className="form-group">
                                                <label>Town</label>
                                                <input type="text" className="form-control" {...input} placeholder="" id="addressBillingTown" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="addressBillingCountry" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Country</label>
                                                <select className="form-control" {...input} id="addressBillingCountry">
                                                    <option />
                                                    {countries && countries.map((cat, index) => {
                                                        return <option value={cat.code}>{cat.name}</option>;
                                                    })}
                                                </select>
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="addressBillingCounty" >
                                        {({ input, meta }) => (
                                            <div className="form-group">
                                                <label>County</label>
                                                <input type="text" className="form-control" {...input} placeholder="" id="addressBillingCounty" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="addressBillingPostCode" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div className="form-group">
                                                <label>Post Code</label>
                                                <input type="text" className="form-control" {...input} placeholder="" id="addressBillingPostCode" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-6">










                                    <div className="d-flex justify-content-between">
                                        <div></div>
                                        <h2>Delivery Address</h2>
                                        <button type="button" id="btnDeliveryAddressAsBilling" onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            form.change('addressDeliveryStreet', values.addressBillingStreet)
                                            form.change('addressDeliveryTown', values.addressBillingTown)
                                            form.change('addressDeliveryCity', values.addressBillingCity)
                                            form.change('addressDeliveryCountry', values.addressBillingCountry)
                                            form.change('addressDeliveryCounty', values.addressBillingCounty)
                                            form.change('addressDeliveryPostCode', values.addressBillingPostCode)
                                        }} className="btn btn-primary">same</button>
                                    </div>

                                    <Field name="addressDeliveryStreet" validate={composeValidators(required)} id="addressDeliveryStreet">
                                        {({ input, meta }) => (
                                            <div className="form-group">
                                                <label>Street</label>
                                                <input type="text" className="form-control" {...input} placeholder="" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="addressDeliveryCity" validate={composeValidators(required)} id="addressDeliveryCity">
                                        {({ input, meta }) => (
                                            <div className="form-group">
                                                <label>City</label>
                                                <input type="text" className="form-control" {...input} placeholder="First Name" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="addressDeliveryTown" id="addressDeliveryTown">
                                        {({ input, meta }) => (
                                            <div className="form-group">
                                                <label>Town</label>
                                                <input type="text" className="form-control" {...input} placeholder="First Name" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <Field name="addressDeliveryCountry" id="addressDeliveryCountry">
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Country</label>
                                                <select className="form-control" {...input}>
                                                    <option />
                                                    {countries && countries.map((cat, index) => {
                                                        return <option value={cat.code}>{cat.name}</option>;
                                                    })}
                                                </select>
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="addressDeliveryCounty" id="addressDeliveryCounty">
                                        {({ input, meta }) => (
                                            <div className="form-group">
                                                <label>County</label>
                                                <input type="text" className="form-control" {...input} placeholder="First Name" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="addressDeliveryPostCode" validate={composeValidators(required)} id="addressDeliveryPostCode">
                                        {({ input, meta }) => (
                                            <div className="form-group">
                                                <label>Post Code</label>
                                                <input type="text" className="form-control" {...input} placeholder="Post Code" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className="col-12">
                                    Payment Method
                                    <label>
                                        <Field
                                            name="gatewayType"
                                            component="input"
                                            type="radio"
                                            value="stripe"
                                            validate={composeValidators(required)}
                                        />{' '}
                                        Stripe
                                    </label>
                                    <label>
                                        <Field
                                            name="gatewayType"
                                            component="input"
                                            type="radio"
                                            value="pay-pal"
                                            validate={composeValidators(required)}
                                        />{' '}
                                        Pay Pal
                                    </label>

                                </div>
                            </div>




                            <button className="btn btn-primary" type="submit" id="btnCheckout">SEND</button>
                        </form>

                    }} /> : <>Please add some things to your cart</>}

                </> : <div className='col-12' id="paymentBit">
                    <h2>Payment Bit!</h2>
                    <DebugButton data={response} />
                    <table className="table w-100">
                        <tbody>
                            <tr>
                                <td>Sub-Total</td>
                                <td>£ <span id="checkoutPay_subTotal"><MoneyDisplay value={response.subTotal} /></span>
                                </td>
                            </tr>
                            <tr>
                                <td>Shipping-Total</td>
                                <td>£ <span id="checkoutPay_shippingTotal"><MoneyDisplay value={response.shippingTotal} /></span></td>
                            </tr>
                            <tr>
                                <td>Grand-Total</td>
                                <td>£ <span id="checkoutPay_totalTotal"><MoneyDisplay value={response.totalTotal} /></span></td>
                            </tr>
                        </tbody>
                    </table>
                    <EBoundary title="checkout-payment-boundary">
                        {response.gatewayType === 'pay-pal' && <PaypalButton checkout={response} addNotification={addNotification} onComplete={(d) => {
                            let n = response;
                            n.gatewayMeta = d;
                            complete(n);
                        }} />}
                        {response.gatewayType === 'stripe' && <StripeButton checkout={response} addNotification={addNotification} onComplete={(d) => {
                            //   let n = values;
                            // n.gatewayMeta = d;
                            complete(d);
                        }} />}

                    </EBoundary></div>}



            </div>}

    </>
    );
}

export default CartForm;