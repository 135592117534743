import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import 'react-html5-camera-photo/build/css/index.css';
import 'react-image-crop/lib/ReactCrop.scss';
import { RequestNewFile, RequestUpdateFile } from '../../api/file-requests';
import { RequestNewLog } from '../../api/log-requests';
import { ENotificationType, NotificationContext } from '../../context/notifications';
import { IFile } from '../../interface/file';
import { ELogType, ESeverity } from '../../interface/log';
import DebugButton from './DebugButton';




class UploadFiles extends React.Component<any, any> {

  //static contextType = NotificationContext;
  //  declare context: React.ContextType<typeof NotificationContext>
  context!: React.ContextType<typeof NotificationContext>
  constructor(props: any) {
    super(props);
    console.log(props);
    let aspect: any = { aspect: 1 / 1 };
    if (props.aspect === 'none') {
      //this.setState({ crop: { aspect: props.aspect } })
      aspect = {};
    } else if (props.aspect) {
      aspect = { aspect: props.aspect }
    }
    const initFile: IFile = { id: 'new', name: '', description: '', fileExt: '', fileHash: '', fileMime: '', fileName: '', fileSize: 0 }

    this.state = {
      camera: true,
      cameraError: false,
      display: false,
      file: initFile,

    }

    //console.log(this.props);
  }



  render() {
    const { display, crop, croppedImageUrl, src, blob } = this.state;
    let actualDisplay: boolean = display;
    if (this.props.showOverRide) {
      actualDisplay = this.props.showOverRide;
    }
    const handleClose = () => this.setState({ display: false });
    const onSubmit = (data: any) => {


      if (this.context) {
        const { addNotification } = this.context;
        this.props.googleReCaptchaProps.executeRecaptcha('upload_images').then((recapture: string) => {
          console.log("recapture", recapture, data);

          try {
            let ppp: Promise<IFile>;
            let notificationText: string;
            if (!data._id) {
              notificationText = 'File Uploaded';
              ppp = RequestNewFile(addNotification, data);
            } else {
              data.id = 'new'
              notificationText = 'File Saved!';
              ppp = RequestUpdateFile(addNotification, data);
            }

            ppp.then(thing => {
              console.log(thing);
              //hookIntoForm(thing);
              addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
              if (this.props.onChange) {
                this.props.onChange(thing)
              } else if (this.props.input && this.props.input.onChange) {
                this.props.input.onChange(thing)
              }
              else if (this.props.save) {
                this.props.save(thing);
              }
              if (this.props.onChanged) {
                this.props.onChanged(thing)
              } else if (this.props.input && this.props.input.onChange) {
                this.props.input.onChanged(thing)
              }

              handleClose();
            })
          } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - File - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
            handleClose();
          }

        });
      }


    }

    if (this.context) {
      const { addNotification } = this.context;
      return (<>

        <Modal size="xl" show={actualDisplay} onHide={handleClose}>
          <Form
            onSubmit={onSubmit}
            initialValues={this.state.file}
            render={({ handleSubmit, form, submitting, pristine, values }) => {
              const onSelectFile = (e: any) => {
                if (e.target.files && e.target.files.length >= 0) {

                  const file = e.target.files[0];
                  let readerA = new FileReader();


                  readerA.onload = function () {
                    form.change('name', file.name)
                    form.change('fileName', file.name)
                    form.change('fileSize', file.size)
                    form.change('fileMime', file.type)
                    form.change('fileData', readerA.result)
                  };
                  readerA.onerror = function (error) {
                    console.log('Error: ', error);
                    addNotification && addNotification('File upload Error', "File Reader Error", ENotificationType.Danger)

                  };



                  readerA.readAsDataURL(e.target.files[0])



                } else {
                  addNotification && addNotification('File upload', 'Please specify one file only', ENotificationType.Warning)
                }
              }

              return <form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title>Upload</Modal.Title>
                </Modal.Header>
                <Modal.Body><div className="row">
                  <div className="col-12">


                    <div className="row">
                      <div className="col-12">
                        <DebugButton data={values} />


                        <div>
                          <label>Name</label>
                          <input type="file" onChange={onSelectFile} />

                        </div>

                        <Field name="name">
                          {({ input, meta }) => (
                            <div>
                              <label>Name</label>
                              <input type="text" className="form-control" {...input} placeholder="...." />
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                        </Field>

                        <Field name="description">
                          {({ input, meta }) => (
                            <div>
                              <label>Description</label>
                              <input type="text" className="form-control" {...input} placeholder="...." />
                              {meta.touched && meta.error && <span>{meta.error}</span>}
                            </div>
                          )}
                        </Field>

                        <button className="btn btn-primary" type="submit">Save</button>
                      </div>

                    </div>


                  </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </form>
            }} />
        </Modal>

        {
          display === false && <>{this.props.hideButton !== true && <Button onClick={(ev: React.MouseEvent) => {
            ev.preventDefault();
            this.setState({ display: true })
          }}>{this.props.text ? this.props.text : 'Add File'}</Button>}</>
        }
        {this.props.meta.touched && this.props.meta.error && <span className="bg-warning">{this.props.meta.error}</span>}
      </>);
    }
  }
}



export default withGoogleReCaptcha(UploadFiles);