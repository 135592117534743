import arrayMutators from 'final-form-arrays';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Helmet } from 'react-helmet';
import { Prompt, Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { RequestNewLog } from '../../../api/log-requests';
import {
    RequestGetProductCategory,
    RequestNewProductCategory,
    RequestUpdateProductCategory
} from '../../../api/product-category-requests';
import DebugButton from '../../../components/system/DebugButton';
import InputCheckboxTemplate from '../../../components/system/FormStuff/InputCheckboxTemplate';
import Loading from '../../../components/system/loading';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { ELogType, ESeverity } from '../../../interface/log';
import { IProductCategory } from '../../../interface/product-category';
const AdminProductCategory = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IProductCategory>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/product-categorys/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }

                if (id === 'new') {
                    const parsed = queryString.parse(location.search);

                    setThing({ id: 'new', name: '', description: '', details: [] })
                    //   Promise.allSettled([]).then(() => {
                    setLoading(false)
                    //   });
                } else {
                    // const prodCall = () => {
                    RequestGetProductCategory(addNotification, id).then(thing => {
                        setThing(thing);
                        setLoading(false)
                    })

                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - ProductCategory - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IProductCategory>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'ProductCategory Created!';
                ppp = RequestNewProductCategory(addNotification, data);
            } else {
                notificationText = 'ProductCategory Saved!';
                ppp = RequestUpdateProductCategory(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - ProductCategory - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 1 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../product-categorys" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>ProductCategory / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 1 -->*/}
            <Form <IProductCategory>
                onSubmit={onSubmit}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators
                }}
                initialValues={thing}
                render={({ handleSubmit, form, submitting, pristine, values }) => {

                    return <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <DebugButton data={values} />
                                <Field name="name">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Name</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                {/* 

                                <Field name="id">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>ID</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>


                                <Field name="clientId">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Client</label>
                                            <select className="form-control" {...input} disabled={values.id !== 'new'}>
                                                <option></option>
                                                {clients && clients.map((client, index) => {
                                                    return <option key={index} value={client.id}>{client.name}</option>
                                                })}
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>*/}
                                <Field name="description">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Description</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <h2>Product Questions</h2>
                                <p>These are specific per product</p>
                                <FieldArray name="details">
                                    {({ fields }) => (
                                        <div>
                                            {fields.map((name, index) => (
                                                <div key={name}>


                                                    <h2>Question {index + 1} </h2>
                                                    <div className="btn-group">
                                                        {index !== 0 && <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            const array = [...values.details];

                                                            const item = { ...array[index] }
                                                            array.splice(index, 1);
                                                            array.splice(index - 1, 0, item);


                                                            form.change('details', array)
                                                        }}>
                                                            up
                                                        </button>}
                                                        <button type="button" className="btn btn-danger" onClick={() => fields.remove(index)}>
                                                            Remove
                                                        </button>
                                                        {index + 1 !== values.details.length && <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();

                                                            const array = [...values.details];

                                                            const item = { ...array[index] }
                                                            array.splice(index, 1);
                                                            array.splice(index + 1, 0, item);


                                                            form.change('details', array)
                                                        }}>
                                                            down
                                                        </button>}
                                                    </div>
                                                    <Field name={`${name}.title`}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Title</label>
                                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>

                                                    <Field name={`${name}.field`}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Field</label>
                                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>

                                                    <Field name={`${name}.type`}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Type</label>
                                                                <select className="form-control" {...input}>
                                                                    <option value="text">Text</option>
                                                                    <option value="number">Number</option>
                                                                    <option value="yesno">Checkbox (yes)</option>
                                                                    <option value="select">Drop Down Box</option>
                                                                </select>
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>


                                                    {values.details[index].type === 'select' && <FieldArray name={`${name}.options`}>
                                                        {(optProps: any) => (<div className="row">
                                                            {optProps.fields.map((optName: string, optIndex: number) => {
                                                                return <div key={optIndex} className="col-4">
                                                                    <button type="button" className="btn btn-danger" onClick={() => {
                                                                        console.log(optProps)

                                                                        optProps.fields.remove && optProps.fields.remove(optIndex)
                                                                    }
                                                                    }>
                                                                        Remove
                                                                    </button>
                                                                    <Field name={`${optName}.value`}>
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Value</label>
                                                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>

                                                                    <Field name={`${optName}.text`}>
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <label>Text</label>
                                                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>

                                                                </div>
                                                            })}
                                                            <div className="col-4">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-success"
                                                                    onClick={() => optProps.fields.push({ value: '', text: '' })}
                                                                >
                                                                    Add Option Response
                                                                </button>
                                                            </div>
                                                        </div>)}
                                                    </FieldArray>
                                                    }
                                                    <hr />

                                                    <Field name={`${name}.conditionalField`}>
                                                        {({ input, meta }) => (
                                                            <div>
                                                                <label>Conditional Field</label>
                                                                <select className="form-control" {...input}>
                                                                    <option value=""></option>
                                                                    {values.details && values.details.map((detai, index) => {
                                                                        return <option value={detai.field}>{detai.field}</option>
                                                                    })}
                                                                </select>
                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                            </div>
                                                        )}
                                                    </Field>

                                                    {values.details[index].type === 'yesno' && <>
                                                        <Field name={`${name}.conditionalValue`}>
                                                            {({ input, meta }) =>
                                                                <InputCheckboxTemplate input={input} meta={meta} text={"grrr"} desc={"grr andy grr"} />
                                                            }
                                                        </Field>
                                                    </>}
                                                </div>
                                            ))}
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={() => fields.push({ title: '', field: '', type: 'text', options: [] })}
                                            >
                                                Add Question
                                            </button>
                                        </div>
                                    )}
                                </FieldArray>

                                <button className="btn btn-primary" type="submit">Save</button>
                            </div>

                        </div>

                    </form>

                }} />

            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />
        }

    </>
    );
}

export default AdminProductCategory;