import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useRouteMatch } from "react-router";
import { RequestGetPages } from "../api/page-requests";
import { useAuth } from "../context/auth";
import { useNotifications } from "../context/notifications";
import { IPage } from "../interface/page";

const Page = () => {
    const { auth } = useAuth()!;



    const match: any = useRouteMatch("/p/:slug");
    const { addNotification } = useNotifications()!;


    const [id, setId] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);
    const [page, setPage] = useState<IPage>();
    const createMarkup = () => {
        let mum = '';
        if (page && page.content) {
            mum = page.content;
        }
        return {
            __html: mum
        };
    };
    useEffect(() => {
        console.log("get page", match);
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });
        const slugd = match.params.slug;

        if (loading === true || id !== slugd) {
            console.log(match.params.slug);
            setId(slugd)
            RequestGetPages(addNotification, { slug: slugd }).then(slugs => {
                console.log(slugs)

                if (slugs.length === 1) {
                    const page = slugs[0];
                    setPage(page)
                    setLoading(false)
                }
            })
        }
    }, [match])



    return (




        <>{page && loading === false && <>


            <Helmet>
                <title>{page.name} / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            <div className="loginbody mainpage">
                <div className="standarddiv">

                    <h2 className="">{page.name}</h2>
                    <div className="col-12">
                        <div dangerouslySetInnerHTML={createMarkup()} />
                    </div>

                </div>
            </div>
        </>}
        </>
    );
}

export default Page;