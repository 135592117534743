import axiosInstance from "./instance";


export const ProfileUpdate =  (addNotification:any, thing:any): Promise<any> => {




    return new Promise<any>((resolve, reject) => {
        //let url = '/login';
      
        axiosInstance(addNotification).post(`/profile`, thing).then(response => {
            return response.data;
        }).then(response => {
          //  console.log(response);
            resolve(response)
        }).catch(exc => {
            //console.log(exc);
            reject(exc);
        });
    })
}
// export const RequestLoginLostPassword =  (addNotification:any, thing:any): Promise<ISession> => {




//     return new Promise<ISession>((resolve, reject) => {

      
//         axiosInstance(addNotification).post(`/login/lostPassword`, thing).then(response => {
//             return response.data;
//         }).then(response => {
//           //  console.log(response);
//             resolve(response)
//         }).catch(exc => {
//             //console.log(exc);
//             reject(exc);
//         });
//     })
// }