import { CardElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import { useAuth } from '../../context/auth';
import { ICheckout } from '../../interface/checkout';


declare const window: any;
// const CLIENT = {
//   sandbox:
//     "AfyKAAlwRWLvmO8s8xUwGJhuK9aWwGKMuf_1sl33fysFYmhXlaDSsgQIY6HQHTCNL2yq58eyuFi00IE0",
//   production:
//     "your_production_key"
// };

const CLIENT_ID = "AfyKAAlwRWLvmO8s8xUwGJhuK9aWwGKMuf_1sl33fysFYmhXlaDSsxgQIY6HQHTCNL2yq58eyuFi00IE0"


interface IPPButton {
  checkout: ICheckout;
  addNotification: any;
  onComplete: (dd: any) => void;
  onButtonReady?: () => void
}


const StripppeButton = (props: IPPButton) => {
  const stripe = useStripe();
  const elements = useElements();
  const { auth } = useAuth()!;
  let mode: string = '';
  if (auth.metaData['MODE']) {

    mode = auth.metaData['MODE'];
  }
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardEle = elements.getElement(CardElement);
    if (cardEle !== null) {
      let sss: string = '';
      if (props.checkout.gatewayOrderId) {
        sss = props.checkout.gatewayOrderId;
      }

      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
        sss,
        {
          payment_method: {
            card: cardEle,
            billing_details: {
              name: props.checkout.firstName + " " + props.checkout.lastName,
            },
          },
        }
      );

      console.log(stripeError, paymentIntent)

      if (stripeError === undefined) {
        props.onComplete(paymentIntent)
      }
    }
  }

  return <><h1>Card</h1>
    {mode === 'DEV' && <div>
      <h4>Try a <a href="https://stripe.com/docs/testing#cards" target="_blank" rel="noopener noreferrer">test card</a>:</h4>
      <div>
        <code>4242424242424242</code> (Visa)
      </div>
      <div>
        <code>5555555555554444</code> (Mastercard)
      </div>
      <div>
        <code>4000002500003155</code> (Requires <a href="https://www.youtube.com/watch?v=2kc-FjU2-mY" target="_blank" rel="noopener noreferrer">3DSecure</a>)
      </div>
    </div>}


    <CardElement id="card" />

    <button id="payNow" type="button" className="btn btn-primary" onClick={handleSubmit}>Pay</button>

  </>
};


const StripeButton = (props: IPPButton) => {

  const { auth } = useAuth()!;

  if (auth && auth.metaData && auth.metaData.stripe && props.checkout.gatewayOrderId !== undefined && props.checkout && typeof props.checkout.gatewayOrderId === 'string') {

    const stripePromise = loadStripe(auth.metaData.stripe);




    return <Elements stripe={stripePromise}>

      <StripppeButton addNotification={props.addNotification} onComplete={props.onComplete} checkout={props.checkout} />
    </Elements>
  } else {
    return <></>;
  }


};

//ttps://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=USD`)(PaypalButton);
//export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=GBP`)(PaypalButton);
export default StripeButton