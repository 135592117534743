import React from 'react';
import {  faGlobe, faMapMarker, faPaperPlane, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';

import { useAuth } from '../../context/auth';
import ContactForm from './ContactForm';

const Contact = () => {
    const { auth } = useAuth()!;
    return (
        <>
            <Helmet>
                <title>Contact Us / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <div className="container loginbody">            
                <div className="col-md-12">
                    <div className="row">
					<div className="col-md-6 d-flex align-items-stretch">
                			<img src="/contactus.jpg" className="card-img-top" alt="..."></img>
						</div>
                        <div className="contactfix col-md-6">
                            <div className="">
                                <ContactForm />
                            </div>
                        </div>

                    </div>
					<hr className="gold" />
                </div>

                <div className="row nomargin p-4">

					<div className="col-md-4">
						<div className="dbox w-100 text-center">
			        		<div className="icon d-flex align-items-center justify-content-center">
                                <FontAwesomeIcon icon={faMapMarker} size="2x" />
			        		</div>
			        		<div className="text">
				                <p className="font-weight-bold goldfont"> {auth.metaData && auth.metaData.SUPPORT_CONTACT_ADDRESS}</p>
				            </div>
			            </div>
					</div>
					<div className="col-md-4">
						<div className="dbox w-100 text-center">
			        		<div className="icon d-flex align-items-center justify-content-center">
                                
								<a className="font-weight-bold" href={`tel://${auth.metaData && auth.metaData.SUPPORT_CONTACT_PHONE}`}><FontAwesomeIcon icon={faPhone} size="2x" /></a>
			        		</div>
			        		<div className="text">
				            	<p> <a className="font-weight-bold" href={`tel://${auth.metaData && auth.metaData.SUPPORT_CONTACT_PHONE}`}>{auth.metaData && auth.metaData.SUPPORT_CONTACT_PHONE}</a></p>
				        	</div>
			        	</div>
					</div>
					<div className="col-md-4">
						<div className="dbox w-100 text-center">
			        		<div className="icon d-flex align-items-center justify-content-center">
                                
								<a href={`mailto://${auth.metaData && auth.metaData.SUPPORT_CONTACT_EMAIL}`}><FontAwesomeIcon icon={faPaperPlane} size="2x" /></a>
			        		</div>
			        		<div className="text">
				                <p><a className="font-weight-bold" href={`mailto://${auth.metaData && auth.metaData.SUPPORT_CONTACT_EMAIL}`}>{auth.metaData && auth.metaData.SUPPORT_CONTACT_EMAIL}</a></p>
				            </div>
			            </div>
					</div>
				</div>
            </div>
        </>
    );
}

export default Contact;