import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Helmet } from 'react-helmet';
import { Prompt, Redirect, useLocation, useRouteMatch } from 'react-router-dom';

import { RequestNewLog } from '../../../api/log-requests';
import { RequestGetSupplier, RequestNewSupplier, RequestUpdateSupplier } from '../../../api/supplier-requests';
import DebugButton from '../../../components/system/DebugButton';
import Loading from '../../../components/system/loading';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { ELogType, ESeverity } from '../../../interface/log';
import { ISupplier } from '../../../interface/supplier';


const AdminSupplier = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<ISupplier>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/suppliers/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 1 -->*/ }


                if (id === 'new') {
                    setThing({ tell: '', mobile: '', email: '', id: 'new', name: '', description: '', addressCity: '', addressStreet: '', addressCountry: '', addressCounty: '', addressHouse: '', addressPostCode: '', addressTown: '' })
                    setLoading(false);
                } else {
                    const prodCall = () => {

                        return RequestGetSupplier(addNotification, id).then(user => {
                            setThing(user);
                        })
                    }

                    Promise.allSettled([prodCall()]).then(() => {
                        setLoading(false)
                    });
                }
                // eslint-disable-next-line
                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Supplier - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<ISupplier>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Supplier Created!';
                ppp = RequestNewSupplier(addNotification, data);
            } else {
                notificationText = 'Supplier Saved!';
                ppp = RequestUpdateSupplier(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Supplier - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 1 -->*/ }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../suppliers" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Supplier / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 1 -->*/}



            <Form
                onSubmit={onSubmit}
                initialValues={thing}
                render={({ handleSubmit, form, submitting, pristine, values }) => {

                    return <form onSubmit={handleSubmit}>
                        <DebugButton data={thing} />
                        <div className="row">

                            <div className="col-4">


                                <Field name="name">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Name</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="description">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Description / Note </label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="col-4">
                                <Field name="email">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Email</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="mobile">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Mobile</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="tell">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Tell</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="col-4">
                                <Field name="addressHouse">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>House/Building</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>

                                <Field name="addressStreet">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Street</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="addressTown">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Town</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="addressCity">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>City</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="addressCounty">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>County</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="addressPostCode">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Post Code</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="addressCountry">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Country</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="col-12"><DebugButton data={thing} /><br />
                                <button className="btn btn-primary" type="submit">Save</button>
                            </div>


                        </div>

                    </form>

                }} />

            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminSupplier;