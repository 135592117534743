import { RequestGetManufacturers } from "../api/manufacturer-requests";
import { RequestGetProductCategorys } from "../api/product-category-requests";
import { RequestGetProductManufacturers } from "../api/product-manufacturer-requests";
import { RequestGetProducts } from "../api/product-requests";
import { RequestGetStores } from "../api/store-requests";

export const promiseOptionsProduct = (inputValue: string) => {
    return new Promise(resolve => {
        if (inputValue.length > 2) {
            RequestGetProducts(undefined, { xPartNumber: inputValue.toUpperCase() }).then(producs => {
                resolve(producs.map(ddd => {
                    console.log(ddd)

                    return { "value": ddd.id, "label": ddd.partNumber, newSalePrice: ddd.newSalePrice, image: ddd.image, weight: ddd.weight, stock: ddd.stock }
                }))
            })
        } else {
            resolve([])
        }
    });
}

export const promiseOptionsStore = (inputValue: string) =>
    new Promise(resolve => {


        if (inputValue.length > 2) {
            RequestGetStores(undefined, { xId: inputValue.toUpperCase() }).then(producs => {
                resolve(producs.map(location => {


                    return { value: location.id, label: location.id }
                }))
            })
        } else {
            resolve([])
        }

    });
export const promiseOptionsManufacturer = (inputValue: string) =>
    new Promise(resolve => {


        if (inputValue.length > 2) {
            RequestGetManufacturers(undefined, { xName: inputValue }).then(producs => {
                resolve(producs.map(location => {


                    return { value: location.id, label: location.name }
                }))
            })
        } else {
            resolve([])
        }

    });
export const promiseOptionsProductManufacturer = (inputValue: string) =>
    new Promise(resolve => {


        if (inputValue.length > 2) {
            RequestGetProductManufacturers(undefined, { xName: inputValue }).then(producs => {
                resolve(producs.map(location => {


                    return { value: location.id, label: location.name }
                }))
            })
        } else {
            resolve([])
        }

    });
export const promiseOptionsProductCategory = (inputValue: string) =>
    new Promise(resolve => {
        if (inputValue.length > 2) {
            RequestGetProductCategorys(undefined, { xName: inputValue }).then(producs => {
                resolve(producs.map(location => {
                    return { value: location.id, label: location.name }
                }))
            })
        } else {
            resolve([])
        }

    });
