import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Helmet } from 'react-helmet';
import { Link, Prompt, Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { RequestGetBuyins } from '../../../api/buyin-requests';
import { RequestGetCheckoutCountries, RequestGetCheckouts } from '../../../api/checkout-requests';
import { RequestGetContacts } from '../../../api/contact-requests';
import { RequestGetLogs, RequestNewLog } from '../../../api/log-requests';
import { RequestGetSysNotificationTypes } from '../../../api/sys-notification-type-requests';
import { RequestGetSysPermissionss } from '../../../api/sys-permissions-requests';
import { RequestGetUser, RequestNewUser, RequestUpdateUser, RequestUserFunction } from '../../../api/user-requests';
import DataTable from '../../../components/system/DataTable';
import DebugButton from '../../../components/system/DebugButton';
import InputSelectTemplate from '../../../components/system/FormStuff/InputSelectTemplate';
import InputTextTemplate from '../../../components/system/FormStuff/InputTextTemplate';
import { composeValidators, required } from '../../../components/system/FormStuff/validators';
import Loading from '../../../components/system/loading';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { IBuyin } from '../../../interface/buyin';
import { ICheckout, ICheckoutCountry } from '../../../interface/checkout';
import { IContact } from '../../../interface/contact';
import { ELogType, ESeverity, ILog } from '../../../interface/log';
import { ISysNotificationType } from '../../../interface/sys-notification-type';
import { ISysPermissions } from '../../../interface/sys-permissions';
import { IUser } from '../../../interface/user';

const AdminUser = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IUser>();

    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    // eslint-disable-next-line
    const [key, setKey] = useState('home');

    const [checkouts, setCheckouts] = useState<ICheckout[]>();
    const [contacts, setContacts] = useState<IContact[]>();
    const [buyins, setBuyins] = useState<IBuyin[]>();
    const [logs, setLogs] = useState<ILog[]>([])
    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/users/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {

            const parsed = queryString.parse(location.search);

            console.log("parsed.type:", parsed.type)
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }
                const prodCall = () => {

                    return RequestGetUser(addNotification, id).then(user => {
                        setThing(user);
                    })
                }
                const contries = () => {

                    return RequestGetCheckoutCountries(addNotification, {}).then(c => {
                        console.log(c);
                        setCo(c);
                        setLoading(false)
                    })
                }
                const gPerm = (): Promise<any> => {
                    return RequestGetSysPermissionss(addNotification).then(clients => {
                        setPermissions(clients);
                    })
                }
                const gNt = (): Promise<any> => {
                    return RequestGetSysNotificationTypes(addNotification).then(clients => {
                        setNType(clients);
                    })
                }
                // const gClients = (): Promise<any> => {
                //     return RequestGetClients(addNotification).then(clients => {
                //         setClients(clients);
                //     })
                // }
                if (id === 'new') {

                    Promise.allSettled([contries(), gPerm(), gNt()]).then(() => {
                        setThing({
                            id: 'new', subType: 'customer', company: '', landline: '', notifications: [], username: '', password: '1',
                            addressCity: '', addressCountry: 'GB', addressPostCode: '', addressStreet: '', addressTown: '', addressCounty: '',
                            hasAccess: false, validated: null, validateCode: '', marketing: false, terms: false,
                            email: '', mobile: '', type: 'client', firstName: '', lastName: '', permissions: [], config: {}
                        })

                        setLoading(false)
                    });

                } else {
                    const getContacts = () => {

                        return RequestGetContacts(addNotification, { userId: id }).then(user => {
                            setContacts(user);
                        })
                    }


                    const getCheckouts = () => {

                        return RequestGetCheckouts(addNotification, { userId: id }).then(user => {
                            setCheckouts(user);
                        })
                    }

                    const getBuyIns = () => {

                        return RequestGetBuyins(addNotification, { supplierId: id }).then(user => {
                            setBuyins(user);
                        })
                    }
                    // const getStores = () => {
                    //     return fetch(`/api/admin/stores`)
                    //         .then((response) => response.json())
                    //         .then((json: IStore[]) => {
                    //             console.log(json);
                    //             setStores(json);
                    //         })
                    // }


                    Promise.allSettled([contries(), prodCall(), getContacts(), getCheckouts(), gPerm(), gNt(), getBuyIns()]).then(() => {
                        setLoading(false)
                    });
                }
                // eslint-disable-next-line
                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - User - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IUser>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'User Created!';
                ppp = RequestNewUser(addNotification, data);
            } else {
                notificationText = 'User Saved!';
                ppp = RequestUpdateUser(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - User - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();
    const getUserActivity = useCallback((userId: string) => {
        RequestGetLogs(addNotification, { userId: userId }).then(logs => {
            setLogs(logs)
        })
    }, [addNotification, setLogs])

    const sendWelcomeEmail = useCallback((userId: string) => {
        RequestUserFunction(addNotification, 'send-welcome-email', userId).then(logs => {

        })
    }, [addNotification])


    const sendPassword = useCallback((userId: string) => {
        RequestUserFunction(addNotification, 'admin-lost-password', userId).then(logs => {

        })
    }, [addNotification])
    const severityObj = Object.values(ESeverity);
    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }

    const [countries, setCo] = useState<ICheckoutCountry[]>();
    const [nType, setNType] = useState<ISysNotificationType[]>();
    const [permissions, setPermissions] = useState<ISysPermissions[]>([])

    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../users" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>User / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 3 -->*/}

            <Form
                onSubmit={onSubmit}
                initialValues={thing}
                render={({ handleSubmit, form, submitting, pristine, values }) => {

                    return <form onSubmit={handleSubmit}>
                        <div className="d-flex justify-content-between">
                            <div>
                                <button type="button" className={`btn ${values.type === 'admin' ? 'btn-warning' : 'btn-primary'}`} onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    form.change("type", 'admin');
                                    form.change("subType", 'na');
                                    if (values.id === 'new') {
                                        form.change("password", '');
                                    }
                                }}>
                                    Staff
                                </button>

                            </div>
                            <div>
                                <label>
                                    <button type="button" className={`btn ${(values.type === 'client' && values.subType === 'customer') ? 'btn-warning' : 'btn-primary'}`} onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        form.change("type", 'client');
                                        form.change("subType", 'customer');
                                    }}>
                                        Customer
                                    </button>

                                </label>
                            </div>
                            <div>
                                <button type="button" className={`btn ${(values.type === 'client' && values.subType === 'supplier') ? 'btn-warning' : 'btn-primary'}`} onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    form.change("type", 'client');
                                    form.change("subType", 'supplier');
                                }}>
                                    Supplier
                                </button>

                            </div>
                            <div>
                                <DebugButton data={values} />
                            </div>
                        </div>
                        <Tabs
                            id="controlled-tab-example"

                            className="mb-3"
                        >
                            <Tab eventKey="home" title="Details">
                                <div className="row">




                                    <div className="col-12">

                                        {(values.type === 'client' && values.subType === 'supplier') && <Field name="company" validate={composeValidators(required)}>
                                            {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text={`Company`} />}
                                        </Field>}
                                        <Field name="firstName" validate={composeValidators(required)}>
                                            {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text={`${(values.type === 'client' && values.subType === 'supplier') ? 'Contact ' : ''} First Name`} />}
                                        </Field>

                                        <Field name="lastName" validate={composeValidators(required)}>
                                            {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text={`${(values.type === 'client' && values.subType === 'supplier') ? 'Contact ' : ''} Last Name`} />}
                                        </Field>





                                        <Field name="addressStreet">
                                            {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text="House Number / Street" />}
                                        </Field>

                                        <Field name="addressTown">
                                            {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text="Town" />}
                                        </Field>



                                        <Field name="addressCity">
                                            {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text="City" />}
                                        </Field>
                                        <Field name="addressCounty">
                                            {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text="County" />}
                                        </Field>

                                        <Field name="addressCountry">
                                            {({ input, meta }) => <InputSelectTemplate input={input} meta={meta} text="Country"><option />
                                                {countries && countries.map((cat, index) => {
                                                    return <option value={cat.code}>{cat.name}</option>;
                                                })}</InputSelectTemplate>}
                                        </Field>


                                        <Field name="addressPostCode">
                                            {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text="Post Code" />}
                                        </Field>

                                        <Field name="email" validate={composeValidators(required)}>
                                            {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text="Email" />}
                                        </Field>

                                        <Field name="mobile" validate={composeValidators(required)}>
                                            {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text="Mobile" />}
                                        </Field>
                                        <OnChange name={`email`}>
                                            {(value, previous) => {
                                                // do something
                                                console.log(value, previous)

                                                if (values.username === '' || values.username === previous) {
                                                    form.change('username', value)
                                                }
                                            }}
                                        </OnChange>


                                        <div className="row pb-2 pt-2">
                                            <div className="col-2">
                                                <label>Enable access</label>
                                            </div>
                                            <div className="col-8">
                                                <Field
                                                    name="hasAccess"
                                                    component="input"
                                                    type="checkbox"
                                                    // value={true}
                                                    className=""
                                                    id="hasAccess"
                                                />      <label htmlFor="hasAccess">Enable Access</label>
                                            </div>
                                            <div className="col-2">

                                            </div>

                                        </div>

                                        {values.hasAccess === true && <>
                                            <h2>Login Stuff</h2>
                                            <Field name="username" validate={composeValidators(required)}>
                                                {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text="User Name" />}
                                            </Field>

                                            {values.id === 'new' && <>



                                                <Field name="password" validate={composeValidators(required)}>
                                                    {({ input, meta }) => <InputTextTemplate input={input} iType='password' meta={meta} text="Password" />}
                                                </Field>


                                                <Field name="password2" validate={composeValidators(required)}>
                                                    {({ input, meta }) => <InputTextTemplate input={input} iType='password' meta={meta} text="Re Enter Password" />}
                                                </Field>

                                                {values.id === 'new' && <>




                                                    <div className="row pb-2 pt-2">
                                                        <div className="col-2">
                                                            <label>Welcome E-mail</label>
                                                        </div>
                                                        <div className="col-8">

                                                            <Field
                                                                name="sendWelcome"
                                                                component="input"
                                                                type="checkbox"
                                                                // value={true}
                                                                className=""
                                                                id="sendWelcome"
                                                            />      <label htmlFor="agree">Send Welcome Email</label>
                                                        </div>
                                                        <div className="col-2">

                                                        </div>

                                                    </div></>}
                                            </>}

                                        </>}







                                    </div>


                                </div>
                            </Tab>


                            fggfhfgdh


                            {values.type !== 'admin' && <Tab eventKey="contact" title="Conversations">
                                <div className="row">

                                    {contacts && contacts.length > 0 ? <>   <div className="col-12"><h2>Conversations With user - Contacts / Requests</h2>
                                        <DataTable

                                            colHeadings={[
                                                { name: "Name", field: "name", sortable: false },
                                                { name: "Email", field: "email", sortable: false },
                                                { name: "Phone", field: "phone", sortable: false },
                                                { name: "Status", field: "status", sortable: false },
                                                { name: "Type", field: "type", sortable: false },

                                                {
                                                    name: "Actions", field: "id", r: (value: any, index) => {
                                                        return <div className="btn-group">
                                                            <DebugButton data={value} alwaysDisplay={true} />
                                                            <Link className="btn btn-primary" to={`/admin/contacts/${value.id}`}>View</Link>

                                                        </div>;
                                                    }, sortable: false
                                                },

                                            ]}

                                            data={contacts}
                                        />
                                        <DebugButton data={contacts} />   </div></> : <>No conversations with this person</>}
                                </div>
                            </Tab>}





                            {values.type === 'admin' && <Tab eventKey="permissions" title="Permissions">
                                <>
                                    <div className="row">
                                        <h2>Permissions</h2>
                                        {permissions && permissions.map((ss, key) => {
                                            const exists: boolean = values.permissions.includes(ss.id)
                                            console.log(exists)
                                            if (ss.visible === true && ss.userTypes.includes(values.type))
                                                return <div className="col-3" key={key}>
                                                    {ss.name}
                                                    <br />
                                                    {ss.description}
                                                    <br />
                                                    <div className="btn-group">
                                                        {exists ? <button type="button" className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault()
                                                            console.log("sss", ss)
                                                            const p = [...thing.permissions];
                                                            const index = p.findIndex(dd => dd === ss.id)
                                                            if (index !== -1) {
                                                                p.splice(index, 1)
                                                            }
                                                            form.change("permissions", p)
                                                        }}><FontAwesomeIcon icon={faTrash} /></button> : <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault()
                                                            const p = [...thing.permissions];
                                                            p.push(ss.id)
                                                            form.change("permissions", p)
                                                        }}><FontAwesomeIcon icon={faPlus} /></button>}
                                                    </div>
                                                </div>
                                        })}

                                    </div></>
                            </Tab>}

                            {values.type === 'admin' && <Tab eventKey="notifications" title="Notifications">
                                <><div className="row">
                                    <h2>Notifications</h2>
                                    {nType && nType.map((ss, key) => {
                                        const exists: boolean = values.notifications.includes(ss.id)
                                        console.log("dsd", ss.userTypes.includes(auth.type))
                                        if (ss.userTypes.includes(values.type))
                                            return <div className="col-3" key={key}>
                                                {ss.name}
                                                <br />
                                                {ss.description}
                                                <br />
                                                <div className="btn-group">
                                                    {exists ? <button type="button" className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                                        ev.preventDefault()
                                                        console.log("sss", ss)
                                                        const p = [...thing.notifications];
                                                        const index = p.findIndex(dd => dd === ss.id)
                                                        if (index !== -1) {
                                                            p.splice(index, 1)
                                                        }
                                                        form.change("notifications", p)
                                                    }}><FontAwesomeIcon icon={faTrash} /></button> : <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                                        ev.preventDefault()
                                                        const p = [...thing.notifications];
                                                        p.push(ss.id)
                                                        form.change("notifications", p)
                                                    }}><FontAwesomeIcon icon={faPlus} /></button>}
                                                </div>
                                            </div>
                                    })}
                                </div></>
                            </Tab>}
                            {values.type !== 'admin' && <Tab eventKey="sales" title="Sales">
                                <><div className="row">
                                    {checkouts && checkouts.length > 0 ? <>  <div className="col-12">
                                        <h2>Previous Orders</h2>
                                        <DataTable

                                            colHeadings={[
                                                { name: "ID", field: "id", sortable: true },
                                                { name: "Name", field: "name", sortable: true },
                                                { name: "Status", field: "status", sortable: true },
                                                { name: "Type", field: "type", sortable: true },
                                                { name: "Name", field: "name", sortable: true },
                                                {
                                                    name: "Total", field: "totalTotal", sortable: true, r: (value: any, index) => {
                                                        return <>

                                                            {value.totalTotal !== null && value.totalTotal && value.totalTotal.toFixed(2)}

                                                        </>
                                                    }
                                                },
                                                { name: "Gateway", field: "gatewayType", sortable: true },
                                                {
                                                    name: "Actions", field: "id", r: (value: any, index) => {
                                                        return <div className="btn-group">
                                                            <DebugButton data={value} alwaysDisplay={true} />
                                                            <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link>

                                                        </div>;
                                                    }, sortable: true
                                                },

                                            ]}

                                            data={checkouts.reverse()}
                                        />

                                        <DebugButton data={checkouts} /></div></> : <>No Sales to this person</>}
                                </div></>
                            </Tab>}
                            {(values.type !== 'admin' && values.subType === 'supplier') && <Tab eventKey="purchases" title="Purchases">
                                <><div className="row">
                                    {buyins && buyins.length > 0 ? <>  <div className="col-12">
                                        <h2>Purchases from this person</h2>
                                        <DataTable

                                            colHeadings={[
                                                { name: "ID", field: "id", sortable: true },
                                                { name: "Name", field: "name", sortable: true },
                                                { name: "Status", field: "status", sortable: true },
                                                { name: "Type", field: "type", sortable: true },
                                                { name: "Name", field: "name", sortable: true },
                                                {
                                                    name: "Total", field: "totalTotal", sortable: true, r: (value: any, index) => {
                                                        return <>

                                                            {value.totalTotal !== null && value.totalTotal && value.totalTotal.toFixed(2)}

                                                        </>
                                                    }
                                                },
                                                { name: "Gateway", field: "gatewayType", sortable: true },
                                                {
                                                    name: "Actions", field: "id", r: (value: any, index) => {
                                                        return <div className="btn-group">
                                                            <DebugButton data={value} alwaysDisplay={true} />
                                                            <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link>

                                                        </div>;
                                                    }, sortable: true
                                                },

                                            ]}

                                            data={buyins.reverse()}
                                        />

                                        <DebugButton data={checkouts} />        </div></> : <>No Purchases from this person</>}
                                </div></>
                            </Tab>}
                            {values.type !== 'admin' && <Tab eventKey="bikes" title="Bike Details">
                                <>Coming Soon</>
                            </Tab>}

                            <Tab eventKey="logs" title="Log">
                                <div className="row">
                                    <button className="btn btn-warning" type="button" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();

                                        thing.id && getUserActivity(thing.id)
                                    }}>View Activity</button>
                                    {logs && logs.length > 0 && <><h2>User log</h2><DataTable rowStyleFn={(value) => {
                                        // console.log('ss', value)
                                        switch (value.severity) {
                                            case 0:
                                                return { backgroundColor: "lightgreen" };
                                            case 1:
                                                return { backgroundColor: "lightblue" };
                                            case 2:
                                                return { backgroundColor: "#d342ff" };
                                            case 3:
                                                return { backgroundColor: "orange" };
                                            case 4:
                                                return { backgroundColor: "red" };
                                            default:
                                                return {};
                                        }

                                    }}

                                        colHeadings={[
                                            {
                                                name: "Severity", field: "severity", sortable: true, r: (value: any, index) => {
                                                    return <>{value.severity}</>;
                                                }
                                            },
                                            {
                                                name: "Type", field: "type", sortable: true
                                            },
                                            {
                                                name: "Text", field: "text", sortable: false
                                            },
                                            {
                                                name: "Severity", field: "severity", sortable: true, r: (value: any, index) => {
                                                    const severity: number = value.severity;
                                                    let d = severityObj[severity]
                                                    console.log(d)
                                                    return <>{d}</>
                                                }
                                            },
                                            {
                                                name: "Created", field: "created", r: (value: any, index) => {
                                                    return <>{value.created}</>;
                                                }, sortable: true
                                            },
                                            {
                                                name: "Debug", field: "id", r: (value: any, index) => {
                                                    return <><DebugButton data={value} /><Link className="btn btn-primary" to={`${match.url}/${value._id && value._id}`}>Edit</Link></>;
                                                }, sortable: false
                                            },
                                        ]} data={logs} /></>}

                                </div>
                            </Tab>
                        </Tabs>


                        <div className="row">

                            <div className="col-12">
                                <div className="btn-group">
                                    {thing.id !== 'new' && <>
                                        <button className="btn btn-primary" type="button" onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            thing.id && sendWelcomeEmail(thing.id);
                                        }}>Resend Welcome Email</button>
                                        <button className="btn btn-danger" type="button" onClick={(ev: React.MouseEvent) => {
                                            ev.preventDefault();
                                            thing.id && sendPassword(thing.id);

                                        }}>Send Password Reset Email</button>

                                    </>}

                                    <button className="btn btn-success" type="submit">Save</button>
                                </div>


                            </div>
                        </div>



                    </form>

                }} />


        </> : <Loading />}

    </>
    );
}

export default AdminUser;