import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Helmet } from "react-helmet";
import JSONPretty from 'react-json-prettify';
import { Link, Prompt, Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { RequestNewLog } from "../../../api/log-requests";
import { RequestGetRequest, RequestNewRequest, RequestUpdateRequest } from "../../../api/request-requests";
import DataTable from "../../../components/system/DataTable";
import DebugButton from "../../../components/system/DebugButton";
import { composeValidators, required } from "../../../components/system/FormStuff/validators";
import Loading from "../../../components/system/loading";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { IImage } from "../../../interface/image";
import { ELogType, ESeverity } from "../../../interface/log";
import { IRequest } from "../../../interface/request";




const AdminRequest = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IRequest>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/requests/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }

                if (id === 'new') {
                    setThing({ id: 'new', name: '', message: '', phone: '', email: '', parts: [], requestId: '' })
                    Promise.allSettled([]).then(() => {
                        setLoading(false)
                    });
                } else {
                    const prodCall = () => {
                        return RequestGetRequest(addNotification, id).then(thing => {
                            setThing(thing);
                        })
                    }

                    Promise.allSettled([prodCall()]).then(() => {
                        setLoading(false)
                    });
                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Request - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IRequest>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Request Created!';
                ppp = RequestNewRequest(addNotification, data);
            } else {
                notificationText = 'Request Saved!';
                ppp = RequestUpdateRequest(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Request - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 3 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../requests" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Request / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 4 -->*/}


            <Form <IRequest>
                onSubmit={onSubmit}
                initialValues={thing}
                mutators={{
                    setValue: ([field, value], state, { changeValue }) => {
                        console.log(state, field, value)
                        changeValue(state, field, () => value)
                    }
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    //do if altered browse notifcation
                    console.log(submitting, pristine)
                    setFormChanged(!pristine)
                    return <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <DebugButton data={values} />
                                <Field name="name" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Name</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                {/* 

                                <Field name="id">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>ID</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>


                                <Field name="clientId">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Client</label>
                                            <select className="form-control" {...input} disabled={values.id !== 'new'}>
                                                <option></option>
                                                {clients && clients.map((client, index) => {
                                                    return <option key={index} value={client.id}>{client.name}</option>
                                                })}
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>*/}
                                <Field name="message" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Message</label>
                                            <textarea type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>

                                <button className="btn btn-primary" type="submit">Save</button>
                            </div>

                        </div>

                    </form>

                }} />
            <h3>Parts</h3>
            <DataTable

                colHeadings={[
                    { name: "Part No", field: "partNo", sortable: false },
                    { name: "Description", field: "description", sortable: false },
                    {
                        name: "Images", field: "id", r: (value: any, index) => {
                            return <div className="">
                                <DebugButton data={value.images} />
                                <div className="row">
                                    {value.images.map((image: IImage, iindex: number) => {
                                        // console.log(values.parts[index]);


                                        if (image) {
                                            let src = image.url;
                                            //    console.log(image);
                                            if (image.croppedSrcData) {
                                                src = image.croppedSrcData;
                                            }

                                            return <div key={iindex} className="col-6"><img className="img-fluid w-90" src={src} /></div>
                                        }
                                    })}
                                </div>

                            </div>;
                        }, sortable: false
                    },
                    { name: "Qty", field: "quantity", sortable: false },
                    {
                        name: "Actions", field: "id", r: (value: any, index) => {
                            return <div className="btn-group">
                                <DebugButton data={value} />
                                <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link>
                                {/* <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    // onDelete(value);
                                }}>Delete</button> */}
                            </div>;
                        }, sortable: false
                    },

                ]}

                data={thing.parts}
            />

            <div className="text-left">
                <JSONPretty json={thing} />

            </div>
            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminRequest;