import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Helmet } from 'react-helmet';
import { Route, RouteProps, Switch } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import AccessDenied from './components/system/AccessDenied';
import EBoundary from './components/system/EBoundary';
import Error from './components/system/Error';
import Notifications from './components/system/Notifications';
import { AdminSidebarProvider } from './context/adminSidebar';
import { useAuth } from './context/auth';
import { CartProvider } from './context/cart';
import { NotificationProvider, useNotifications } from './context/notifications';
import { IManufacturer } from './interface/manufacturer';
import { IProductCategory } from './interface/product-category';
import Admin from './routes/admin/Admin';
import PaymentGatewayResponse from './routes/admin/PaymentGatewayResponse';
import Cart from './routes/Checkout/Cart';
import Checkout from './routes/Checkout/Checkout';
import Contact from './routes/Contact/Contact';
import Conversation from './routes/Conversation/Conversation';
import Home from './routes/Home';
import Login from './routes/Login';
import MyProfile from './routes/MyProfile/MyProfile';
import Page from './routes/Page';
import Privacy from './routes/Privacy';
import Product from './routes/Product/Product';
import ProductType from './routes/Products/ProductType';
import RegisterBuyer from './routes/Register/RegisterBuyer';
import Request from './routes/Requests/Request';
import Splash from './routes/Splash';
import Terms from './routes/Terms';







export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: string | string[];
  errorText?: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {

  const { auth } = useAuth()!;

  let error = false;

  if (Array.isArray(props.isAuthenticated)) {

    if (!props.isAuthenticated.includes(auth.type)) {
      error = true;
    }

  } else {
    if (auth.type !== props.isAuthenticated) {
      error = true;
    }
  }


  if (error) {
    //    console.log(auth);
    const renderComponent = () => <h1><AccessDenied text={props.errorText ? props.errorText : 'Access Denied'} /></h1>;
    return <Route {...props} component={renderComponent} render={undefined} />;
  } else {
    return <Route {...props} />;
  }
};

const blankTypes: IProductCategory[] = [];
const blankMan: IManufacturer[] = [];
const App = () => {
  const { addNotification } = useNotifications()!;
  const { auth } = useAuth()!;
  const [startedLoading, setStartedLoading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [types, setTypes] = useState<IProductCategory[]>(blankTypes);
  const [manufacturer, setManufacturer] = useState<IManufacturer[]>(blankMan);
  useEffect(() => {
    console.log("app:useEffect", auth);
    //  const query = qs.parse(props.location.search.substring(1));
    //setQuery({ name: query.cat });

    if (auth.token && loading === true && startedLoading === false) {
      setStartedLoading(true);
      setLoading(false)

    }
  }, [auth, loading, startedLoading, addNotification])

  //sopmething

  const allUsers: ProtectedRouteProps = {
    isAuthenticated: ['buyer', 'admin'],
    errorText: 'you must be a logged in user :)',
  };


  const guestOnly: ProtectedRouteProps = {
    isAuthenticated: 'guest',
    errorText: 'You are already a member :)',
  };

  // const buyerOnly: ProtectedRouteProps = {
  //   isAuthenticated: 'buyer'
  // };

  const adminOnly: ProtectedRouteProps = {
    isAuthenticated: 'admin'
  };


  let shopEnabled = false;
  let isTest = false;
  // or instead of floor you can use ceil depending on what side gets the extra data
  if (auth.metaData !== undefined) {
    if (auth.metaData.MODE === 'SOMETHING' || auth.metaData.MODE === 'DEV' || auth.metaData.MODE === 'TEST' || auth.metaData.MODE === 'docker') {
      isTest = true;
    }
    if (auth.metaData.SHOP_ENABLED) {
      shopEnabled = auth.metaData.SHOP_ENABLED;
    }

  }

  return (<>

    <AdminSidebarProvider>
      <NotificationProvider>
        <CartProvider>

          <div className="App" data-testid="App">


            {auth.id !== 'new' && auth.metaData ? <>
              <GoogleReCaptchaProvider reCaptchaKey={auth.metaData.recapture}>
                <h1 style={{ display: 'none' }}>
                  / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}
                </h1>
                <Helmet>
                  <title>/ {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
                </Helmet>

                <EBoundary title="header-boundary">
                  {/* <Route path={`/`} exact><TopBar /></Route>
                  <Route path={`/product/:id`} exact><TopBar /></Route>
                  <Route path={`/type/:type`} exact><TopBar /></Route> */}
                  <Navigation />
                  <Notifications />
                </EBoundary>
                <div className="container-fluid" style={{ position: "relative", top: "-40px" }}>
                  {/* {isTest && <div className="notLiveBar text-center">TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST TEST</div>} */}

                  <Switch>

                    <Route path="/about">
                      <Splash />
                    </Route>
                    <Route path="/xsplash">
                      <Splash />
                    </Route>
                    <Route path="/xhome">
                      <Home />
                    </Route>
                    <Route path="/contact">
                      <Contact />
                    </Route>
                    <Route path="/privacy">
                      <Privacy />
                    </Route>
                    <Route path="/terms">
                      <Terms />
                    </Route>
                    <Route path="/p/:slug">
                      <Page />
                    </Route>
                    <Route path="/product/:id">
                      <Product />
                    </Route>
                    <Route path="/request">
                      <Request />
                    </Route>
                    <ProtectedRoute
                      {...guestOnly}
                      path='/register'
                      component={RegisterBuyer}
                    />
                    <ProtectedRoute
                      {...adminOnly}
                      path='/admin'
                      component={Admin}
                    />

                    <ProtectedRoute
                      {...allUsers}
                      path='/profile'
                      component={MyProfile}
                    />
                    <Route path="/login">
                      <Login />
                    </Route>

                    <Route path="/type">
                      {shopEnabled === true ? <ProductType /> : <Splash />}
                    </Route>

                    <Route path="/cart">
                      <Cart />
                    </Route>

                    <Route path="/checkout">
                      <Checkout />
                    </Route>

                    <Route path="/payment-gateway-response/:type/:resp">
                      <PaymentGatewayResponse />
                    </Route>
                    <Route path="/conv/:type/:id">
                      <Conversation />
                    </Route>
                    <Route path="/" exact>
                      <Splash />
                    </Route>
                  </Switch>

                </div>

                <Footer />
              </GoogleReCaptchaProvider>
            </> : <><Error /> Sorry System Error please contact support</>}

          </div>
        </CartProvider>
      </NotificationProvider>
    </AdminSidebarProvider>
  </>);
}

export default App;
