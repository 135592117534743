import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { RequestNewLog } from '../../../api/log-requests';
import { RequestDeleteUser, RequestGetUsers } from '../../../api/user-requests';
import DataTable from '../../../components/system/DataTable';
import DebugButton from '../../../components/system/DebugButton';
import Loading from '../../../components/system/loading';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { ELogType, ESeverity } from '../../../interface/log';
import { IUser } from '../../../interface/user';
import AdminUser from './Admin.User';


const AdminUsersPge = () => {
    let location = useLocation();
    const [loading, setLoading] = useState<boolean>(true);
    const [things, setThings] = useState<IUser[]>();
    const [type, setType] = useState<string>("");
    const [subType, setSubType] = useState<string>("");
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;


    useEffect(() => {
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react stores onunload'
        });
    }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }

    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }



    useEffect(() => {
        try {

            const parsed = queryString.parse(location.search);
            const sType: string = parsed.type ? parsed.type.toString() : '';
            const sSubType = parsed.subType ? parsed.subType.toString() : '';
            console.log(sType, sSubType)

            if (loading === true || (sType !== type || sSubType !== subType)) {
                // eslint-disable-next-line
                {/*<!-- OPTIONAL 2 -->*/ }

                setType(sType)
                setSubType(sSubType)
                RequestGetUsers(addNotification, { type: sType, subType: sSubType }).then(users => {
                    console.log(users)
                    setThings(users);
                    setLoading(false);
                })
                // eslint-disable-next-line
                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Users - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }, [loading, addNotification, location]);

    const onDelete = (value: IUser) => {
        try {

            const conf = window.confirm("Delete?");
            if (conf) {
                setLoading(true);
                RequestDeleteUser(addNotification, value).then(res => {
                    //  console.log(res);
                    let thingsD: IUser[] = [];
                    if (things) {
                        thingsD = [...things];
                    }
                    const index = thingsD.findIndex(thing => thing._id === value._id);
                    thingsD.splice(index, 1);

                    setThings(thingsD);
                    setLoading(false);
                    addNotification && addNotification('Success', 'User Deleted!', ENotificationType.Primary);
                });
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Users - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }



    return (<>
        {loading === true && <Loading />}
        {things && <div className="col-12">
            <h2>Customers, Staff, Suppliers</h2>
            <Helmet>
                <title>Users / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            <Link to="./users/new" className="btn btn-secondary">New</Link>
            {/*<!-- OPTIONAL 3 -->*/}

            <DataTable

                colHeadings={[
                    { name: "Name", field: "username", sortable: true, queryable: false },
                    { name: "First Name", field: "firstName", sortable: true, queryable: false },

                    { name: "Last Name", field: "lastName", sortable: true, queryable: false },
                    // { name: "Type", field: "type", sortable: true, queryable:true },
                    { name: "Email", field: "email", sortable: true, queryable: false },
                    {
                        name: "Actions", field: "id", r: (value: any, index) => {
                            return <div className="btn-group">
                                <DebugButton alwaysDisplay={true} data={value} />
                                <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>View Person</Link>
                                <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    onDelete(value);
                                }}>Delete</button>
                            </div>;
                        }, sortable: false
                    },

                ]}

                data={things}
            />

            {/*<!-- OPTIONAL END -->*/}
        </div>}
    </>
    );
};
const AdminUsers = () => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${match.path}/:id`}>
                <AdminUser />
            </Route>
            <Route path={`${match.path}`} exact>
                <AdminUsersPge />
            </Route>

        </Switch>
    </>
    );
}

export default AdminUsers;