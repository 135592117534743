import React, { FunctionComponent, useCallback, useEffect, useState } from "react";
type SelectInput ={
    text: string,
    meta: any,
    input: any,
    desc:string;
}
const InputCheckboxTemplate: FunctionComponent<SelectInput> = ({ input, meta, text}) => {
    console.log("dsddddddddddddddddddddddddddddd", input.value)
    return <div className="row pb-2 pt-2">
        <div className="col-2">
            <label>{text}</label>
        </div>
        <div className="col-8">
           <input type="checkbox" value={input.value.toString()} {...input} />
        </div>
        <div className="col-2">
            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
        </div>

    </div>
}
export default InputCheckboxTemplate;