import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ICheckout } from '../../interface/checkout';

declare const window: any;
// const CLIENT = {
//   sandbox:
//     "AfyKAAlwRWLvmO8s8xUwGJhuK9aWwGKMuf_1sl33fysFYmhXlaDSsgQIY6HQHTCNL2yq58eyuFi00IE0",
//   production:
//     "your_production_key"
// };

const CLIENT_ID = "AfyKAAlwRWLvmO8s8xUwGJhuK9aWwGKMuf_1sl33fysFYmhXlaDSsgQIY6HQHTCNL2yq58eyuFi00IE0"


interface IPPButton {
  checkout: ICheckout;
  addNotification: any;
  onComplete: (dd: any) => void;
  onButtonReady?: () => void
}



const PaypalButton = (props: IPPButton) => {
  const [sdkReady, setSdkReady] = useState(false);

  const addPaypalSdk = () => {

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=GBP`;
    script.async = true;
    script.onload = () => {
      setSdkReady(true);
    };
    script.onerror = () => {
      throw new Error('Paypal SDK could not be loaded.');
    };

    document.body.appendChild(script);
  };

  useEffect(() => {
    if (window !== undefined && window.paypal === undefined) {
      addPaypalSdk();
    } else if (
      window !== undefined &&
      window.paypal !== undefined &&
      props.onButtonReady
    ) {
      props.onButtonReady();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  //amount goes in the value field we will use props of the button for this   
  const createOrder = (data: any, actions: any) => {
    console.log("createOrder", data, actions)
    return new Promise<any>((resolve, reject) => {
      resolve(props.checkout.gatewayOrderId)
    })

  };

  const onApprove = (data: any, actions: any) => {
    console.log("onApprove", data, actions)
    return actions.order
      .capture()
      .then((details: any) => {
        console.log(details);
        if (props.onComplete) {
          return props.onComplete(data);
        }
      })
      .catch((err: any) => {
        console.log(err)
      });
  };

  if (!sdkReady && window.paypal === undefined) {
    return (
      <div>Loading PayPal...</div>
    );
  }

  const Button = window.paypal.Buttons.driver('react', {
    React,
    ReactDOM
  });

  //you can set your style to whatever read the documentation for different styles I have put some examples in the style tag
  return (
    <Button
      {...props}
      createOrder={
        props.checkout.totalTotal && createOrder
          ? (data: any, actions: any) => createOrder(data, actions)
          : (data: any, actions: any) => createOrder(data, actions)
      }
      onApprove={
        (data: any, actions: any) => onApprove(data, actions)
        //     : (data:any, actions:any) => onApprove(data, actions)
      }
      style={{
        layout: 'vertical',
        color: 'blue',
        shape: 'rect',
        label: 'paypal'
      }}
    />
  );
};

//ttps://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=USD`)(PaypalButton);
//export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=GBP`)(PaypalButton);
export default PaypalButton