import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { RequestCheckoutFunction } from "../api/checkout-requests";
import { ICheckout } from '../interface/checkout';
import DebugButton from "./system/DebugButton";


interface IAdminProductsSearchForm {
    checkout: ICheckout;
    setCheckout?: (check: ICheckout) => void
}

const AdminCheckoutStockLocations = ({ checkout, setCheckout }: IAdminProductsSearchForm) => {

    return <div className="row">
        <h2>Order Picker - Where is stuff in the warehouse</h2>
        {checkout.cartItems && checkout.cartItems.map((s, index) => {
            return <div key={index} className="col-12">
                <h2>{s.productId}</h2>
                <div className="col-12">
                    {s.stockLocations && s.stockLocations.map((ss, stockIndex) => {

                        return <div key={stockIndex} className="col-12 d-flex justify-content-between">

                            <div>{ss.storeId}</div>
                            <div>{ss.qty}</div>
                            <div className="btn-group">
                                {ss.confirmed === false && <button type="button" id={`${index}${stockIndex}`} className="btn btn-warning" onClick={async () => {
                                    const fun: any = await RequestCheckoutFunction(undefined, 'orderPicked', { checkoutId: checkout.id, productId: s.productId, storeId: ss.storeId })
                                    console.log(fun.payload)
                                    if (setCheckout) {
                                        setCheckout(fun.payload)
                                    }
                                }}>Packed</button>}<DebugButton data={ss} />
                            </div>
                        </div>
                    })}
                </div>

            </div>
        })}

        <div className="w-100">
            <button className="btn btn-info" id={`e2eWSE`} type="button" onClick={(ev: React.MouseEvent) => {
                ev.preventDefault();
                RequestCheckoutFunction(undefined, 'pdfOrderPick', { checkoutId: checkout.id }).then(something => {
                    //       console.log("jhk__________", something.payload);
                    const linkSource = `data:application/pdf;base64,${something.payload}`;
                    const downloadLink = document.createElement("a");


                    downloadLink.href = linkSource;
                    downloadLink.download = `order${checkout.id}.pdf`;
                    downloadLink.click();
                }).catch(Errr => {
                    console.log("err", Errr)
                })
            }}><FontAwesomeIcon icon={faFilePdf} />Stock Pick Sheet</button>
        </div>

    </div >

}

export default AdminCheckoutStockLocations
