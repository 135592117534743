import { faCogs, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link, useLocation } from "react-router-dom";
import packageJson from '../../package.json';
import { useAuth } from '../context/auth';

const Footer = () => {

  let location = useLocation();
  const { auth, logout } = useAuth()!;
  return <>

    {!location.pathname.startsWith('/admin') ? <>
      <div className="footer">
        <hr className="goldline" />
        <div className="container p-4">
          <div className="footerlogo">
            <Link to="/"><img src="/logo.png" className="resize"></img></Link>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Link to="/terms"><p className="font-weight-bold">Terms of use</p></Link>
            </div>
            <div className="col-md-6">
              <Link to="/privacy"><p className="font-weight-bold">Privacy information</p></Link>
            </div>
            {/* <Link to="/p/cookies"><p className="font-weight-bold">Cookies information</p></Link> */}
          </div>
        </div>
        <div className="text-center white linkfix rowfix">
          {auth.type === 'admin' && <>
            <Link className="ffsAwal" to="/admin/home" id="footer_admin"><FontAwesomeIcon size='2x' icon={faCogs} /></Link>
            <button className="ffsAwal btn btn-link" id="footer_logout" onClick={(ev: React.MouseEvent) => { ev.preventDefault(); logout && logout(); }}><FontAwesomeIcon size='2x' icon={faSignOutAlt} /></button>
          </>}

          {auth.type === 'guest' && <>
            {/* <Link className="ffsAwal" to="/register" id="navigation_register">Register</Link> */}
            <Link className="ffsAwal" to="/login" id="navigation_login">Login</Link>
          </>}
          <p><small>© 2021 Copyright Sheffield British Motorcycles, powered by:<a className="font-weight-bold linkfix" href="https://www.photonsurge.net">Photonsurge.</a> <p>{packageJson.version}</p>
          </small></p>
        </div>
      </div></> : <></>}
  </>;
};
export default Footer;