import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Prompt, Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { RequestNewLog } from "../../../api/log-requests";
import { RequestGetPage, RequestNewPage, RequestUpdatePage } from "../../../api/page-requests";
import DebugButton from "../../../components/system/DebugButton";
import { composeValidators, mustBeURLSafe, required } from "../../../components/system/FormStuff/validators";
import Loading from "../../../components/system/loading";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { ELogType, ESeverity } from "../../../interface/log";
import { IPage } from "../../../interface/page";


//import RichText from "../../../components/system/FormStuff/richText";

const AdminPage = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IPage>();
    const { addNotification } = useNotifications()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/pages/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }

                if (id === 'new') {
                    setThing({ id: 'new', slug: '', name: '', description: '', content: '', draftContent: null })
                    Promise.allSettled([]).then(() => {
                        setLoading(false)
                    });
                } else {
                    const prodCall = () => {
                        return RequestGetPage(addNotification, id).then(thing => {
                            setThing(thing);
                        })
                    }

                    Promise.allSettled([prodCall()]).then(() => {
                        setLoading(false)
                    });
                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Page - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IPage>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Page Created!';
                ppp = RequestNewPage(addNotification, data);
            } else {
                notificationText = 'Page Saved!';
                ppp = RequestUpdatePage(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Page - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 3 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../pages" />}

        {(loading === false && thing) ? <>


            {/*<!-- OPTIONAL 4 -->*/}

            <Form <IPage>
                onSubmit={onSubmit}
                initialValues={thing}
                mutators={{
                    setValue: ([field, value], state, { changeValue }) => {
                        console.log(state, field, value)
                        changeValue(state, field, () => value)
                    }
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    //do if altered browse notifcation
                    //console.log(submitting, pristine)
                    setFormChanged(!pristine)
                    return <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <DebugButton data={values} />
                                <Field name="name" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Name</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="slug" validate={composeValidators(required, mustBeURLSafe)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Slug - url safe unique "address" of page</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="description" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Description</label>
                                            <textarea type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                {/* <Field name="draftContent" validate={composeValidators(required)} component={RichText} onChanged={(something:any)=>{
                                   form.change('content',  something)
                                }} /> */}
                                <button className="btn btn-primary mb-100" type="submit">Save</button>


                            </div>

                        </div>

                    </form>

                }} />

            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminPage;