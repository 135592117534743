import arrayMutators from 'final-form-arrays';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Helmet } from 'react-helmet';
import { Link, Prompt, Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { RequestNewBike } from '../../../api/bike-requests';
import { RequestNewLog } from '../../../api/log-requests';
import {
    RequestGetManufacturer,
    RequestNewManufacturer,
    RequestUpdateManufacturer
} from '../../../api/manufacturer-requests';
import DataTable from '../../../components/system/DataTable';
import DebugButton from '../../../components/system/DebugButton';
import Loading from '../../../components/system/loading';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { IBike } from '../../../interface/bike';
import { ELogType, ESeverity } from '../../../interface/log';
import { IManufacturer } from '../../../interface/manufacturer';


const AdminManufacturer = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IManufacturer>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }

    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/manufacturers/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 1 -->*/ }


                if (id === 'new') {
                    setThing({ id: 'new', name: '', bikes: [], description: '', bikesObj: [] })
                    setLoading(false);
                } else {
                    const prodCall = () => {

                        return RequestGetManufacturer(addNotification, id).then(manufacturer => {
                            setThing(manufacturer);
                        })
                    }

                    Promise.allSettled([prodCall()]).then(() => {
                        setLoading(false)
                    });
                }


                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Manufacturer - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IManufacturer>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Manufacturer Created!';
                ppp = RequestNewManufacturer(addNotification, data);
            } else {
                notificationText = 'Manufacturer Saved!';
                ppp = RequestUpdateManufacturer(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Manufacturer - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 1 -->*/ }
    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="/admin/manufacturers" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Manufacturer / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 1 -->*/}
            <div className="row">
                <div className={" " + (thing.id === 'new' ? 'col-12' : 'col-6')}>
                    <h2>{thing.id === 'new' ? 'Manufacturer' : thing.name}</h2>
                    <Form
                        onSubmit={onSubmit}
                        initialValues={thing}
                        mutators={{
                            // expect (field, value) args from the mutator
                            ...arrayMutators,
                        }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => {

                            return <form onSubmit={handleSubmit}>
                                <DebugButton data={values} />

                                <Field name="name">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Name</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="description">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Description</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>

                                <FieldArray name="bikesObj">
                                    {({ fields }) => (
                                        <div>
                                            <DataTable colHeadings={[{ name: "Name", field: "name", sortable: false }, {
                                                name: "Actions", field: "id", r: (value: any, index) => {
                                                    return <div className="btn-group">
                                                        <DebugButton data={value} />
                                                        <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link>
                                                        <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            const conf = window.confirm("Delete?");
                                                            if (conf) {
                                                                fields.remove(index - 1);
                                                                //remove bikes ref
                                                                const bikes: string[] = [...values.bikes];
                                                                const bikesObj: IBike[] = [...values.bikesObj];
                                                                //bikes.push(bike.id);
                                                                const indexed = bikes.findIndex(b => b = value.id);
                                                                if (indexed !== -1) {
                                                                    bikes.splice(indexed, 1);
                                                                    bikesObj.splice(indexed, 1);
                                                                }
                                                                form.change('bikesObj', bikesObj);
                                                                form.change('bikes', bikes);
                                                                setTimeout(() => {
                                                                    form.change('name', '1' + values.name)
                                                                    form.change('name', values.name.substring(1))
                                                                }, 10);

                                                            }

                                                        }}>Delete</button>
                                                    </div>;
                                                }, sortable: false
                                            },]} data={values.bikesObj} />


                                            <h3>Add new bike</h3>

                                            <Field name={`newBike.name`}>
                                                {({ input, meta }) => (
                                                    <div>
                                                        <label>Name</label>
                                                        <input type="text" className="form-control" {...input} placeholder="...." />
                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name={`newBike.description`}>
                                                {({ input, meta }) => (
                                                    <div>
                                                        <label>Description</label>
                                                        <input type="text" className="form-control" {...input} placeholder="...." />
                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name={`newBike.year`} parse={(value, name) => {
                                                //  console.log("parse", value, name)
                                                return parseFloat(value);
                                            }}
                                                format={(value, name) => {
                                                    //  console.log("format", value, name)

                                                    return parseFloat(value);
                                                }}>
                                                {({ input, meta }) => (
                                                    <div>
                                                        <label>Year</label>
                                                        <input type="number" className="form-control" {...input} placeholder="...." />
                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                            <Field name={`newBike.yearTo`} parse={(value, name) => {
                                                //  console.log("parse", value, name)
                                                return parseFloat(value);
                                            }}
                                                format={(value, name) => {
                                                    //  console.log("format", value, name)

                                                    return parseFloat(value);
                                                }}>
                                                {({ input, meta }) => (
                                                    <div>
                                                        <label>Year to (If Range)</label>
                                                        <input type="number" className="form-control" {...input} placeholder="...." />
                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                            <button
                                                type="button"
                                                className="btn btn-success"
                                                onClick={() => {

                                                    const newBike: IBike = { id: 'new', name: values.newBike.name, year: 0, description: values.newBike.description, manufacturerId: values.id, products: [] };
                                                    RequestNewBike(addNotification, newBike).then(bike => {
                                                        form.change('newBike.name', '');
                                                        form.change('newBike.description', '');
                                                        console.log(bike, [...values.bikes])
                                                        const bikesObj: IBike[] = [...values.bikesObj];
                                                        const bikes: string[] = [...values.bikes];
                                                        bikes.push(bike.id);
                                                        bikesObj.push(bike)

                                                        form.change('bikes', bikes);
                                                        form.change('bikesObj', bikesObj);

                                                        form.change('name', '1' + values.name)
                                                        //   form.change('name', values.name.substring(1))

                                                    }).catch(ex => {
                                                        console.log(ex);
                                                    })


                                                }}>
                                                Add new Bike</button>
                                        </div>
                                    )}
                                </FieldArray>


                                <button className="btn btn-primary" type="submit">Save</button>

                            </form>

                        }} />
                </div>
            </div>
            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminManufacturer;