import React from 'react';
import { 
    faUserLock } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from 'react-router-dom';

interface IAccessDenied{
    text:string;
}
const AccessDenied = ({text}:IAccessDenied) => {
    
    return <div className="jumbotron col-12">
        <FontAwesomeIcon icon={faUserLock} size="5x" />
        <h3>{text}</h3>
        <Link to="/login">Login</Link>
    </div>;
};

export default AccessDenied;