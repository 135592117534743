import { faAlignLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';
import Switch from 'react-bootstrap/esm/Switch';
import { Link, Redirect, Route, useRouteMatch } from 'react-router-dom';
import { useAdminSidebars } from '../../context/adminSidebar';
import { useAuth } from '../../context/auth';
import { IManufacturer } from '../../interface/manufacturer';
import { IProductCategory } from '../../interface/product-category';
import AdminBikes from './Admin.Bikes/Admin.Bikes';
import AdminBuyIn from './Admin.BuyIn';
import AdminBuyins from './Admin.Buyins/Admin.Buyins';
import AdminCheckoutInStore from './Admin.CheckoutInstore';
import AdminCheckouts from './Admin.Checkouts/Admin.Checkouts';
import AdminContacts from './Admin.Contacts/Admin.Contacts';
import AdminFiles from './Admin.Files/Admin.Files';
import AdminHome from './Admin.Home';
import AdminImages from './Admin.Images/Admin.Images';
import AdminJobss from './Admin.Jobss/Admin.Jobss';
import AdminLogs from './Admin.Logs';
import AdminManuals from './Admin.Manuals/Admin.Manuals';
import AdminManufacturers from './Admin.Manufacturers/Admin.Manufacturers';
import AdminOffers from './Admin.Offers/Admin.Offers';
import AdminOrders from './Admin.Orders';
import AdminPages from './Admin.Pages/Admin.Pages';
import AdminProductCategorys from './Admin.ProductCategorys/Admin.ProductCategorys';
import AdminProductManufacturers from './Admin.ProductManufacturers/Admin.ProductManufacturers';
import AdminProducts from './Admin.Products/Admin.Products';
import AdminQRScanner from './Admin.QRScanner';
import AdminRequests from './Admin.Requests/Admin.Requests';
import AdminSessions from './Admin.Sessions/Admin.Sessions';
import AdminStocks from './Admin.Stocks/Admin.Stocks';
import AdminStores from './Admin.Stores/Admin.Stores';
import AdminSuppliers from './Admin.Suppliers/Admin.Suppliers';
import AdminSysNotifications from './Admin.SysNotifications/Admin.SysNotifications';
import AdminSysNotificationTypes from './Admin.SysNotificationTypes/Admin.SysNotificationTypes';
import AdminSysPermissionss from './Admin.SysPermissionss/Admin.SysPermissionss';
import AdminUsers from './Admin.Users/Admin.Users';
import AdminSystemCollectionsPage from './_Collections/Admin.Collections';
import AdminCollectionStatusPage from './_Collections/Admin.CollectionStatus';


import packageJson from '../../../package.json';
interface IAdminProps {
    types: IProductCategory[];
    manufacturers: IManufacturer[];
}
const Admin = ({ types, manufacturers }: IAdminProps) => {
    let match = useRouteMatch();
    const { auth, hasPermission, logout } = useAuth()!;
    const { display, toggle } = useAdminSidebars()!;
    let [redirect, setRedirect] = useState<boolean>(false);
    // // let [user, setUser] = useState<IUserSellerProfile>();

    // useEffect(() => {
    //     //   console.log("get product types");
    //     //  const query = qs.parse(props.location.search.substring(1));
    //     //setQuery({ name: query.cat });

    //     if (loading === true) {



    //     }
    // }, [match])


    return (
        <div className="wrapper admini">
            {redirect && <Redirect to={`/`} />}
            <nav id='sidebar' className={`${display ? '' : 'hide'}`}>
                <div className="sidebar-header">
                    <Link to="/admin/home"><img src="/logo.png" className="adminlogo"></img></Link>
                    <h5>BACKOFFICE</h5>
                    <strong>SBM</strong>
                </div>
                <div className="list-unstyled components" data-testid="adminNavigation">


                    <h4 className="paddingtop">DAY TO DAY</h4>
                    {/* {hasPermission && hasPermission(['**']) && <NavDropdown.Item as={Link} to="/admin/qr">QR</NavDropdown.Item>} */}
                    <NavDropdown.Item as={Link} id="admin_checkoutInStore" to="/admin/checkoutInStore">Checkout In Store</NavDropdown.Item>
                    <NavDropdown.Item as={Link} id="admin_buyIn" to="/admin/buyIn">Buy In Process</NavDropdown.Item>
                    {/* <NavDropdown.Item as={Link} to="/admin/requests">Requests</NavDropdown.Item> */}
                    <NavDropdown.Item as={Link} id="admin_checkouts" to="/admin/checkouts">Orders</NavDropdown.Item>
                    <NavDropdown.Item as={Link} id="admin_newContacts" to="/admin/contacts?status=new">Contacts</NavDropdown.Item>
                    {/* <NavDropdown.Item as={Link} to="/admin/orders?activeOnly=true">Orders (Active)</NavDropdown.Item> */}


                    <h4 className="paddingtop">USERS</h4>
                    <NavDropdown.Item as={Link} id="admin_usersStaff" to="/admin/users?type=admin&subType=na">Admin / Staff</NavDropdown.Item>
                    <NavDropdown.Item as={Link} id="admin_usersCustomers" to="/admin/users?type=client&subType=customer">Customers</NavDropdown.Item>
                    <NavDropdown.Item as={Link} id="admin_usersSuppliers" to="/admin/users?type=client&subType=supplier">Suppliers</NavDropdown.Item>

                    <h4 className="paddingtop">EDIT STUFF</h4>
                    <NavDropdown.Item as={Link} id="admin_products" to="/admin/products">Products</NavDropdown.Item>
                    <NavDropdown.Item as={Link} id="admin_stock" to="/admin/stock">Stock</NavDropdown.Item>
                    <NavDropdown.Item as={Link} id="admin_buyIns" to="/admin/buyIns">Buy-Ins Records</NavDropdown.Item>
                    <NavDropdown.Item as={Link} id="admin_productManufacturers" to="/admin/productManufacturers">Product Manufacturers</NavDropdown.Item>
                    <NavDropdown.Item as={Link} id="admin_manufactures" to="/admin/manufacturers">Manufacturers</NavDropdown.Item>
                    <NavDropdown.Item as={Link} id="admin_bikes" to="/admin/bikes">Bikes</NavDropdown.Item>
                    <NavDropdown.Item as={Link} id="admin_stores" to="/admin/stores">Stores/Locations</NavDropdown.Item>
                    {/* <NavDropdown.Item as={Link} to="/admin/offers">Offers</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/checkouts">Orders</NavDropdown.Item>
                    <NavDropdown.Item as={Link} to="/admin/manuals">Manuals</NavDropdown.Item> */}
                    <NavDropdown.Item as={Link} id="admin_productCategories" to="/admin/product-categorys">Product Categories</NavDropdown.Item>
                    <NavDropdown.Item as={Link} id="admin_pages" to="/admin/pages">Pages</NavDropdown.Item>

                    <h4 className="paddingtop">SYSTEM</h4>
                    {hasPermission && hasPermission(['**']) && <NavDropdown.Item as={Link} to="/admin/permissions">Permissions</NavDropdown.Item>}
                    {hasPermission && hasPermission(['**']) && <NavDropdown.Item as={Link} to="/admin/notifications">Notifications</NavDropdown.Item>}
                    {hasPermission && hasPermission(['**']) && <NavDropdown.Item as={Link} to="/admin/notificationTypes">Notification Types</NavDropdown.Item>}
                    {hasPermission && hasPermission(['**']) && <NavDropdown.Item as={Link} to="/admin/sessions">Sessions</NavDropdown.Item>}
                    {hasPermission && hasPermission(['**']) && <NavDropdown.Item as={Link} to="/admin/logs">Logs</NavDropdown.Item>}
                    {hasPermission && hasPermission(['**']) && <NavDropdown.Item as={Link} to="/admin/images">Images</NavDropdown.Item>}
                    {hasPermission && hasPermission(['**']) && <NavDropdown.Item as={Link} to="/admin/files">Files</NavDropdown.Item>}
                    {hasPermission && hasPermission(['**']) && <NavDropdown.Item as={Link} to="/admin/collections">Collections</NavDropdown.Item>}
                    {hasPermission && hasPermission(['**']) && <NavDropdown.Item as={Link} to="/admin/backupMonitor">Backup Monitor</NavDropdown.Item>}
                    {hasPermission && hasPermission(['**']) && <NavDropdown.Item as={Link} to="/admin/jobs">Jobs</NavDropdown.Item>}
                    <p>{packageJson.version}</p>

                </div>
                {/* <ul className="list-unstyled CTAs">
                    <li>
                        <a href="" className="download">stuff 1</a>
                    </li>
                    <li>
                        <a href="" className="article">stuff 2</a>
                    </li>
                </ul> */}
            </nav>

            <div id="content" className="p-sm-0 p-md-2 m-sm-0 m-md-2">
                <Nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className="container-fluid">
                        <button type="button" id="sidebarCollapse" className="btn btn-info" onClick={(ev: React.MouseEvent) => {
                            ev.preventDefault();
                            if (toggle) {
                                toggle();
                            }
                        }}>
                            <FontAwesomeIcon icon={faAlignLeft} />
                            <span>Toggle Sidebar</span>
                        </button>

                        <Nav className="float-right">
                            <Nav.Item>
                                <Nav.Link className="profile-class font-weight-bold" to={`/`} as={Link}>View website online</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link id="profile" className="profile-class font-weight-bold" to={`/profile`} as={Link}>Profile</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link id="logout" className="profile-class font-weight-bold" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault(); if (logout) {
                                        logout(); setRedirect(true);
                                        setRedirect(true);
                                    }
                                }}>Logout
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                </Nav>
                <Switch>
                    <Route path={`${match.path}/orders`}>
                        <AdminOrders />
                    </Route>
                    <Route path={`${match.path}/messages`}>
                        <AdminContacts />
                    </Route>
                    <Route path={`${match.path}/checkoutInStore`}>
                        <AdminCheckoutInStore />
                    </Route>

                    <Route path={`${match.path}/bikes`}>
                        <AdminBikes />
                    </Route>
                    <Route path={`${match.path}/product-categorys`}>
                        <AdminProductCategorys />
                    </Route>
                    <Route path={`${match.path}/products`}>
                        <AdminProducts />
                    </Route>
                    <Route path={`${match.path}/buyIn`}>
                        <AdminBuyIn />
                    </Route>

                    <Route path={`${match.path}/buyIns`}>
                        <AdminBuyins />
                    </Route>

                    <Route path={`${match.path}/stock`}>
                        <AdminStocks />
                    </Route>
                    <Route path={`${match.path}/suppliers`}>
                        <AdminSuppliers />
                    </Route>
                    <Route path={`${match.path}/stores`}>
                        <AdminStores />
                    </Route>
                    <Route path={`${match.path}/manuals`}>
                        <AdminManuals />
                    </Route>
                    <Route path={`${match.path}/checkouts`}>
                        <AdminCheckouts />
                    </Route>
                    <Route path={`${match.path}/contacts`}>
                        <AdminContacts />
                    </Route>
                    <Route path={`${match.path}/pages`}>
                        <AdminPages />
                    </Route>
                    <Route path={`${match.path}/requests`}>
                        <AdminRequests />
                    </Route>
                    <Route path={`${match.path}/sessions`}>
                        <AdminSessions />
                    </Route>
                    <Route path={`${match.path}/users`}>
                        <AdminUsers />
                    </Route>
                    <Route path={`${match.path}/images`}>
                        <AdminImages />
                    </Route>
                    <Route path={`${match.path}/manufacturers`}>
                        <AdminManufacturers />
                    </Route>
                    <Route path={`${match.path}/logs`}>
                        <AdminLogs />
                    </Route>
                    <Route path={`${match.path}/offers`}>
                        <AdminOffers />
                    </Route>
                    <Route path={`${match.path}/files`}>
                        <AdminFiles />
                    </Route>
                    <Route path={`${match.path}/qr`}>
                        <AdminQRScanner />
                    </Route>
                    {/* <Route path={`${match.path}/collections`}>
                            <AdminSystemPage />
                        </Route> */}
                    <Route path={`${match.path}/jobs`}>
                        <AdminJobss />
                    </Route>
                    <Route path={`${match.path}/home`}>
                        <AdminHome />
                    </Route>
                    <Route path={`${match.path}/permissions`}>
                        <AdminSysPermissionss />
                    </Route>
                    <Route path={`${match.path}/notifications`}>
                        <AdminSysNotifications />
                    </Route>

                    <Route path={`${match.path}/notificationTypes`}>
                        <AdminSysNotificationTypes />
                    </Route>
                    <Route path={`${match.path}/productManufacturers`}>
                        <AdminProductManufacturers />
                    </Route>
                    <Route path={`${match.path}/collections`}>
                        <AdminSystemCollectionsPage />
                    </Route>
                    <Route path={`${match.path}/backupMonitor`} exact>
                        <AdminCollectionStatusPage />
                    </Route>
                </Switch>

            </div>
        </div>
    );
}

export default Admin;