import queryString from 'query-string';
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { RequestNewLog } from "../../../api/log-requests";
import { RequestGetProductCategory } from '../../../api/product-category-requests';
import { RequestGetProducts } from '../../../api/product-requests';
import AdminProductsSearchForm from '../../../components/AdminProductsSearchForm';
import DataTable from "../../../components/system/DataTable";
import DebugButton from "../../../components/system/DebugButton";
import ImageDisplay from "../../../components/system/ImageDisplay";
import Loading from "../../../components/system/loading";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { ELogType, ESeverity } from "../../../interface/log";
import { IProduct } from "../../../interface/product";
import { IProductCategory } from "../../../interface/product-category";
import AdminProduct from './Admin.Product';


const AdminProductsPge = () => {
    const [loading, setLoading] = useState<boolean>(true);

    const [redirect, setRedirect] = useState<boolean>(false);
    const [redirectTo, setRedirectTo] = useState<string>("");

    const [search, setSearch] = useState<any>({});

    const [things, setThings] = useState<IProduct[]>();
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;
    const location = useLocation();


    useEffect(() => {
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react stores onunload'
        });
    }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }
    const [table, setTable] = useState<any[]>([]);
    const [categories, setCategories] = useState<IProductCategory[]>();
    {/*<!-- OPTIONAL END -->*/ }



    useEffect(() => {
        const as = async () => {

            let query: any = queryString.parse(location.search)

            if (query.productCategoryId) {
                query._productCategoryId = await RequestGetProductCategory(undefined, query.productCategoryId);
            }


            setSearch(query)
        }
        as();
    }, [location]);

    useEffect(() => {
        try {
            if (loading === true) {
                // eslint-disable-next-line
                {/*<!-- OPTIONAL 1 -->*/ }

                RequestGetProducts(addNotification, {}).then(users => {
                    console.log(users)
                    setThings(users);
                    //  setTable(newTable);
                    setLoading(false);

                })



                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Products - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }, [loading, addNotification]);


    // const [search, setShow] = useState<any>();
    const onSubmit = (data: any) => {
        const yourMum = { ...data };
        console.log("start", data)
        if (data._productCategoryId) {
            data.productCategoryId = data._productCategoryId.value
        }
        if (data._productManufacturerId) {
            data.productManufacturerId = data._productManufacturerId.value
        }
        if (data._description) {
            data.description = data._description
        }
        console.log("fin", yourMum)
        setSearch(data);
        //  setRedirectTo(`/admin/products/?${queryString.stringify(yourMum)}`)
        //  setRedirect(true)
    }


    const promiseOptionsStore = (inputValue: string): Promise<any> => {
        console.log(inputValue);


        return new Promise(resolve => {
            console.log(inputValue);
            //   if (inputValue.length > 2) {
            if (things) {
                resolve(things.filter(d => d.partNumber.toUpperCase().startsWith(inputValue.toUpperCase())).map(location => { return { value: location.id, label: location.partNumber } }))
            }
            //  }
        });
    }

    return (<>
        {loading === true && <Loading />}
        {redirect === true && <Redirect to={redirectTo} />}
        <div className="col-12">
            <h2>Products Admin</h2>
            {/* {JSON.stringify(search)} */}
            {/* <p className="bg-primary">ok this is the list / summary of all the products known, click edit to edit.</p> */}
            <Helmet>
                <title>Products / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>




            {things && <>
                <AdminProductsSearchForm onSubmit={onSubmit} search={search} setSearch={setSearch} setRedirect={setRedirect} setRedirectTo={setRedirectTo} promiseOptionsStore={promiseOptionsStore} />


                <DataTable <IProduct>
                    // exportFileName="products"
                    colHeadings={[
                        { name: "Part Number", field: "partNumber", sortable: true, width: "20px" },
                        {
                            name: "Image", field: "image", width: "20px", sortable: false, r: (value, index) => {
                                return <>{value.image && <ImageDisplay image={value.image} />}</>;
                            }
                        },
                        { name: "Category", field: "productCategoryStr", width: "20px", sortable: true },
                        { name: "Product Manufacturer", field: "productManufacturerStr", width: "20px", sortable: true },
                        { name: "Manufacturer Code", field: "productManufacturerCode", width: "20px", sortable: true },
                        { name: "Bike Manufacturer", field: "manufacturerStr", width: "20px", sortable: true },
                        { name: "Price", field: "newSalePrice", width: "20px", sortable: true },
                        { name: "Sold", field: "stockSold", width: "20px", sortable: true },
                        { name: "Stock", field: "stock", width: "20px", sortable: true },
                        {
                            name: "Actions", field: "id", width: "20px", isButtonField: true, r: (value: any, index) => {
                                return <div className="btn-group">
                                    <DebugButton data={value} />
                                    <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link>
                                </div>;
                            }, sortable: false
                        },

                    ]}

                    data={things.filter(something => {
                        let searches = 0;
                        let ok = 0

                        if (!search.productCategoryId && !search.productManufacturerId && !search._description) {
                            return something;
                        }


                        if (search.productCategoryId) {
                            searches++;
                            if (something.productCategoryId === search.productCategoryId) {
                                ok++;
                            }
                        }


                        if (search.productManufacturerId) {
                            searches++;
                            if (something.productManufacturerId === search.productManufacturerId) {
                                ok++;
                            }
                        }
                        if (search._description) {
                            searches++;
                            if (something.description.includes(search._description)) {
                                ok++;
                            }
                        }


                        if (searches == ok) {
                            return something;
                        }


                    })}
                /></>}

            {/*<!-- OPTIONAL END -->*/}
        </div>
    </>
    );
};
const AdminProducts = () => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${match.path}/:id`}>
                <AdminProduct />
            </Route>
            <Route path={`${match.path}`} exact>
                <AdminProductsPge />
            </Route>

        </Switch>
    </>
    );
}

export default AdminProducts;