import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMutators from 'final-form-arrays';
import { useEffect, useRef, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { Helmet } from 'react-helmet';
import { Link, Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { RequestNewLog } from '../../../api/log-requests';
import { RequestStockFunctionPost } from '../../../api/stock-requests';
import { RequestDeleteStore, RequestGetStores } from '../../../api/store-requests';
import DataTable from '../../../components/system/DataTable';
import DebugButton from '../../../components/system/DebugButton';
import { BasicAsyncSelectAdapter } from '../../../components/system/FormStuff/ReactSelect';
import Loading from '../../../components/system/loading';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { ELogType, ESeverity } from '../../../interface/log';
import { IStore } from '../../../interface/store';
import AdminStore from './Admin.Store';





const AdminStoresPge = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [things, setThings] = useState<IStore[]>();

    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    const [buildings, setBuildings] = useState<string[]>();
    const location = useLocation();



    const [redirect, setRedirect] = useState<boolean>(false);
    const [redirectTo, setRedirectTo] = useState<string>("");

    useEffect(() => {
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react stores onunload'
        });
    }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }

    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }



    useEffect(() => {
        try {
            if (loading === true) {
                // eslint-disable-next-line
                {/*<!-- OPTIONAL 2 -->*/ }
                RequestGetStores(addNotification).then(thing => {
                    //   console.log(stores)
                    setThings(thing);
                    setBuildings([...new Set(thing.map(thinx => thinx.building))]);
                    setLoading(false);
                })
                // eslint-disable-next-line
                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Stores - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }, [loading, addNotification]);

    const onDelete = (value: IStore) => {
        try {
            const conf = window.confirm("Delete?");
            if (conf) {
                setLoading(true);
                RequestDeleteStore(addNotification, value).then(res => {
                    //  console.log(res);
                    let thingsD: IStore[] = [];
                    if (things) {
                        thingsD = [...things];
                    }
                    const index = thingsD.findIndex(thing => thing._id === value._id);
                    thingsD.splice(index, 1);

                    setThings(thingsD);
                    setLoading(false);
                    addNotification && addNotification('Success', 'Store Deleted!', ENotificationType.Primary);
                });
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Stores - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }

    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const [search, setSearch] = useState<any>({});





    const onSubmit = (data: any) => {
        setSearch(data)
    }





    const promiseOptionsStore = (inputValue: string) => {
        console.log(inputValue);


        return new Promise(resolve => {
            console.log(inputValue);
            if (inputValue.length > 2) {
                if (things) {
                    resolve(things.filter(d => d.id.toUpperCase().startsWith(inputValue.toUpperCase())).map(location => { return { value: location.id, label: location.id } }))
                }
            }
        });
    }

    return (<>
        {loading === true && <Loading />}
        {redirect === true && <Redirect to={redirectTo} />}
        {things && <div className="col-12">
            <h2>Stores Admin</h2>
            <p className="bg-primary">List of stock locations. click edit to edit and view stock in said store location</p>
            <Helmet>
                <title>Stores / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 3 -->*/}





            {/* {JSON.stringify(search)} */}

            {things !== undefined && <>



                <Form <any>
                    onSubmit={onSubmit}
                    initialValues={search}
                    mutators={{
                        // expect (field, value) args from the mutator
                        ...arrayMutators,
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value)
                        }
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {

                        return <form onSubmit={handleSubmit}>

                            <div className='row'>
                                <div className='col-md-12 col-lg-3'>
                                    <Field
                                        name="id"
                                        component={BasicAsyncSelectAdapter}
                                        promiseOptions={promiseOptionsStore}
                                    />
                                    <OnChange name='id'>
                                        {(value, previous) => {
                                            if (value !== previous) {
                                                console.log(value, value.valu)
                                                if (value.value) {
                                                    setRedirect(true);
                                                    setRedirectTo(`/admin/stores/${value.value}`)
                                                }
                                            }
                                        }}
                                    </OnChange>
                                </div>
                                <div className='col-md-12 col-lg-3'>
                                    <Field name="building">
                                        {({ input, meta }) => (
                                            <div>
                                                <select className="form-control" {...input}>
                                                    <option>Building</option>
                                                    {buildings && buildings.map(b => <option key={b} value={b}>{b}</option>)}
                                                </select>
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <OnChange name='building'>
                                        {(value, previous) => {
                                            if (value !== previous) {
                                                form.change('id', undefined)
                                                handleSubmit()
                                            }
                                        }}
                                    </OnChange>

                                </div>
                                {search.building && <>
                                    <div className='col-md-12 col-lg-3'>
                                        <Field name="area">
                                            {({ input, meta }) => (
                                                <div>

                                                    <select className="form-control" {...input}>
                                                        <option>Rack</option>
                                                        {things && <>{[...new Set(things.filter(ddd => ddd.building === search.building).map(thinx => thinx.area))].map(b => <option key={b} value={b}>{b}</option>)}</>}

                                                    </select>
                                                    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>

                                        <OnChange name='area'>
                                            {(value, previous) => {
                                                if (value !== previous) {
                                                    form.change('id', undefined)
                                                    handleSubmit()
                                                }
                                            }}
                                        </OnChange>

                                    </div>
                                    {search.building && <>
                                        <div className='col-md-12 col-lg-3'>
                                            <Field name="row">
                                                {({ input, meta }) => (
                                                    <div>
                                                        <select className="form-control" {...input}>
                                                            <option>Row</option>
                                                            {things && <>{[...new Set(things.filter(ddd => ddd.building === search.building && ddd.area == search.area).map(thinx => thinx.row))].map(b => <option key={b} value={b}>{b}</option>)}</>}
                                                        </select>
                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>

                                            <OnChange name='row'>
                                                {(value, previous) => {
                                                    if (value !== previous) {
                                                        handleSubmit()
                                                    }
                                                }}
                                            </OnChange>
                                        </div>
                                    </>}
                                </>}
                            </div>




                        </form>

                    }} />
                <div className='btn-group'>
                    <button className="btn btn-info" id={`pdf`} type="button" onClick={(ev: React.MouseEvent) => {
                        ev.preventDefault();
                        RequestStockFunctionPost(addNotification, 'pdf', { ...search }).then(something => {
                            //       console.log("jhk__________", something.payload);
                            const linkSource = `data:application/pdf;base64,${something.payload}`;
                            const downloadLink = document.createElement("a");


                            downloadLink.href = linkSource;
                            downloadLink.download = "shelves.pdf";
                            downloadLink.click();
                        }).catch(Errr => {
                            console.log("err", Errr)
                        })
                    }}><FontAwesomeIcon icon={faFilePdf} /> Shelf Labels</button>


                    <button className="btn btn-info" id={`pdfRows`} type="button" onClick={(ev: React.MouseEvent) => {
                        ev.preventDefault();
                        RequestStockFunctionPost(addNotification, 'pdfRows', { ...search }).then(something => {
                            //       console.log("jhk__________", something.payload);
                            const linkSource = `data:application/pdf;base64,${something.payload}`;
                            const downloadLink = document.createElement("a");


                            downloadLink.href = linkSource;
                            downloadLink.download = "rows.pdf";
                            downloadLink.click();
                        }).catch(Errr => {
                            console.log("err", Errr)
                        })
                    }}><FontAwesomeIcon icon={faFilePdf} /> Row Labels</button>
                </div>


                <DataTable <IStore>
                    exportFileName="stores"

                    colHeadings={[
                        { field: 'id', name: 'id', sortable: true },
                        { field: 'building', name: 'Building', sortable: true },
                        { field: 'area', name: 'Rack', sortable: true },
                        { field: 'row', name: 'Row', sortable: true, },
                        { field: 'column', name: 'Column', sortable: true },
                        { field: 'products', name: 'Unique Products', sortable: true },
                        { field: 'totalStock', name: 'Total Items', sortable: true },
                        {
                            field: 'id', isButtonField: true, name: 'Act', sortable: false, r: ((val => {
                                return <>
                                    <DebugButton data={val} />
                                    <Link to={`/admin/stores/${val.id}`} className='btn btn-warning'>View Location</Link>
                                </>
                            }))
                        }
                    ]} data={things.filter(thing => {


                        if (search.id) {

                            if (thing.id === search.id.value) {
                                return thing;

                            }
                        }
                        let searches = 0;
                        let matches = 0;

                        if (search.building) {
                            searches++;
                            if (thing.building === search.building) {
                                matches++
                            }
                        }

                        if (search.area) {
                            searches++;
                            if (thing.area === search.area) {
                                matches++
                            }
                        }

                        if (search.row) {
                            searches++;
                            if (thing.row === parseInt(search.row)) {
                                matches++
                            }
                        }
                        if (searches === matches) {
                            return thing;
                        }
                    })} />






            </>
            }




            {/*<!-- OPTIONAL END -->*/}
        </div>}
    </>
    );
};
const AdminStores = () => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${match.path}/:id`}>
                <AdminStore />
            </Route>
            <Route path={`${match.path}`} exact>
                <AdminStoresPge />
            </Route>

        </Switch>
    </>
    );
}

export default AdminStores;