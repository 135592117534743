import React from 'react';
import RegisterBuyerForm from "./RegisterBuyerForm";

function RegisterBuyer() {
    return (
        <div className="container loginbody">            
            <div className="col-md-12">
                <div className="row">
                    <div className="contactfix col-md-6">
                        <RegisterBuyerForm />
                    </div>
                    <div className="col-md-6 d-flex align-items-stretch">
                        <img src="https://via.placeholder.com/555x1060" className="card-img-top" alt="..."></img>
                    </div>
                </div>
            </div>
            <hr className="gold" />
        </div>
    );
}

export default RegisterBuyer;