import { faMinus, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMutators from 'final-form-arrays';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Link } from 'react-router-dom';
import { RequestGetCheckoutCountries } from '../../api/checkout-requests';
import { RequestNewContactPublic } from '../../api/contact-requests';
import { RequestGetManufacturers } from '../../api/manufacturer-requests';
import DebugButton from '../../components/system/DebugButton';
import { composeValidators, required } from '../../components/system/FormStuff/validators';
import Loading from '../../components/system/loading';
import UploadImages from '../../components/system/UploadImages/UploadImages';
import { useAuth } from '../../context/auth';
import { useNotifications } from '../../context/notifications';
import { IBike } from '../../interface/bike';
import { ICheckoutCountry } from '../../interface/checkout';
import { IManufacturer } from '../../interface/manufacturer';


const RequestForm = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [finished, setFinished] = useState<boolean>(false);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { auth } = useAuth()!;
    const { addNotification } = useNotifications()!;
    const [startedLoading, setStartedLoading] = useState<boolean>(false);
    const [countries, setCo] = useState<ICheckoutCountry[]>();
    const [manufacturers, setMan] = useState<IManufacturer[]>();
    const [thing, setThing] = useState<any>({ addressCountry: 'GB', year: "1950", addMaillist: false, agree: false, parts: [{ partNo: '', images: [], description: '', quantity: 1 }] });
    const onSubmit = (data: any) => {
        setLoading(true)
        executeRecaptcha && executeRecaptcha("request_form").then(token => {
            data.id = 'new';
            data.recapture = token;
            data.type = 'request'
            data.status = 'new'
            console.log(data);
            RequestNewContactPublic(addNotification, data).then(req => {
                setFinished(true);
                setLoading(false);
            })
        }).catch(() => {
            setLoading(false)
        });
    }
    useEffect(() => {
        if (startedLoading === false) {
            setStartedLoading(true);

            RequestGetCheckoutCountries(addNotification, {}).then(c => {
                //    console.log("sdsdfsdf",c);
                setCo(c);

                RequestGetManufacturers(addNotification, {}).then(m => {
                    //    console.log("sdsdfsdf",c);
                    setMan(m)
                    setLoading(false)

                });

                //  addNotification && addNotification('Success', 'Saved Order', ENotificationType.Success)
            }).catch(ex => {

            })
        }
    }, [])

    return (<>
        {loading === true ? <Loading /> : <>{finished === true ? <>
            <h1>
                Thank you for your request, we will contact you shortly
            </h1></> :

            <Form
                onSubmit={onSubmit}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators
                }}
                validate={(values: any) => {
                    const errors: any = {};
                    if (values.agree !== true) {

                        errors.agree = 'you must agree to terms'
                    }
                    return errors;
                }}
                initialValues={thing}
                render={({ handleSubmit, form, submitting, pristine, values, errors }) => {

                    return <form onSubmit={handleSubmit}>
                        <DebugButton data={values} />

                        <div className="container">
                            <div className="card cardrequest p-2">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-12 p-2">
                                            <Field name="name" validate={composeValidators(required)}>
                                                {({ input, meta }) => (
                                                    <div className="input-container">

                                                        <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                                        <label className={`${input.value !== '' && 'filled'}`}>Your Name:</label>
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6 p-2">
                                            <Field name="phone" validate={composeValidators(required)}>
                                                {({ input, meta }) => (
                                                    <div className="pull-left input-container">
                                                        <label className={`${input.value !== '' && 'filled'}`}>Phone Number:</label>
                                                        <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="col-sm-12 col-lg-6 p-2">
                                            <Field name="email" validate={composeValidators(required)}>
                                                {({ input, meta }) => (
                                                    <div className="input-container">
                                                        {/* <label>Email</label> */}
                                                        <label className={`${input.value !== '' && 'filled'}`}>E-Mail:</label>
                                                        <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-4"> </div>
                            <div className="card cardrequest">
                                <div className="card-body">
                                    <div className="row hidemobile">
                                        <hr className="line" />
                                        <div className="mydiv"><h3 className="p-2">Address Details</h3></div>
                                    </div>
                                    <div className="row hidedesktop">
                                        <h3 className="p-2">Address Details</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-8 p-2">
                                            <Field name="addressStreet">
                                                {({ input, meta }) => (
                                                    <div className="input-container">
                                                        {/* <label className="float-left">House Number and Street Name</label> */}
                                                        <label className={`${input.value !== '' && 'filled'}`}>House Number & Street:</label>
                                                        <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />

                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="col-sm-12 col-lg-4 p-2">
                                            <Field name="addressTown">
                                                {({ input, meta }) => (
                                                    <div className="input-container">
                                                        <label className={`${input.value !== '' && 'filled'}`}>Town:</label>
                                                        <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />

                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-4 p-2">
                                            <Field name="addressCity">
                                                {({ input, meta }) => (
                                                    <div className="input-container">
                                                        <label className={`${input.value !== '' && 'filled'}`}>City:</label>
                                                        <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="col-sm-12 col-lg-4 p-2">
                                            <Field name="addressCounty">
                                                {({ input, meta }) => (
                                                    <div className="input-container">
                                                        <label className={`${input.value !== '' && 'filled'}`}>County:</label>
                                                        <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="col-sm-12 col-lg-4 p-2">
                                            <Field name="addressPostCode">
                                                {({ input, meta }) => (
                                                    <div className="input-container">
                                                        <label className={`${input.value !== '' && 'filled'}`}>Post Code / Zip:</label>
                                                        <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-12 p-2">
                                            <Field name="addressCountry">
                                                {({ input, meta }) => (
                                                    <div className="pull-left">
                                                        {/* <label className="float-left">Country</label> */}
                                                        <select className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input}>
                                                            <option value="">Country:</option>
                                                            {countries && countries.map((cat, index) => {
                                                                return <option value={cat.code}>{cat.name}</option>;
                                                            })}
                                                        </select>
                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-4"> </div>
                            <div className="card cardrequest">
                                <div className="card-body">

                                    <div className="row hidemobile">
                                        <hr className="line" />
                                        <div className="mydiv"><h3 className="p-2">Bike's Details</h3></div>
                                    </div>
                                    <div className="row hidedesktop">
                                        <h3 className="p-2">Bike's Details</h3>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-3 p-2">
                                            <OnChange name={`manufacturerId`}>
                                                {(value: any) => {
                                                    console.log("man", value)
                                                    if (manufacturers) {
                                                        const iiiiii = manufacturers.findIndex((ddd: IManufacturer) => ddd.id === value);
                                                        form.change('manufacturer', manufacturers[iiiiii])
                                                        console.log("manufacturer", iiiiii)
                                                    }
                                                }}
                                            </OnChange>
                                            {values.manufacturerId !== 'other' && <Field name="manufacturerId">
                                                {({ input, meta }) => (
                                                    <div>
                                                        {/* <label className="float-left">Manufacture</label> */}
                                                        <select className={`form-control fc-tweak ${meta.touched && meta.error && 'form-error'}`} {...input}>
                                                            <option value="">Manufacturer</option>

                                                            {manufacturers && manufacturers.map((man, index) => {
                                                                return <option key={index} value={man.id}>{man.name}</option>;
                                                            })}
                                                            <option value="other">Others</option>
                                                        </select>
                                                    </div>
                                                )}
                                            </Field>}

                                            {values.manufacturerId === 'other' && <Field name="otherManufacturer">
                                                {({ input, meta }) => (
                                                    <div className="pull-left">
                                                        {/* <label>Other Manufacturer</label> */}
                                                        <input type="text" className={`form-control fc-tweak ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="Manufacturer" />
                                                    </div>
                                                )}
                                            </Field>}

                                        </div>
                                        <div className="col-sm-12 col-lg-3 p-2">
                                            {values.bikeId !== 'other' && <Field name="bikeId">
                                                {({ input, meta }) => (
                                                    <div>
                                                        {/* <label className="float-left">Bike</label> */}
                                                        <select className={`form-control fc-tweak ${meta.touched && meta.error && 'form-error'}`} {...input}>
                                                            <option value="">Model</option>

                                                            {values.manufacturer && values.manufacturer.bikesObj.map((man: IBike, index: number) => {
                                                                return <option key={index} value={man.id}>{man.name}</option>;
                                                            })}

                                                            <option value="other">Others</option>
                                                        </select>
                                                        {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                    </div>
                                                )}
                                            </Field>}

                                            {values.bikeId === 'other' && <Field name="otherBike">
                                                {({ input, meta }) => (
                                                    <div className="pull-left">
                                                        {/* <label>Other?</label> */}
                                                        <input type="text" className={`form-control fc-tweak ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="Bike" />
                                                    </div>
                                                )}
                                            </Field>}
                                        </div>


                                        <div className="col-sm-12 col-lg-3 p-2">
                                            <Field name="color">
                                                {({ input, meta }) => (
                                                    <div className="pull-left">
                                                        {/* <label>Colour</label> */}
                                                        <input type="text" className={`form-control fc-tweak ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="Colour" />
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="col-sm-12 col-lg-3 p-2">
                                            <Field name="year">
                                                {({ input, meta }) => (
                                                    <div>
                                                        { }
                                                        <select className={`form-control fc-tweak ${meta.touched && meta.error && 'form-error'}`} {...input}>
                                                            <option value="">Year</option>

                                                            {Array.apply(1940, Array(100)).map((value: any, index: number) => <option key={index} value={1940 + index}>{1940 + index}</option>)}
                                                        </select>
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </div></div></div>
                            <div className="p-4"> </div>
                            <div className="card cardrequest">
                                <div className="card-body">

                                    <div className="row hidemobile">
                                        <hr className="line" />
                                        <div className="mydiv"><h3 className="p-2">Part's Details</h3></div>
                                    </div>
                                    <div className="row hidedesktop">
                                        <h3 className="p-2">Part's Details</h3>
                                    </div>


                                    <FieldArray name="parts">
                                        {({ fields }) => (<div>


                                            {fields.map((name, index) => {
                                                let indexNamed = '';
                                                switch (index) {
                                                    case 0:
                                                        indexNamed = "1st"
                                                        break;
                                                    case 1:
                                                        indexNamed = "2nd"
                                                        break;
                                                    case 2:
                                                        indexNamed = "3rd"
                                                        break;
                                                    default:
                                                        indexNamed = (index + 1) + "th"
                                                }

                                                const part = values.parts[index];
                                                return <div className="row" key={name}>


                                                    <div className="col-12 pull-left p-2">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="d-flex justify-content-between">
                                                                    <h4 className="goldfont">{indexNamed} Part</h4>
                                                                    <button className="btn btn-danger" type="button" onClick={() => fields.remove(index)}> <FontAwesomeIcon icon={faTrashAlt} /></button>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-sm-12 col-lg-6 p-2">
                                                                        <div className="input-container">
                                                                            <Field name={`${name}.partNo`}>
                                                                                {({ input, meta }) => (
                                                                                    <>
                                                                                        <label className={`${input.value !== '' && 'filled'}`}>Part number (if known):</label>
                                                                                        <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder={``} />
                                                                                    </>
                                                                                )}
                                                                            </Field>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-sm-12 col-lg-6 p-2">
                                                                        <div className="row">
                                                                            <div className="col-6 paddingtop">
                                                                                <p className="text-right goldfont">Quantity Required:</p>
                                                                            </div>
                                                                            <div className="col-6 paddingtop1">

                                                                                <button className="btn btn-gold" type="button" onClick={(ev: React.MouseEvent) => {
                                                                                    ev.preventDefault();
                                                                                    const newQ = part.quantity - 1;
                                                                                    if (newQ > 0) {
                                                                                        const parts = [...values.parts];
                                                                                        const xPart = { ...part };
                                                                                        xPart.quantity = newQ;
                                                                                        parts[index] = xPart;
                                                                                        form.change('parts', parts)
                                                                                    }
                                                                                }}> <FontAwesomeIcon icon={faMinus} /></button>

                                                                                <span className="paddingquantity goldfont">{part.quantity}</span>


                                                                                <button className="btn btn-gold" type="button" onClick={(ev: React.MouseEvent) => {
                                                                                    ev.preventDefault();
                                                                                    const newQ = part.quantity + 1;
                                                                                    if (newQ > 0) {
                                                                                        const parts = [...values.parts];
                                                                                        const xPart = { ...part };
                                                                                        xPart.quantity = newQ;
                                                                                        parts[index] = xPart;
                                                                                        form.change('parts', parts)
                                                                                    }
                                                                                }}> <FontAwesomeIcon icon={faPlus} /></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div className="row">
                                                                    <div className="col-sm-12 col-lg-12 p-2 input-container">
                                                                        <Field name={`${name}.description`} validate={composeValidators(required)}>
                                                                            {({ input, meta }) => (
                                                                                <>
                                                                                    <label className={`${input.value !== '' && 'filled'}`}>Description:</label>
                                                                                    <textarea type="text" className={`form-control-tweaktarea ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder={``} />

                                                                                </>
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                </div>
                                                                <label>If you have any images of this part to hand, please upload an image of the part using 'Add Images' button below. </label>
                                                                <FieldArray name={`${name}.images`}>
                                                                    {(prop: any) => (<><div className="col-12">
                                                                        <UploadImages aspect="none" onChange={(event: any) => {
                                                                            prop.fields.push(event)
                                                                        }} />
                                                                    </div>

                                                                        <div className="row">
                                                                            {prop.fields.map((name: string, iindex: number) => {
                                                                                console.log(values.parts[index]);

                                                                                const image: any = values.parts[index].images[iindex];

                                                                                if (image) {
                                                                                    let src = image.url;
                                                                                    //    console.log(image);
                                                                                    if (image.croppedSrcData) {
                                                                                        src = image.croppedSrcData;
                                                                                    }

                                                                                    return <div key={name} className="col-6"><img className="img-fluid w-90" src={src} /></div>
                                                                                }
                                                                            })}
                                                                        </div>

                                                                    </>
                                                                    )}
                                                                </FieldArray>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            })}

                                            <button
                                                className="btn btn-success"
                                                type="button"
                                                onClick={(ev: React.MouseEvent) => {
                                                    ev.preventDefault();
                                                    fields.push({ partNo: '', images: [], description: '', quantity: 1 })
                                                }}
                                            >Add Another Part</button>
                                        </div>
                                        )}
                                    </FieldArray>
                                </div>
                            </div>

                            <div className="p-4"> </div>
                            <div className="">
                                <div className="">
                                    <div className="row">
                                        <div className="col-sm-12 col-lg-12 p-4 input-container">
                                            <Field name="message">
                                                {({ input, meta }) => (
                                                    <div className="pull-left">
                                                        <label className={`commentadd ${input.value !== '' && 'filled'}`}>Additional Notes:</label>
                                                        <textarea type="text" className={`form-control-tweaktarea ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                                    </div>
                                                )}
                                            </Field>

                                        </div>
                                    </div></div></div>



                            <div className="p-4 pull-left">
                                <div className="row">
                                    <div className="col-1 flex0">
                                        <Field
                                            name="agree"
                                            component="input"
                                            type="checkbox"
                                            className=""
                                            id="agree"
                                        />
                                    </div>
                                    <div className="col-10">
                                        <label htmlFor="agree" className="pr-3"> By clicking here you are agreeing to our <Link to="/terms">Terms and Conditions</Link>
                                            {errors && errors['agree'] && <span className="text-danger paddingquantity">(required)</span>}</label>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-1 flex0">
                                        <Field
                                            name="marketing"
                                            component="input"
                                            type="checkbox"
                                            // value={true}
                                            className=""
                                            id="marketing"
                                        />
                                    </div>
                                    <div className="col-10">
                                        <label htmlFor="marketing" className="pr-3">Click here to opt-in our Mailing List</label>
                                    </div>
                                </div>
                            </div>

                            <div className="button-group p-4">
                                <button className="signin-button buttonfix reqbtnfix" type="submit">Send Request</button>
                            </div></div>
                    </form>

                }} />}
        </>}

    </>
    );
}

export default RequestForm;