import React, { useEffect, useState } from "react";
import {  Prompt, Redirect, useLocation, useRouteMatch } from "react-router-dom";

import { RequestNewLog } from "../../../api/log-requests";
import { RequestGetStock, RequestNewStock, RequestUpdateStock } from "../../../api/stock-requests";
import { IStock } from "../../../interface/stock";

import { ENotificationType, useNotifications } from "../../../context/notifications";

import Loading from "../../../components/system/loading";
import { ELogType, ESeverity } from "../../../interface/log";
import JSONPretty from 'react-json-prettify';

import { useAuth } from "../../../context/auth";
import { Helmet } from "react-helmet";
import StockLabelDisplay from "../../../components/StockLable";


const AdminStock = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IStock>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/stock/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
          //  console.log("lkjldfkjslkdfjglskdfjglksdfjgsl;kdfjgsl;kdfjgslk;dfjgsld;fkjg;sldkfjgs;dfl", match.params);
            const id = match.params.id;
            console.log("lkjldfkjslkdfjglskdfjglksdfjgsl;kdfjgsl;kdfjgslk;dfjgsld;fkjg;sldkfjgs;dfl", id);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }

                if (id === 'new') {
               //     setThing({ id: 'new', name: '', description: '' })
                    Promise.allSettled([]).then(() => {
                        setLoading(false)
                    });
                } else {
                    const prodCall = () => {
                        return RequestGetStock(addNotification, id).then(thing => {
                            setThing(thing);
                        })
                    }

                    Promise.allSettled([prodCall()]).then(() => {
                        setLoading(false)
                    });
                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            console.log(ex);
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Stock - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IStock>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Stock Created!';
                ppp = RequestNewStock(addNotification, data);
            } else {
                notificationText = 'Stock Saved!';
                ppp = RequestUpdateStock(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
         
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Stock - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 3 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../stocks" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Stock / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 4 -->*/}
            <>
            {thing && thing.product && <><StockLabelDisplay stock={thing} product={thing.product} thing={thing}/></>} 


            <h2>Debug Stuff</h2>
            <div className="text-left">
                 <JSONPretty json={thing} />

            </div>

            </>
            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminStock;