import { faCheck, faMinus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMutators from 'final-form-arrays';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { Link } from 'react-router-dom';
import { RequestGetCheckoutCountries, RequestNewCheckout } from '../../api/checkout-requests';
import { RequestGetProducts } from '../../api/product-requests';
import { RequestGetUsersPost, RequestNewUser } from '../../api/user-requests';
import AdminCheckoutStockLocations from '../../components/AdminCheckoutStockLocations';
import { promiseOptionsProduct, promiseOptionsProductCategory, promiseOptionsProductManufacturer } from '../../components/promises';
import DataTable from '../../components/system/DataTable';
import DebugButton from '../../components/system/DebugButton';
import DateField from '../../components/system/FormStuff/date';
import { BasicAsyncSelectAdapter } from '../../components/system/FormStuff/ReactSelect';
import { composeValidators, required } from '../../components/system/FormStuff/validators';
import ImageDisplay from '../../components/system/ImageDisplay';
import Loading from '../../components/system/loading';
import { useNotifications } from '../../context/notifications';
import { ECheckoutState, ICheckout, ICheckoutCountry, ICheckoutItem } from '../../interface/checkout';
import { IProduct } from '../../interface/product';
import { IProductCategory } from '../../interface/product-category';
import { IUser } from '../../interface/user';

const Radio = ({ input, children }: any) => {
    // input should contain checked value to indicate
    // if the input is checked
    console.log(input)
    return < label >
        <input type="radio" {...input} />
        {children}
    </label >;
}


const AdminCheckoutInStore = () => {
    //{ productId: 'na', productName: '', qty: 0, price: 0, weight: 0, itemTotal: 0, weightTotal: 0 }
    const blankThing: any = { _qty: 1, xMode: '', xxxxxx: 1, knownCustomer: "false", firstName: '', lastName: '', formDate: moment().format('DD-MM-YYYY'), id: 'new', status: ECheckoutState['Shipping Complete'], gatewayType: 'cash', customersFound: undefined, type: 'shop', addressCountry: 'GB', cartItems: [], };
    const { addNotification } = useNotifications()!;
    const [loading, setLoading] = useState<boolean>(true);
    const [startedLoading, setStartedLoading] = useState<boolean>(false);
    const [countries, setCo] = useState<ICheckoutCountry[]>();
    const [categories, setTypes] = useState<IProductCategory[]>();
    const [thing, setThing] = useState<any>({ ...blankThing });
    const [response, setResponse] = useState<any>();
    const [redirect, setRedirect] = useState<boolean>();
    const [redirectTo, setRedirectTo] = useState<string>();

    const onSubmitD = (d: ICheckout) => {
        console.log("onSubmit", d)
        RequestNewCheckout(addNotification, d).then(dsdd => {
            console.log("RequestNewCheckout", dsdd);
            setResponse(dsdd);
            //  setThing({ ...blankThing })
        }).catch(exc => {
            console.log(exc)
        })
    }

    useEffect(() => {

        const Go = async () => {
            setStartedLoading(true);
            setThing({ ...blankThing })
            setCo(await RequestGetCheckoutCountries(addNotification, {}))
            setLoading(false)
        }


        if (startedLoading === false) {
            Go();
        }

    }, [])


    return <>

        {loading === true ? <Loading /> :
            <>
                {response ? <>
                    <h2>Order Packing/Confirm</h2>
                    <span id="checkoutId">{response.id}</span>
                    <AdminCheckoutStockLocations checkout={response} setCheckout={setResponse} />


                    <Link id="checkoutViewOrderButton" className='btn btn-warning' to={`/admin/checkouts/${response.id}`}>View Order</Link>

                </> : <Form <ICheckout>
                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators
                    }}
                    onSubmit={(d) => {
                        onSubmitD(d)
                    }}
                    initialValues={thing}
                    validate={(values) => {
                        const errors: any = {};
                        console.log(values.totalTotal)
                        if (values.totalTotal === 0) {
                            errors['totalTotal'] = "Must be more than 0"
                        }
                        console.log(errors)
                        return errors;
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values, errors }) => {

                        // thisfuckinghackValues = values;



                        const calculateTotal = () => {

                            const cartItems = [...values.cartItems]
                            for (let index = 0; index < cartItems.length; index++) {
                                //const element = cartItems[index];

                                const qty = cartItems[index].qty;

                                const price = cartItems[index].price;

                                cartItems[index].itemTotal = (qty * price)

                            }


                            form.change('totalTotal', cartItems.map(dd => dd.itemTotal).reduce((a, b) => a + b, 0));
                        }

                        const findProducts = async () => {

                            const query: any = {};
                            if (values._productCategoryId) {
                                query.productCategoryId = values._productCategoryId.value
                            }

                            if (values._productManufacturerId) {
                                query.productManufacturerId = values._productManufacturerId.value
                            }


                            if (values._description) {
                                query.xText = values._description;
                            }

                            try {
                                const foundProducts = await RequestGetProducts(addNotification, query);
                                form.change('findProducts', foundProducts)
                            } catch (e) {
                                form.change('findProducts', [])
                            }
                        }

                        return <form onSubmit={handleSubmit} id="checkoutInStoreForm">
                            <div className="row">
                                <div className="col-12">
                                    <h2>In-Store Checkout</h2>
                                    <p className="bg-primary">Internal checkout form, used to allow sales from in-store and via phone also legacy doesn't effect the stock system but is used to see orders made before the system.</p>
                                </div>

                                <div className="col-12">

                                    <h3>Order Items</h3>


                                    <FieldArray <ICheckoutItem> name="cartItems">
                                        {({ fields }) => {




                                            return <>


                                                <button type='button' id="btnDBMode" onClick={() => {
                                                    form.change('_mode', 'db')
                                                }} className="btn btn-warning">Add Database Product</button>
                                                <button type='button' id="btnLegacyMode" onClick={() => {
                                                    form.change('_mode', 'legacy')
                                                }} className="btn btn-warning">Add Legacy Product</button>


                                                <div className="row">
                                                    {values._mode === 'db' && <div id="dbProductAdd" className="col-12">
                                                        <h3>On Database Product</h3>

                                                        <div className="col-12">

                                                            <div className='row'>
                                                                <div className='col-sm-12 col-md-1'>
                                                                    QTY
                                                                    <Field
                                                                        name={`_qty`}
                                                                        parse={(value, name) => {
                                                                            //  console.log("parse", value, name)
                                                                            return parseInt(value);
                                                                        }}
                                                                        format={(value, name) => {
                                                                            //  console.log("format", value, name)

                                                                            return parseInt(value);
                                                                        }}>
                                                                        {({ input, meta }) => (
                                                                            <div>
                                                                                <input type="number" id={`_qty`} data-testId={`_qty`} className="form-control" {...input} placeholder="...." />
                                                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                            </div>
                                                                        )}
                                                                    </Field>
                                                                </div>
                                                                <div className='col-sm-12 col-md-3'>
                                                                    Part Number
                                                                    <Field
                                                                        name="_partNumber"
                                                                        component={BasicAsyncSelectAdapter}
                                                                        inputId={'_partNumber'}
                                                                        promiseOptions={promiseOptionsProduct}
                                                                        autoFocus
                                                                    />
                                                                    <OnChange name='_partNumber'>
                                                                        {(value, previous) => {
                                                                            console.log(value)

                                                                            if (value) {
                                                                                if (value !== previous) {
                                                                                    const qtty = (values._qty ? values._qty : 1)
                                                                                    const indexee = fields.value.findIndex((d: any) => d.productId === value.value)
                                                                                    if (indexee === -1) {
                                                                                        fields.push({ qty: qtty, productId: value.value, partNumber: value.label, productDescription: value.label, price: value.newSalePrice, weight: value.weight, itemTotal: value.newSalePrice, weightTotal: value.weight, image: value.image })

                                                                                    } else {
                                                                                        const line = fields.value[indexee];
                                                                                        line.qty = line.qty + qtty;
                                                                                        fields.update(indexee, line)
                                                                                    }
                                                                                    form.change('_qty', 1)
                                                                                    form.change('_mode', '')
                                                                                    form.change('_partNumber', undefined)
                                                                                    form.change('findProducts', undefined)
                                                                                }
                                                                            }
                                                                        }}
                                                                    </OnChange>
                                                                </div>
                                                                <div className='col-sm-12 col-md-2'>
                                                                    Category
                                                                    <Field name="_productCategoryId"
                                                                        title="Category" component={BasicAsyncSelectAdapter}
                                                                        promiseOptions={promiseOptionsProductCategory}
                                                                        inputId={"productCategoryId"}
                                                                        description="What is this item"
                                                                    />
                                                                    <OnChange name='_productCategoryId'>
                                                                        {(value, previous) => {

                                                                            if (value !== previous) {
                                                                                findProducts()
                                                                            }
                                                                        }}
                                                                    </OnChange>
                                                                </div>
                                                                <div className='col-sm-12 col-md-3'>
                                                                    Manufacturer
                                                                    <Field name="_productManufacturerId"
                                                                        title="Part Manufacturer"
                                                                        component={BasicAsyncSelectAdapter}
                                                                        promiseOptions={promiseOptionsProductManufacturer}
                                                                        description="Whom made this"
                                                                        inputId={"productManufacturerId"}
                                                                    />

                                                                    <OnChange name='_productManufacturerId'>
                                                                        {(value, previous) => {

                                                                            if (value !== previous) {
                                                                                findProducts()
                                                                            }
                                                                        }}
                                                                    </OnChange>
                                                                </div>
                                                                <div className='col-sm-12 col-md-2'>
                                                                    All Text Search
                                                                    <Field
                                                                        name={`_description`}
                                                                        component='input'
                                                                        type='text'
                                                                        className="form-control"
                                                                        id="description"
                                                                    />

                                                                    <OnChange name='_description'>
                                                                        {(value, previous) => {
                                                                            // if (value !== previous) {
                                                                            //     findProducts()
                                                                            // }
                                                                            // if (value !== previous) {
                                                                            //     handleSubmit()
                                                                            // }
                                                                        }}
                                                                    </OnChange>
                                                                </div>
                                                                <div className='col-sm-12 col-md-1'>
                                                                    <br />
                                                                    <button type="button" className='btn btn-warning' onClick={findProducts}>Find</button>

                                                                </div>
                                                            </div>
                                                            {values.findProducts !== undefined && <>

                                                                <DataTable <IProduct>
                                                                    colHeadings={[


                                                                        { name: "Part Number", field: "partNumber", sortable: true, width: "20px" },
                                                                        {
                                                                            name: "Image", field: "image", width: "20px", sortable: false, r: (value: any, index) => {
                                                                                return <>{value.image && <ImageDisplay image={value.image} />}</>;
                                                                            }
                                                                        },
                                                                        { name: "Category", field: "productCategoryStr", width: "20px", sortable: true },
                                                                        { name: "Manufacturer", field: "productManufacturerStr", width: "20px", sortable: true },
                                                                        { name: "Price", field: "newSalePrice", width: "20px", sortable: true },
                                                                        { name: "Sold", field: "stockSold", width: "20px", sortable: true },
                                                                        { name: "Stock", field: "stock", width: "20px", sortable: true },
                                                                        {
                                                                            name: "Actions", field: "id", width: "20px", isButtonField: true, r: (value, index) => {
                                                                                return <div className="btn-group">
                                                                                    <DebugButton data={value} />
                                                                                    {/* <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link> */}
                                                                                    <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                                                                        ev.preventDefault();
                                                                                        // fields.push({ qty: 1, productId: value.id, partNumber: value.partNumber, price: value.newSalePrice, weight: 0, itemTotal: 0, weightTotal: 0 })
                                                                                        form.change('findProducts', undefined)
                                                                                        form.change('_qty', 1)
                                                                                        form.change('_mode', '')
                                                                                        const qtty = (values._qty ? values._qty : 1)
                                                                                        const indexee = fields.value.findIndex((d: any) => d.productId === value.id)
                                                                                        if (indexee === -1) {

                                                                                            fields.push({ qty: qtty, productId: value.id, partNumber: value.partNumber, productDescription: value.partNumber, price: value.newSalePrice, weight: value.weight, itemTotal: value.newSalePrice, weightTotal: value.weight, image: value.image })

                                                                                        } else {
                                                                                            const line = fields.value[indexee];
                                                                                            line.qty = line.qty + qtty;
                                                                                            fields.update(indexee, line)
                                                                                        }



                                                                                    }}>Add</button>
                                                                                </div>;
                                                                            }, sortable: false
                                                                        },
                                                                    ]}
                                                                    data={values.findProducts} />
                                                                <button type='button' className='btn btn-warning' onClick={() => {
                                                                    form.change('findProducts', undefined)
                                                                }}>Clear Search</button>
                                                            </>

                                                            }
                                                        </div>
                                                    </div>}
                                                    {values._mode === 'legacy' && <div className="col-12">
                                                        <h3>NOT On Database Product</h3>
                                                        <div className='col-12'>
                                                            description
                                                            <Field
                                                                name={`__description`}
                                                                component='input'
                                                                type='text'
                                                                className="form-control"
                                                                id="description"
                                                            />
                                                            price
                                                            <Field
                                                                name={`_price`}
                                                                parse={(value, name) => {
                                                                    //  console.log("parse", value, name)
                                                                    return parseFloat(value);
                                                                }}
                                                                format={(value, name) => {
                                                                    //  console.log("format", value, name)

                                                                    return parseFloat(value);
                                                                }}
                                                                id={'price'}
                                                                component={"input"}
                                                                type="number"
                                                                className="form-control"

                                                            />

                                                            <button type="button" className='btn btn-warning' onClick={async () => {
                                                                const desc = values.__description;
                                                                const price = values._price;


                                                                if (desc && (price && price > 0)) {
                                                                    //NOT DATABASE ITEM
                                                                    fields.push({ qty: 1, type: 'legacy', productId: '', productDescription: desc, price: price, weight: 0, itemTotal: price, weightTotal: 0, image: undefined })
                                                                    form.change('__description', undefined)
                                                                    form.change('_price', undefined)
                                                                    form.change('_mode', undefined)
                                                                } else {
                                                                    alert("Enter Description & Price")
                                                                }

                                                            }}>Add</button>
                                                        </div>
                                                    </div>}
                                                </div>


                                                <table className="table">
                                                    <tr>
                                                        <th style={{ width: "55%" }}>Part Number</th>
                                                        <th style={{ width: "15%" }}>Qty</th>
                                                        <th style={{ width: "15%" }}>Price</th>
                                                        <th style={{ width: "15%" }}>Total</th>
                                                        <th>
                                                        </th>
                                                    </tr>


                                                    {fields.map((name, index) => {
                                                        const item = fields.value[index];
                                                        return <tr key={index} id={`cartItem_${index}}`}>
                                                            <td className='text-center'>


                                                                {item.partNumber ? <span data-testId={`cartItem${index}_partNumber`}>{item.partNumber} </span> : <span data-testId={`cartItem${index}_description`}>{item.productDescription} </span>}


                                                                {item.image && <div className="w-50"><ImageDisplay image={item.image} /></div>}
                                                            </td>

                                                            <td>
                                                                <Field
                                                                    name={`${name}.qty`}
                                                                    parse={(value, name) => {
                                                                        //  console.log("parse", value, name)
                                                                        return parseInt(value);
                                                                    }}
                                                                    format={(value, name) => {
                                                                        //  console.log("format", value, name)

                                                                        return parseInt(value);
                                                                    }}>
                                                                    {({ input, meta }) => (
                                                                        <div>

                                                                            <input type="number" id={`cartItem${index}_qty`} data-testId={`cartItem${index}_qty`} className="form-control" {...input} placeholder="...." />
                                                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                                        </div>
                                                                    )}
                                                                </Field>



                                                            </td>
                                                            <td>
                                                                £<span id={`cartItem${index}_price`} data-testId={`cartItem${index}_price`} >{item.price}</span>
                                                            </td>
                                                            <td>
                                                                £<span id={`cartItem${index}_itemTotal`} data-testId={`cartItem${index}_itemTotal`} >{item.itemTotal}</span>

                                                            </td>
                                                            <td>
                                                                <button type="button" className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                                                    ev.preventDefault();
                                                                    fields.remove(index);
                                                                }}><FontAwesomeIcon icon={faMinus} /></button>
                                                            </td>
                                                        </tr>
                                                    })}
                                                    <tr>
                                                        <td colSpan={3}></td>
                                                        <td className="text-left" >£ <span id="totalTotal">{values.totalTotal && values.totalTotal.toFixed(2)}</span></td>
                                                    </tr>
                                                </table>


                                            </>
                                        }}
                                    </FieldArray>
                                    <OnChange name={`cartItems`}>
                                        {(value, previous) => {

                                            if (value !== previous) {
                                                calculateTotal();
                                            }
                                        }}
                                    </OnChange>


                                </div>


                                <div className="col-12">
                                    Payment Method
                                    <label>
                                        <Field
                                            name="gatewayType"
                                            component="input"
                                            type="radio"
                                            value="cash"
                                            validate={composeValidators(required)}
                                        />{' '}
                                        Cash
                                    </label>
                                    <label>
                                        <Field
                                            name="gatewayType"
                                            component="input"
                                            type="radio"
                                            value="card"
                                            validate={composeValidators(required)}
                                        />{' '}
                                        Card
                                    </label>

                                </div>
                                {values.cartItems.length > 0 && <div className="col-12">

                                    <h3>Customer Details</h3>

                                    <div className="row">
                                        <div className="col-sm-12 col-lg-6">
                                            <Field name="firstName">
                                                {({ input, meta }) => (
                                                    <div className="form-group">
                                                        <label className="float-left">First Name</label>
                                                        <input type="text" className="form-control" {...input} placeholder="" />
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                        <div className="col-sm-12 col-lg-6">
                                            <Field name="lastName">
                                                {({ input, meta }) => (
                                                    <div className="form-group">
                                                        <label className="float-left">Last Name</label>
                                                        <input type="text" className="form-control" {...input} placeholder="" />
                                                    </div>
                                                )}
                                            </Field>
                                        </div>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <Field name="knownCustomer" value="true" type="radio" component={Radio}>
                                            Known Customer
                                        </Field>
                                        <Field name="knownCustomer" value="false" type="radio" component={Radio}>
                                            Unknown Customer
                                        </Field>
                                    </div>

                                    {values.knownCustomer === "true" && <div className="row rowadmin">
                                        {values.user === undefined ? <>
                                            <div className="col-12">

                                                <Field name="company">
                                                    {({ input, meta }) => (
                                                        <div className="form-group">
                                                            <label className="float-left">Company or Organisation</label>
                                                            <input type="text" className="form-control" {...input} placeholder="" />
                                                        </div>
                                                    )}
                                                </Field>
                                                <Field name="email">
                                                    {({ input, meta }) => (
                                                        <div className="form-group">
                                                            <label className="float-left">Email Address</label>
                                                            <input type="text" className="form-control" {...input} placeholder="" />
                                                        </div>
                                                    )}
                                                </Field>



                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-6">
                                                        <Field name="mobile">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label className="float-left">Mobile Number</label>
                                                                    <input type="text" className="form-control" {...input} placeholder="" />
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className="col-sm-12 col-lg-6">
                                                        <Field name="landline">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label className="float-left">Phone Number</label>
                                                                    <input type="text" className="form-control" {...input} placeholder="" />
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>

                                                <h4>Billing Address</h4>

                                                <Field name="addressStreet">
                                                    {({ input, meta }) => (
                                                        <div className="form-group">
                                                            <label className="float-left">House Number and Street Name</label>
                                                            <input type="text" className="form-control" {...input} placeholder="" />
                                                        </div>
                                                    )}
                                                </Field>

                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-6">
                                                        <Field name="addressTown">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label className="float-left">Town</label>
                                                                    <input type="text" className="form-control" {...input} placeholder="" />
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className="col-sm-12 col-lg-6">
                                                        <Field name="addressCity">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label className="float-left">City</label>
                                                                    <input type="text" className="form-control" {...input} placeholder="" />
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-6">
                                                        <Field name="addressCounty">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label className="float-left">County</label>
                                                                    <input type="text" className="form-control" {...input} placeholder="" />
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                    <div className="col-sm-12 col-lg-6">
                                                        <Field name="addressPostCode">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label className="float-left">Postcode</label>
                                                                    <input type="text" className="form-control" {...input} placeholder="" />
                                                                </div>
                                                            )}
                                                        </Field>
                                                    </div>
                                                </div>

                                                <Field name="addressCountry">
                                                    {({ input, meta }) => (
                                                        <div>
                                                            <label className="float-left">Country</label>
                                                            <select className="form-control" {...input}>
                                                                <option></option>
                                                                {countries && countries.map((cat, index) => {
                                                                    return <option value={cat.code}>{cat.name}</option>;
                                                                })}

                                                            </select>
                                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                                        </div>
                                                    )}
                                                </Field>
                                                <div className="row">
                                                    <div className="col-sm-12 col-lg-6 p-3">
                                                        <button className="btn btn-primary" type="button" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();

                                                            const _or: any[] = [];
                                                            if (values.lastName && values.lastName !== '') {
                                                                _or.push({ lastName: values.lastName })
                                                            }
                                                            if (values.firstName && values.firstName !== '') {
                                                                _or.push({ firstName: values.firstName })
                                                            }
                                                            if (values.email && values.email !== '') {
                                                                _or.push({ email: values.email })
                                                            }

                                                            if (values.addressPostCode && values.addressPostCode !== '') {
                                                                _or.push({ addressPostCode: values.addressPostCode })
                                                            }
                                                            if (values.addressCity && values.addressCity !== '') {
                                                                _or.push({ addressCity: values.addressCity })
                                                            }


                                                            console.log(_or.length)
                                                            let search: any = null;

                                                            search = {
                                                                __or: _or
                                                            };
                                                            //  }
                                                            console.log(search)
                                                            if (search !== null) {
                                                                RequestGetUsersPost(addNotification, search).then(ddd => {
                                                                    console.log("sss", search, ddd)
                                                                    form.change('customersFound', ddd)

                                                                })
                                                            }

                                                        }}>
                                                            Search for Customer <FontAwesomeIcon icon={faSearch} /></button>
                                                    </div>
                                                    <div className="col-sm-12 col-lg-6 p-3">
                                                        <button className="btn btn-primary" type="button" onClick={(ev: React.MouseEvent) => {
                                                            ev.preventDefault();
                                                            console.log(values)

                                                            if (values.email &&
                                                                values.mobile &&
                                                                values.firstName &&
                                                                values.lastName &&
                                                                values.landline &&
                                                                values.addressStreet &&
                                                                values.addressCity &&
                                                                values.addressCountry &&
                                                                values.addressPostCode &&
                                                                values.addressTown) {

                                                                const user: IUser = {
                                                                    id: 'new',
                                                                    company: '',
                                                                    hasAccess: false,
                                                                    subType: 'customer',
                                                                    username: values.email,
                                                                    email: values.email,
                                                                    mobile: values.mobile,
                                                                    firstName: values.firstName,
                                                                    lastName: values.lastName,
                                                                    validateCode: '',
                                                                    validated: null,
                                                                    marketing: false,
                                                                    terms: false,
                                                                    landline: values.landline,
                                                                    addressStreet: values.addressStreet,
                                                                    addressCity: values.addressCity,
                                                                    addressCountry: values.addressCountry,
                                                                    addressCounty: '',
                                                                    addressPostCode: values.addressPostCode,
                                                                    addressTown: values.addressTown,
                                                                    type: 'client',
                                                                    password: '',
                                                                    config: [],
                                                                    notifications: [],
                                                                    permissions: [],

                                                                }
                                                                if (values.company) {
                                                                    user.company = values.company
                                                                }
                                                                if (values.addressCounty) {
                                                                    user.addressCounty = values.addressCounty
                                                                }

                                                                RequestNewUser(addNotification, user).then(dbUser => {
                                                                    console.log("dbUser", dbUser)
                                                                    const ss: IUser = dbUser;
                                                                    form.change('user', ss)
                                                                    form.change('userId', ss.id);
                                                                    form.change('firstName', ss.firstName);
                                                                    form.change('lastName', ss.lastName);
                                                                    form.change('name', ss.firstName + ' ' + ss.lastName);


                                                                    form.change('company', ss.company);
                                                                    form.change('addressBillingCity', ss.addressCity);
                                                                    form.change('addressDeliveryCity', ss.addressCity);
                                                                    form.change('addressBillingStreet', ss.addressStreet);
                                                                    form.change('addressDeliveryStreet', ss.addressStreet);
                                                                    form.change('addressBillingCountry', ss.addressCountry);
                                                                    form.change('addressDeliveryCountry', ss.addressCountry);
                                                                    form.change('addressBillingCounty', ss.addressCounty);
                                                                    form.change('addressDeliveryPostCode', ss.addressCounty);
                                                                    form.change('addressDeliveryPostCode', ss.addressPostCode);
                                                                });
                                                            } else {
                                                                alert('please enter full details to set up new user')
                                                            }
                                                        }}>New Customer</button>
                                                    </div>
                                                </div>
                                            </div>


                                            {values.customersFound && <div className="col-12">
                                                <DataTable data={values.customersFound}
                                                    colHeadings={[
                                                        { name: "User", field: "username", sortable: false, queryable: false },
                                                        { name: "Type", field: "type", sortable: false, queryable: true },
                                                        { name: "First Name", field: "firstName", sortable: false, queryable: false },
                                                        { name: "Last Name", field: "lastName", sortable: false, queryable: false },
                                                        { name: "Email", field: "email", sortable: false, queryable: false },
                                                        {
                                                            sortable: false, name: "Actions", field: "id", r: (value: any, index) => {
                                                                return <div className="btn-group">
                                                                    <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                                                        ev.preventDefault();
                                                                        const ss: IUser = value;
                                                                        form.change('user', ss)
                                                                        form.change('userId', ss.id);
                                                                        form.change('firstName', ss.firstName);
                                                                        form.change('lastName', ss.lastName);
                                                                        form.change('name', ss.firstName + ' ' + ss.lastName);
                                                                        form.change('company', ss.company);
                                                                        form.change('addressBillingCity', ss.addressCity);
                                                                        form.change('addressDeliveryCity', ss.addressCity);
                                                                        form.change('addressBillingStreet', ss.addressStreet);
                                                                        form.change('addressDeliveryStreet', ss.addressStreet);
                                                                        form.change('addressBillingCountry', ss.addressCountry);
                                                                        form.change('addressDeliveryCountry', ss.addressCountry);
                                                                        form.change('addressBillingCounty', ss.addressCounty);
                                                                        form.change('addressDeliveryPostCode', ss.addressCounty);
                                                                        form.change('addressDeliveryPostCode', ss.addressPostCode);

                                                                    }}><FontAwesomeIcon icon={faCheck} />
                                                                    </button>
                                                                    <DebugButton data={value} alwaysDisplay={true} />
                                                                </div>
                                                            }
                                                        }]} />

                                            </div>}


                                        </> : <>
                                            <h2>Customer</h2>

                                            {values.user && values.user.firstName} {values.user && values.user.lastName}
                                            <br />
                                            <button type="button" className="btn btn-warning" onClick={(ev: React.MouseEvent) => {
                                                ev.preventDefault();
                                                form.change('user', undefined);
                                                form.change('userId', '');
                                            }}>Reset</button>
                                        </>}

                                    </div>}
                                    <div className="col-12">
                                        <h3>Order Date</h3>
                                        <OnChange name={`formDate`}>
                                            {(value, previous) => {
                                                // do something


                                                const myMomentObject: Date = moment(value, 'DD-MM-YYYY').toDate()
                                                console.log(value, myMomentObject)
                                                if (myMomentObject !== undefined) {
                                                    form.change('orderTs', myMomentObject)
                                                }
                                            }}
                                        </OnChange>
                                        <Field name="formDate" validate={composeValidators(required)} component={DateField} />
                                    </div>
                                    <div className="col-12">
                                        <button id="submit" disabled={values.cartItems.length === 0} className="btn btn-primary" type="submit">Save</button>

                                    </div>
                                </div>}
                            </div>
                        </form>

                    }} />}

            </>
        }


    </>;
}

export default AdminCheckoutInStore;