import { IBike } from "../interface/bike";
import axiosInstance from "./instance";

export const RequestGetBikes = (addNotification: any, data?: any): Promise<IBike[]> => {
    return new Promise<IBike[]>((resolve, reject) => {
        let url = `/bikes/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetBike = (addNotification: any, id: string): Promise<IBike> => {
    return new Promise<IBike>((resolve, reject) => {

        axiosInstance(addNotification).get(`/bikes/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewBike = (addNotification: any, thing: IBike): Promise<IBike> => {
    return new Promise<IBike>((resolve, reject) => {

        axiosInstance(addNotification).post(`/bikes/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteBike = (addNotification: any, thing: IBike): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/bikes/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateBike = (addNotification: any, user: IBike): Promise<IBike> => {
    return new Promise<IBike>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/bikes/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestBikeFunction = (addNotification: any, func:string, id: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/bikes/${func}/${id}`;
        
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
