import { ISession } from "../interface/session";
import axiosInstance from "./instance";


export const RequestLogin =  (addNotification:any, thing:any): Promise<any> => {




    return new Promise<any>((resolve, reject) => {
        //let url = '/login';
      
        axiosInstance(addNotification).post(`/login`, thing).then(response => {
            if(response===undefined) reject("failed")
            return response.data;
        }).then(response => {
          //  console.log(response);
            
            resolve(response)
        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}


export const RequestRegister =  (addNotification:any, thing:any): Promise<any> => {




    return new Promise<any>((resolve, reject) => {
        //let url = '/login';
      
        axiosInstance(addNotification).post(`/login/register`, thing).then(response => {
            if(response===undefined) reject("failed")
            return response.data;
        }).then(response => {
          //  console.log(response);
            
            resolve(response)
        }).catch(exc => {
            //console.log(exc);
            reject(exc);
        });
    })
}
export const RequestLoginLostPassword =  (addNotification:any, thing:any): Promise<any> => {




    return new Promise<ISession>((resolve, reject) => {

      
        axiosInstance(addNotification).post(`/login/lostPassword`, thing).then(response => {
            if(response===undefined) reject("failed")
            return response.data;
        }).then(response => {
          //  console.log(response);
            resolve(response)
        }).catch(exc => {
            //console.log(exc);
            reject(exc);
        });
    })
}

export const RequestChangeLostPassword =  (addNotification:any, thing:any): Promise<any> => {
    return new Promise<ISession>((resolve, reject) => {
        axiosInstance(addNotification).post(`/login/lostPasswordUpdate`, thing).then(response => {
            if(response===undefined) reject("failed")
            return response.data;
        }).then(response => {
          //  console.log(response);
            resolve(response)
        }).catch(exc => {
            //console.log(exc);
            reject(exc);
        });
    })
}