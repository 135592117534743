import { ICheckout, ICheckoutCountry } from "../interface/checkout";
import axiosInstance from "./instance";

export const RequestGetCheckouts = (addNotification: any, data?: any): Promise<ICheckout[]> => {
    return new Promise<ICheckout[]>((resolve, reject) => {
        let url = `/checkouts/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject("failed")
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}
export const RequestGetCheckoutCountries = (addNotification: any, data?: any): Promise<ICheckoutCountry[]> => {
    return new Promise<ICheckoutCountry[]>((resolve, reject) => {
        let url = `/checkouts/_func/get-countries`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject("failed")
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetCheckout = (addNotification: any, id: string): Promise<ICheckout> => {
    return new Promise<ICheckout>((resolve, reject) => {

        axiosInstance(addNotification).get(`/checkouts/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject("failed")
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewCheckout = (addNotification: any, thing: ICheckout): Promise<ICheckout> => {
    return new Promise<ICheckout>((resolve, reject) => {

        axiosInstance(addNotification).post(`/checkouts/`, thing).then(response => {
            //   console.log(response);

            if (response === undefined) reject("failed")
            return response.data;


        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteCheckout = (addNotification: any, user: ICheckout): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/checkouts/${user.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject("failed")
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateCheckout = (addNotification: any, user: ICheckout): Promise<ICheckout> => {
    return new Promise<ICheckout>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/checkouts/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject("failed")
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

export const RequestPayPalAuth = (addNotification: any, user: ICheckout, payload: any): Promise<ICheckout> => {
    return new Promise<ICheckout>((resolve, reject) => {

        axiosInstance(addNotification).post(`/checkouts/payPalAuthorize/${user.id}`, payload).then(response => {
            //   console.log(response);
            if (response === undefined) reject("failed")
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}


export const RequestCheckoutFunction = (addNotification: any, func: string, payload: any): Promise<any> => {

    return new Promise<any[]>((resolve, reject) => {
        let url = `/checkouts/_func/${func}`;

        axiosInstance(addNotification).post(url, payload).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }





