import arrayMutators from 'final-form-arrays';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { Helmet } from 'react-helmet';
import { Link, Prompt, Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { RequestNewLog } from '../../../api/log-requests';
import { RequestGetStocks, RequestStockFunctionPost } from '../../../api/stock-requests';
import { RequestGetStore, RequestNewStore, RequestUpdateStore } from '../../../api/store-requests';
import { promiseOptionsStore } from '../../../components/promises';
import DataTable from '../../../components/system/DataTable';
import DebugButton from '../../../components/system/DebugButton';
import InputTextTemplate from '../../../components/system/FormStuff/InputTextTemplate';
import { BasicAsyncSelectAdapter } from '../../../components/system/FormStuff/ReactSelect';
import { composeValidators, maxValue, minValue, required } from '../../../components/system/FormStuff/validators';
import Loading from '../../../components/system/loading';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { ELogType, ESeverity } from '../../../interface/log';
import { IStock, IStockMove } from '../../../interface/stock';
import { IStore } from '../../../interface/store';





const AdminStore = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [startedLoading, setStartedLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IStore>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();
    const [displayMoveStore, setDisplayMoveStore] = useState<boolean>(false);


    const [action, setAction] = useState<"move" | "delete" | "update">();
    const [selected, setSelected] = useState<IStock[]>();
    const [stock, setStock] = useState<IStock[]>();
    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/stores/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        const load = async () => {
            try {
                setStartedLoading(true)
                const id = match.params.id;
                setThing(await RequestGetStore(addNotification, id))
                setStock(await RequestGetStocks(addNotification, { storeId: id }))
                setLoading(false)

            } catch (ex: any) {
                console.error(ex)

                RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Store - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
                addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
            }

        }

        if (startedLoading === false) load()




    }, [match]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IStore>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Store Created!';
                ppp = RequestNewStore(addNotification, data);
            } else {
                notificationText = 'Store Saved!';
                ppp = RequestUpdateStore(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                //  setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Store - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    const onActionSubmit = async (data: any) => {

        let resp: any;
        console.log(data)
        switch (data.mode) {
            case 'move':
                data.toStoreId = data._toStoreId.value
                resp = await RequestStockFunctionPost(addNotification, 'moveStock', data)
                break;
            case 'update':
                resp = await RequestStockFunctionPost(addNotification, 'updateStock', data)
                break;
            case 'delete':
                resp = await RequestStockFunctionPost(addNotification, 'deleteStock', data)
                break;
        }
        window.location.reload();

    }


    {/*<!-- OPTIONAL 3 -->*/ }
    // const MoveStore = (values: any[]) => {
    //     RequestGetStores(addNotification, {}).then(sores => {
    //         setDisplayMoveStore(true);
    //         setSelected(values);
    //         setStores(sores);
    //     })

    // }

    // const handelMoveSubmit = (data: any) => {
    //     console.log(data);
    //     RequestStockFunctionPost(addNotification, 'moveStore', data).then(ddd => {
    //         console.log(ddd);
    //     }).catch(ssss => {
    //         console.log("error", ssss)
    //     })
    // }
    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../stores" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Store / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <h2>{thing && thing.id}</h2>
            <DebugButton data={thing} />
            <div className='d-flex justify-content-around'>
                <div>Building: {thing && thing.building}</div>
                <div>Section: {thing && thing.area}</div>

                <div>Column: {thing && thing.column}</div>
                <div>Row: {thing && thing.row}</div>
            </div>
            {/*<!-- OPTIONAL 4 -->*/}

            {stock && <>


                {selected !== undefined ? <>

                    <Form <IStockMove>

                        onSubmit={onActionSubmit}

                        initialValues={{
                            mode: action, selected: selected.map(s => {
                                return { ...s, move: s.qty }
                            })
                        }}
                        mutators={{
                            // expect (field, value) args from the mutator
                            ...arrayMutators,
                            setValue: ([field, value], state, { changeValue }) => {
                                changeValue(state, field, () => value)
                            }
                        }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => {


                            return <form onSubmit={handleSubmit}>
                                <div className='row'>
                                    <div className='col-12'>
                                        <FieldArray name="selected">
                                            {({ fields }) => (<>
                                                <div className="row">
                                                    {fields.map((name, index) => (
                                                        <div key={index} className="col-12">
                                                            {fields.value[index].product && fields.value[index].product.partNumber} X {fields.value[index].qty}
                                                            <DebugButton data={fields.value[index]} />
                                                            {action === 'move' && <Field
                                                                name={`${name}.move`}
                                                                parse={(value, name) => {
                                                                    //  console.log("parse", value, name)
                                                                    return parseInt(value);
                                                                }}
                                                                format={(value, name) => {
                                                                    //  console.log("format", value, name)
                                                                    return parseInt(value);
                                                                }} validate={composeValidators(required, minValue(1), maxValue(fields.value[index].qty))}
                                                                id={'qty'}
                                                                // className="hiContrastFormField"
                                                                iType="number"
                                                                text="Move Quantity"

                                                                component={InputTextTemplate}
                                                            />}

                                                            {action === 'update' && <Field
                                                                name={`${name}.move`}
                                                                parse={(value, name) => {
                                                                    //  console.log("parse", value, name)
                                                                    return parseInt(value);
                                                                }}
                                                                format={(value, name) => {
                                                                    //  console.log("format", value, name)
                                                                    return parseInt(value);
                                                                }} validate={composeValidators(required, minValue(1))}
                                                                id={'qty'}
                                                                // className="hiContrastFormField"
                                                                iType="number"
                                                                text="Update Quantity"

                                                                component={InputTextTemplate}
                                                            />}
                                                            {/* // <Field component="input" type="checkbox" name={`${name}.backup`} /> */}
                                                        </div>
                                                    ))}
                                                </div>
                                            </>)}
                                        </FieldArray>
                                    </div>
                                    {action === 'move' && <div className='col-12'>
                                        Store Id
                                        <Field
                                            name="_toStoreId"
                                            component={BasicAsyncSelectAdapter}
                                            inputId={'_toStoreId'}
                                            promiseOptions={promiseOptionsStore}
                                        />

                                    </div>
                                    }


                                    {(values._toStoreId || action === 'update' || action === 'delete') && selected && selected.length > 0 ? <div className='col-12'>

                                        <button type="submit" className='btn btn-warning'>
                                            {action === 'move' && 'Move'}
                                            {action === 'update' && 'Update'}
                                            {action === 'delete' && 'Delete'}
                                        </button>

                                    </div> : <div className='col-12'>

                                    </div>}

                                </div>
                            </form>

                        }} />


                </> : <>


                    <h2>Stock In Store</h2>
                    <DataTable <IStock>
                        doWithSelected={[
                            {
                                title: 'Move', name: 'move', func: (thingx => {
                                    setSelected(thingx)
                                    setAction('move')
                                })
                            }, {
                                title: 'Update', name: 'update', func: (thingx => {
                                    setSelected(thingx)
                                    setAction('update')
                                })
                            },
                            {
                                title: 'Delete', name: 'delete', func: (thingx => {
                                    setSelected(thingx)
                                    setAction('delete')
                                })
                            }
                        ]}
                        colHeadings={[

                            { field: 'product', name: 'Product', sortable: true, r: (val) => <Link to={`/admin/products/${val.product && val.product.id}`}>{val.product && val.product.partNumber}</Link> },
                            { field: 'qty', name: 'Qty', sortable: true },
                            { field: 'quality', name: 'Quality', sortable: true },
                            { field: 'purchasePrice', name: 'Purchase Price', sortable: true },
                            {
                                field: 'id', name: 'Act', sortable: false, r: ((val => {
                                    return <div className='btn-group'>
                                        <DebugButton data={val} />
                                        {/* <Link to={`/admin/stocks/${val.id}`} className='btn btn-warning'>View Stock</Link> */}
                                    </div>
                                }))
                            }
                        ]} data={stock} />
                </>
                }

            </>
            }
            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminStore;