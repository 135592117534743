
import React, { useEffect, useState } from 'react';
import { io, Socket } from "socket.io-client";
import { useAuth } from './auth';


type SocketContextType = {
    socket: Socket | undefined;
};

export const SocketContext = React.createContext<SocketContextType | undefined>(undefined)
type Props = {
    children: React.ReactNode;
};
export const SocketProvider = ({ children }: Props) => {
    const [socket, setSocket] = useState<Socket>();
    const { auth } = useAuth()!;
    useEffect(() => {
        if (auth) {
            console.log("auth__________________________________-", auth)

            const localStorageStr = localStorage.getItem('auted');

            let token: string = 'unset';
            if (localStorageStr) {
                const parsed = JSON.parse(localStorageStr)
                console.log("ls+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++", parsed)
                token = parsed.token;
            }


            const newSocket = io(auth.metaData.SOCKET_IO, {
                auth: {
                    token: localStorageStr
                }
                , transports: ['websocket'],
            });
            newSocket.on("connect_error", (err) => {
                console.log(`connect_error due to ${err.message}`);
            });
            newSocket.on('error', (err) => {
                console.log("error", err)
            })
            newSocket.on('event', (data) => {
                // handle event
                console.log("event", data)
            });
            newSocket.on('message', (data) => {
                // handle event
                console.log("message", data)
            });
            setSocket(newSocket);
            return () => {
                newSocket.close()
            };
        }

    }, [setSocket]);


    return (
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => React.useContext(SocketContext);