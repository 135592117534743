import { faCogs, faSignOutAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, ButtonGroup, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/auth';
import { useCart } from '../context/cart';
import { ICheckoutItem } from '../interface/checkout';



const Navigation = ({ }: any) => {

  const { items, remove, moreQty, lessQty } = useCart()!;
  const { auth, logout } = useAuth()!;
  let location = useLocation();


  // console.log(location);
  return (<>

    {!location.pathname.startsWith('/admin') ? <>

      <div className="container navcontainerfix" data-testid="navigation">
        <Navbar className="navbarmenu gold" variant="dark" expand="lg" collapseOnSelect={true}>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <a href="/" className="hidden">
            <img src="/logo.png" className="resizenav hidden"></img>
          </a>
          <Navbar.Collapse id="basic-navbar-nav">

            <Nav className="mr-auto hidden">
              <Nav.Link eventKey={1} as={Link} to="/type" id="navigationA_shop"><p className="font-weight-bold">SHOP</p></Nav.Link>
              <Nav.Link eventKey={2} as={Link} to="/request" id="navigationA_request"><p className="font-weight-bold">REQUEST PARTS</p></Nav.Link>
              <Nav.Link eventKey={3} as={Link} to="/about" id="navigationA_about"><p className="font-weight-bold">ABOUT US</p></Nav.Link>
              <Nav.Link eventKey={4} as={Link} to="/contact" id="navigationA_contact"><p className="font-weight-bold">CONTACT US</p></Nav.Link>
            </Nav>

            <Nav>
              {items && items.length > 0 &&
                <>
                  <NavDropdown id="navigation_cartDropdown" title={`Cart (${items.length})`}>
                    {items.map((item: ICheckoutItem, index: number) => {
                      return <NavDropdown.Item key={index} as={ButtonGroup}>
                        <Button style={{ width: "15%" }} onClick={(ev: React.MouseEvent) => {
                          ev.preventDefault();
                          remove(item)
                        }} variant="danger"><FontAwesomeIcon icon={faTrashAlt} />
                        </Button>

                        <Button variant="primary" style={{ width: "15%" }} onClick={(ev: React.MouseEvent) => {
                          ev.preventDefault();
                          lessQty(item)
                        }}>-
                        </Button>

                        <Link style={{ width: "55%" }} to="/cart" className="btn btn-link">{item.partNumber} ({item.qty}) £{(item.price * item.qty).toFixed(2)}</Link>

                        <Button style={{ width: "15%" }} onClick={(ev: React.MouseEvent) => {
                          ev.preventDefault();
                          moreQty(item)
                        }}>+
                        </Button>
                      </NavDropdown.Item>
                    })}
                    <NavDropdown.Divider />
                    <NavDropdown.Item eventKey={`5`} as={Link} id='navigation_checkout' to="/checkout">Go to checkout { }</NavDropdown.Item>
                  </NavDropdown>
                  <Nav.Link as={Link} eventKey={6} to="/checkout" id="navigation_checkout2">Checkout</Nav.Link>
                </>}


              {auth.type === 'admin' && <>
                <Nav.Link as={Link} eventKey={6} to="/admin/home" id="navigation_admin"><FontAwesomeIcon size='2x' icon={faCogs} /></Nav.Link>
                <Nav.Link className="btn" as="button" eventKey={7} id="navigation_logout" onClick={(ev: React.MouseEvent) => { ev.preventDefault(); logout && logout(); }}><FontAwesomeIcon size='2x' icon={faSignOutAlt} /></Nav.Link>

              </>}
              {auth.type === 'guest' ? <>
                <Nav.Link as={Link} eventKey={8} to="/register" id="navigation_register">Register</Nav.Link>
                <Nav.Link as={Link} eventKey={9} to="/login" id="navigation_login">Login</Nav.Link>
              </>
                :
                <NavDropdown title="Profile" id="basic-nav-dropdown">
                  <NavDropdown.Header>{auth.user && auth.user.firstName} {auth.user && auth.user.lastName} ({auth.type})</NavDropdown.Header>
                  <NavDropdown.Item as={Link} eventKey={`10`} to="/login">Change User Type</NavDropdown.Item>
                  <NavDropdown.Item as={Link} eventKey={`11`} to="/profile">Profile</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item eventKey={`12`} onClick={ev => { ev.preventDefault(); logout && logout(); }}>Log Out</NavDropdown.Item>
                </NavDropdown>}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>

      <div className="navback gold">
      </div>
      <div className="container">
        <div className="mainmenu">
          <div className="logomenu">
            <Link to="/"><img src="/logo.png"></img></Link>
          </div>
          <div className="innermainmenu">
            <ul>
              <li><Nav.Link as={Link} to="/type">Shop</Nav.Link></li>
              <li><Nav.Link as={Link} to="/request">Request parts</Nav.Link></li>
              <li><Nav.Link as={Link} to="/about">about us</Nav.Link></li>
              <li><Nav.Link as={Link} to="/contact">contact us</Nav.Link></li>
            </ul>
          </div>


        </div>
      </div>
    </> : <>

    </>}
    <div>

    </div>


  </>);
}

export default Navigation;