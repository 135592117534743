import React, { FunctionComponent } from "react";
type SelectInput = {
    text: string,
    meta: any,
    input: any,
    iType?: string,
    children: React.ReactNode;
}
const InputSelectTemplate: FunctionComponent<SelectInput> = ({ input, meta, text, iType, children }) => {
    const t: string = iType === undefined ? 'text' : iType;
    return <div className="row pb-2 pt-2">
        <div className="col-2">
            <label>{text}</label>
        </div>
        <div className="col-8">
            <select className="form-control" {...input}>{children}</select>
        </div>
        <div className="col-2">
            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
        </div>

    </div>
}
export default InputSelectTemplate;