import { IProductCategory } from "../interface/product-category";
import axiosInstance from "./instance";

export const RequestGetProductCategorys = (addNotification: any, data?: any): Promise<IProductCategory[]> => {
    return new Promise<IProductCategory[]>((resolve, reject) => {
        let url = `/product-categorys/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetProductCategory = (addNotification: any, id: string): Promise<IProductCategory> => {
    return new Promise<IProductCategory>((resolve, reject) => {

        axiosInstance(addNotification).get(`/product-categorys/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewProductCategory = (addNotification: any, thing: IProductCategory): Promise<IProductCategory> => {
    return new Promise<IProductCategory>((resolve, reject) => {

        axiosInstance(addNotification).post(`/product-categorys/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteProductCategory = (addNotification: any, thing: IProductCategory): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/product-categorys/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateProductCategory = (addNotification: any, user: IProductCategory): Promise<IProductCategory> => {
    return new Promise<IProductCategory>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/product-categorys/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestProductCategoryFunction = (addNotification: any, func: string, id: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/product-categorys/${func}/${id}`;

        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
