import React from 'react';
interface IDDate {
    value: number;
}
const MoneyDisplay: React.FunctionComponent<IDDate> = props => {

    return <>
        {props.value !== undefined && <>

            {props.value.toFixed(2)}

        </>}


    </>
};


export default MoneyDisplay