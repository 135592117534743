import { IImage } from "./image";
import { ILog } from "./log";
import { IProduct } from "./product";
import { IUser } from "./user";
import { IAll } from "./_modelAll";

export interface ICheckout extends IAll {
    _mode?: string;
    _qty?: number;
    invoiceNumber?: number;
    _partNumber?: any;
    _productCategoryId?: any;
    _productManufacturerId?: any;
    _description?: string;
    __description?: string;
    _price?: number;
    formDate?: any;
    knownCustomer?: string;
    id: string;
    xxxxxx?: number;
    findCategoryId?: string;
    findProducts?: IProduct[];
    skuFind?: string;
    name: string;
    firstName: string;
    lastName: string;
    email: string;
    landline: string;
    mobile: string;
    company: string;
    orderTs?: Date,
    payedTs?: Date,
    postedTs?: Date,
    barcodeFind?: string,
    completeTs?: Date,
    userId: string;
    user?: IUser;
    type: 'web' | 'legacy' | 'shop' | 'phone'
    requestId: string;
    request?: ILog;
    customersFound?: IUser[];
    addressStreet?: string;
    addressCity?: string;
    addressTown?: string;
    addressCountry?: string;
    addressCounty?: string;
    addressPostCode?: string;
    totalBuyinValueOfItems: number,
    totalProfitOfItems: number,



    addressBillingStreet: string;
    addressBillingCity: string;
    addressBillingTown: string;
    addressBillingCountry: string;
    addressBillingCounty: string;
    addressBillingPostCode: string;

    addressDeliveryTelephone: string;

    addressDeliveryStreet: string;
    addressDeliveryCity: string;
    addressDeliveryTown: string;
    addressDeliveryCountry: string;
    addressDeliveryCounty: string;
    addressDeliveryPostCode: string;

    deliveryInstructions: string;

    cartItems: ICheckoutItem[];
    totalWeight: number;
    subTotal: number;
    shippingTotal: number;
    totalTotal: number;


    status: ECheckoutState;
    // gateway stuff

    gatewayType: string;
    gatewayData: any;
    gatewayLog: any[];
    gatewayOrderId?: string;
    gatewayTransactionId?: string;
    gatewayMeta?: any;

    addressGatewayBillingStreet?: string;
    addressGatewayBillingCity?: string;
    addressGatewayBillingTown?: string;
    addressGatewayBillingCountry?: string;
    addressGatewayBillingPostCode?: string;

    addressGatewayDeliveryTelephone?: string;

    addressGatewayDeliveryStreet?: string;
    addressGatewayDeliveryCity?: string;
    addressGatewayDeliveryTown?: string;
    addressGatewayDeliveryCountry?: string;
    addressGatewayDeliveryPostCode?: string;


    gatewayFee: number;
    totalIncome: number;

}


export enum ECheckoutState {
    "Started" = "started",
    "Payment Pending" = "payment-pending",
    "Payment Complete" = "payment-complete",
    "Payment Error" = "payment-error",
    "Packing" = "packing",
    "Awaiting Shipping" = "shipping-await",
    "In-Progress Shipping" = "shipping-inprogress",
    "Shipping Complete" = "shipping-complete",
    "Complete" = 'complete'
}


export interface ICheckoutItem {

    partNumber?: string;
    productDescription: string;
    productId: string;
    product?: IProduct;
    qty: number;
    price: number;
    weight: number;
    itemTotal: number;
    weightTotal: number;
    type?: string;
    image?: IImage;
    stock?: number;
    errord?: string[];
    id?: string;
    stockLocations?: IStockLocation[];
}


export interface IStockLocation {
    productId: string;
    storeId: string;
    qty: number;
    confirmed: boolean;
}

export interface ICheckoutCountry {
    code: string;
    name: string;
}