import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { IProduct } from "../../interface/product";



const AdminOrders = ({ }: any) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [products, setProduct] = useState<IProduct[]>();
    let match = useRouteMatch();
    //let params = useParams();

    //const { addNotification } = useNotifications()!;
    useEffect(() => {
        console.log("get product types");
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });

        if (loading === true) {

            fetch("/api/products")
                .then((response) => response.json())
                .then((json: IProduct[]) => {
                    console.log(json);
                    setProduct(json);
                    setLoading(false);
                })
        }
    }, [])

    return (<>
        {products && <div className="col-12">


            {/* <Products products={products} /> */}
        </div>}

    </>
    );
}

export default AdminOrders;