import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { RequestNewLog } from "../../../api/log-requests";
import { RequestDeleteProductCategory, RequestGetProductCategorys } from '../../../api/product-category-requests';
import DataTable from "../../../components/system/DataTable";
import DebugButton from "../../../components/system/DebugButton";
import Loading from "../../../components/system/loading";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { ELogType, ESeverity } from "../../../interface/log";
import { IProductCategory } from "../../../interface/product-category";
import AdminProductCategory from './Admin.ProductCategory';


const AdminProductCategorysPge = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [things, setThings] = useState<IProductCategory[]>();
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;


    useEffect(() => {
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react stores onunload'
        });
    }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }

    interface IDStoreRecursive {
        category: IProductCategory,
    }
    const DisplayChildren = ({ category }: IDStoreRecursive) => {

        return <div className="col">
            <h2>{category.name}</h2>
            <div className="button-group">
                <DebugButton data={category} />
                <Link className="btn btn-secondary" to={`${match.url}/${category.id}`}>Edit</Link>
                <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    onDelete(category);
                }}>Delete</button>
            </div>
            {/* <div className="lp-10">
                {category.children && category.children.map((storeed, index) => {
                    return <DisplayChildren key={storeed.id} category={storeed} />
                })}
            </div> */}

        </div>;
    }

    {/*<!-- OPTIONAL END -->*/ }



    useEffect(() => {
        try {
            if (loading === true) {
                // eslint-disable-next-line
                {/*<!-- OPTIONAL 1 -->*/ }
                RequestGetProductCategorys(addNotification).then(thing => {
                    //   console.log(stores)
                    setThings(thing);
                    setLoading(false);
                })

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - ProductCategorys - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }, [loading, addNotification]);

    const onDelete = (value: IProductCategory) => {
        try {

            const conf = window.confirm("Delete?");
            if (conf) {
                setLoading(true);
                RequestDeleteProductCategory(addNotification, value).then(res => {
                    //  console.log(res);
                    let thingsD: IProductCategory[] = [];
                    if (things) {
                        thingsD = [...things];
                    }
                    const index = thingsD.findIndex(thing => thing._id === value._id);
                    thingsD.splice(index, 1);

                    setThings(thingsD);
                    setLoading(false);
                    addNotification && addNotification('Success', 'ProductCategory Deleted!', ENotificationType.Primary);
                });
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - ProductCategorys - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }



    return (<>
        {loading === true && <Loading />}
        {things && <div className="col-12">
            <h2>ProductCategorys Admin</h2>
            <Helmet>
                <title>ProductCategorys / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            <Link to="./product-categorys/new">New</Link>
            {/*<!-- OPTIONAL 2 -->*/}
            <div className="row">

                <DataTable

                    exportFileName="product-categories"
                    colHeadings={[
                        { name: "Name", field: "name", sortable: false },
                        { name: "No Products", field: "productsCount", sortable: false },
                        { name: "Total Stock", field: "stock", sortable: false },
                        {
                            isButtonField: true, name: "Actions", field: "id", r: (value: any, index) => {
                                return <div className="btn-group">
                                    <DebugButton data={value} />
                                    <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link>
                                    <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        onDelete(value);
                                    }}>Delete</button>
                                </div>;
                            }, sortable: false
                        },

                    ]}

                    data={things}
                />


            </div>
            {/*<!-- OPTIONAL END -->*/}
        </div>}
    </>
    );
};
const AdminProductCategorys = () => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${match.path}/:id`}>
                <AdminProductCategory />
            </Route>
            <Route path={`${match.path}`} exact>
                <AdminProductCategorysPge />
            </Route>

        </Switch>
    </>
    );
}

export default AdminProductCategorys;