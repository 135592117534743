import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { Helmet } from 'react-helmet';
import { Prompt, Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { downloadFile } from '../../../api/file-download-request';
import { RequestNewLog } from '../../../api/log-requests';
import { RequestGetManual, RequestNewManual, RequestUpdateManual } from '../../../api/manual-requests';
import DebugButton from '../../../components/system/DebugButton';
import { composeValidators, required } from '../../../components/system/FormStuff/validators';
import ImageDisplay from '../../../components/system/ImageDisplay';
import Loading from '../../../components/system/loading';
import TextDisplay from '../../../components/system/TextDisplay';
import UploadFiles from '../../../components/system/UploadFiles';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { IImage } from '../../../interface/image';
import { ELogType, ESeverity } from '../../../interface/log';
import { IManual } from '../../../interface/manual';



const AdminManual = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IManual>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;
    const [tagging, setTagging] = useState<number>(-1);
    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/manuals/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }

                if (id === 'new') {
                    setThing({ id: 'new', name: '', description: '', pagesStr: [], manufacturerId: '', year: '', status: 'new', filename: '', fileId: '', diagramImagesIds: [], diagramTextIds: [] })
                    Promise.allSettled([]).then(() => {
                        setLoading(false)
                    });
                } else {
                    const prodCall = () => {
                        return RequestGetManual(addNotification, id).then(thing => {
                            setThing(thing);
                        })
                    }

                    Promise.allSettled([prodCall()]).then(() => {
                        setLoading(false)
                    });
                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Manual - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IManual>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Manual Created!';
                ppp = RequestNewManual(addNotification, data);
            } else {
                notificationText = 'Manual Saved!';
                ppp = RequestUpdateManual(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Manual - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 3 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../manuals" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Manual / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 4 -->*/}

            <Form <IManual>
                onSubmit={onSubmit}
                initialValues={thing}
                mutators={{
                    setValue: ([field, value], state, { changeValue }) => {
                        console.log(state, field, value)
                        changeValue(state, field, () => value)
                    }
                }}
                render={({ handleSubmit, form, submitting, pristine, values }) => {
                    //do if altered browse notifcation
                    //  console.log(submitting, pristine)
                    setFormChanged(!pristine)
                    return <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <DebugButton data={values} />
                                <Field name="name" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Name</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>

                                <div className="col-6">
                                    <label>File</label><br />
                                    {values.file && <>{values.file.fileName}
                                        <button className="btn btn-primary" type="button" onClick={() => {
                                            values.file && downloadFile(addNotification, values.file);
                                        }}><FontAwesomeIcon icon={faFileDownload} /></button>

                                    </>}
                                    <Field name="file" validate={composeValidators(required)} component={UploadFiles} onChanged={(event: any) => {
                                        //   fields.push(event)
                                        form.change('fileId', event.id)
                                        console.log(event)
                                    }}>


                                    </Field>
                                </div>
                                {/* 

                                <Field name="id">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>ID</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>


                                <Field name="clientId">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Client</label>
                                            <select className="form-control" {...input} disabled={values.id !== 'new'}>
                                                <option></option>
                                                {clients && clients.map((client, index) => {
                                                    return <option key={index} value={client.id}>{client.name}</option>
                                                })}
                                            </select>
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>*/}
                                <Field name="description">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Description</label>
                                            <textarea type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="year">
                                    {({ input, meta }) => (
                                        <div>
                                            <label>Year</label>
                                            <input type="text" className="form-control" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                <button className="btn btn-primary" type="submit">Save</button>
                            </div>

                        </div>

                    </form>

                }} />


            {thing.pages && <div className="col-12">
                {thing.pages.sort((a, b) => {
                    return a.page - b.page;
                }).map((page: IImage, index: number) => {
                    return <div className="row pb-5" key={index}>

                        {index === tagging ? <div className="col-8">


                        </div> : <div className="col-8">

                            <ImageDisplay image={page} />
                            <DebugButton data={page} />
                            <button type="button" className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                                ev.preventDefault();
                                setTagging(index);
                            }}>Tag This</button>
                        </div>}
                        <div className="col-4">
                            <p><b>{page.page}</b></p>
                            <TextDisplay text={page.description} />
                        </div>
                    </div>
                })}
            </div>}
            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminManual;