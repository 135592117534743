
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router';
import { RequestGetContact } from '../../api/contact-requests';
import ConversationDisplay from '../../components/system/ContactsStuff/ConversationDisplay';
import { useAuth } from '../../context/auth';
import { useNotifications } from '../../context/notifications';
import { IContact } from '../../interface/contact';

const Conversation = () => {
    const { auth } = useAuth()!;
    const [ loading, setLoading] = useState<boolean>(true);
    const { addNotification } = useNotifications()!;
    const [thing, setThing] = useState<IContact>()
    const match: any = useRouteMatch("/conv/:type/:id");

    useEffect(() => {
        console.log("match", match);
        const id = match.params.id;
        if (thing === undefined) {
            RequestGetContact(addNotification, id).then(dd => {
                console.log(dd)
                setThing(dd)
                setLoading(false)
            });
        }
    }, [match])
    return (
        <>
            <Helmet>
                <title>Conversation / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <br></br>
            
            {thing && <ConversationDisplay contact={thing} setContact={setThing} />}

        </>
    );
}

export default Conversation;