import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import { Link, Redirect, useLocation } from "react-router-dom";
import { RequestChangeLostPassword, RequestLogin, RequestLoginLostPassword } from "../api/auth-requests";
import { composeValidators, required } from "../components/system/FormStuff/validators";
import Loading from "../components/system/loading";
import { useAuth } from "../context/auth";
import { ENotificationType, useNotifications } from "../context/notifications";

const AUTH_KEY: string = process.env.REACT_APP_AUTH_KEY ? process.env.REACT_APP_AUTH_KEY : 'auted'
const Login = () => {
    const { auth, setAuth } = useAuth()!;
    const { addNotification } = useNotifications()!
    const { executeRecaptcha } = useGoogleReCaptcha();


    let location = useLocation();
    const [data] = useState<any>(() => {
        const defaultLoginPayload = { user: '', password: '', lostPasswordMode: false }
        const stored = localStorage.getItem('savedUsername')
        if (stored) {
            defaultLoginPayload.user = stored
        }

        console.log(stored)
        return defaultLoginPayload;
    });
    const [showResponse, setShowResponse] = useState<boolean>(false);
    const [response, setResponse] = useState<string>();
    const [responseClass, setResponseClass] = useState<string>('bg-danger');
    const [done, setDone] = useState<boolean>(false);
    const [recover, setRecover] = useState<string>('');

    const [redirect, setRedirect] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    useEffect(() => {

        const parsed = queryString.parse(location.search);
        //   console.log(parsed)
        if (parsed.recover) {
            setRecover(parsed.recover.toString())
        } else {
            setRecover('');
        }
    }, [location.search]);

    const hideReponse = () => {
        setTimeout(() => {
            setShowResponse(false);
            setResponseClass('')

            setResponse('');
        }, 6000)
    }


    const login = (data: any) => {
        //setLoading(true)
        let recaptureType = 'login';
        if (data.lostPasswordMode === true) {
            recaptureType = 'lostpassword';
        }

        //  console.log(data)
        executeRecaptcha && executeRecaptcha(recaptureType).then(token => {
            // data.id = 'new';
            data.recapture = token;



            if (data.lostPasswordMode === false) {
                //normal login
                const login: any = {
                    user: data.user,
                    password: data.password,
                    recapture: token
                }
                RequestLogin(addNotification, login).then(response => {
                    console.log(response);
                    if (response.user) {
                        //  addNotification && addNotification('Login', 'You have successfully logged in!', ENotificationType.Success)
                        setAuth && setAuth(response);
                        localStorage.setItem(AUTH_KEY, JSON.stringify(response));
                        setRedirect(true);
                        if (data.saveUsername === true) {
                            localStorage.setItem('savedUsername', data.user);
                        }
                        hideReponse();
                    } else {
                        setShowResponse(true);
                        setResponseClass('bg-danger');

                        setResponse('Something wasn\'t right there please try again!');
                        hideReponse();
                        //  addNotification && addNotification('Login', 'Something wasn\'t right there please try again!', ENotificationType.Warning)
                    }
                    setLoading(false)


                }).catch(loginExecption => {
                    console.log(loginExecption)
                    addNotification && addNotification('Error', 'Api Error', ENotificationType.Danger)
                    setLoading(false)
                });

            } else {
                const login: any = {
                    user: data.user,
                    recapture: token
                }
                RequestLoginLostPassword(addNotification, login).then(response => {
                    console.log(response);
                    if (response) {
                        setShowResponse(true);
                        setResponseClass('bg-success')
                        setResponse('please check your email and spam folder for recovery instructions!');
                        hideReponse();
                    } else {
                        setShowResponse(true);
                        setResponseClass('bg-danger')
                        setResponse('Something wasn\'t right there please try again!');
                        hideReponse();
                    }
                    setLoading(false)
                    setDone(true);
                }).catch(loginExecption => {
                    console.log(loginExecption)
                    addNotification && addNotification('Error', 'Api Error', ENotificationType.Danger)
                    setLoading(false)
                });
            }
        });

    }

    const recoverSetNewPassword = (data: any) => {

        executeRecaptcha && executeRecaptcha("lostpasswordreset").then(token => {

            data.recapture = token;
            RequestChangeLostPassword(addNotification, data).then(dd => {
                if (dd.lostPasswordUpdate === true) {
                    addNotification && addNotification('Password Reset', 'Password Changed!', ENotificationType.Success)
                    setDone(true);
                }
            })

        });
    }
    return (
        <div className="loginbody mainpage">
            <Helmet>
                <title>Login / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {redirect === true && <Redirect to="/" />}
            {loading === true && <Loading />}

            {recover === '' ? <>

                {auth.type === 'guest' ? <>
                    <Form
                        onSubmit={login}
                        initialValues={data}
                        render={({ handleSubmit, form, submitting, pristine, values }) => {

                            return <form onSubmit={handleSubmit}>
                                <div className="login-div" data-testid='loginForm'>
                                    <div className="card cardrequest">
                                        <div className="card-body">
                                            <div className="title">Sheffield British Motorcycles</div>
                                            <div className="sub-title">login</div>

                                            {done === true ?

                                                <div className="col-12">
                                                    <p>Please wait for an email to arrive in your inbox. This will contain instructions on how to update your password</p>
                                                </div>
                                                :
                                                <div className="col-12">
                                                    <div className="fields">
                                                        <div className="p-4">
                                                            <Field name="user" validate={composeValidators(required)}>
                                                                {({ input, meta }) => (
                                                                    <div className="loginfix2">
                                                                        <FontAwesomeIcon icon={faEnvelope} />
                                                                        <input type="text" data-testid='loginForm_user' className={`user-input fixlogin ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="Username" />
                                                                    </div>
                                                                )}
                                                            </Field>
                                                        </div>
                                                        {values.lostPasswordMode === false && <>
                                                            <div className="p-4">
                                                                <Field name="password" validate={composeValidators(required)}>
                                                                    {({ input, meta }) => (
                                                                        <div className="loginfix2">
                                                                            <FontAwesomeIcon icon={faLock} />
                                                                            <input type="password" data-testid='loginForm_pass' className={`pass-input fixlogin ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="Password" />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <div className="p-4"><label className="col-6">
                                                                <Field
                                                                    name="saveUsername"
                                                                    component="input"
                                                                    type="checkbox"

                                                                />{' save Details'}

                                                            </label></div>
                                                        </>
                                                        }
                                                    </div>
                                                    {showResponse === true &&
                                                        <div className={responseClass}>
                                                            {response}
                                                        </div>}
                                                    <button type="submit" data-testid='loginForm_submit' className="signin-button">
                                                        {values.lostPasswordMode === false ? "LOG IN" : "Recover Password"}
                                                    </button>
                                                    <div className="link" onClick={(ev: React.MouseEvent) => {
                                                        ev.preventDefault();
                                                        form.change('lostPasswordMode', !values.lostPasswordMode)
                                                    }}>
                                                        {values.lostPasswordMode === false ? "Forgot Password?" : "Return to login"}
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>



                            </form>
                        }} /></> : <>You are already logged in</>}
            </> : <>
                <Helmet>
                    <title>Set Password / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
                </Helmet>

                <h1>Set New Password</h1>

                {auth.type === 'guest' ? <><Form
                    onSubmit={recoverSetNewPassword}
                    initialValues={{ ...data, recoverId: recover }}
                    validate={values => {
                        const errors: any = {};
                        console.log(errors);
                        if (values.newPassword !== values.newPassword2) {
                            errors.newPassword = 'Passwords do not match!'
                        }
                        return errors;
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {

                        return <form onSubmit={handleSubmit}>
                            {done === true ? <div className="col-12">please login with your reset password <Link to="/login">Login</Link></div> : <div className="col-12">

                                <div className="col-12">
                                    <Field name="newPassword" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>New Password</label>
                                                <input type="password" className="form-control" {...input} placeholder="" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="newPassword2" validate={composeValidators(required)}>
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Password Confirm</label>
                                                <input type="password" className="form-control" {...input} placeholder="" />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    {showResponse === true && <div className={responseClass}>{response}</div>}

                                    <div className="btn-group">

                                        <button type="submit" className="btn btn-success">Update Password</button>
                                    </div>

                                </div>
                            </div>}
                        </form>
                    }} /></> : <>You're already logged in</>}
            </>}
        </div>
    );
}

export default Login;