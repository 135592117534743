import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { RequestNewContactResponse } from '../../../api/contact-requests';
import { RequestNewLog } from '../../../api/log-requests';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { IContact, IConversation } from '../../../interface/contact';
import { IImage } from '../../../interface/image';
import { ELogType, ESeverity } from '../../../interface/log';
import { IProduct } from '../../../interface/product';
import AddToCartButton from '../../AddToCartButton';
import DataTable from '../../system/DataTable';
import Loading from '../../system/loading';
import TextDisplay from '../../system/TextDisplay';
import UploadImages from '../../system/UploadImages/UploadImages';
import DateDisplay from '../DateDisplay';
import DebugButton from '../DebugButton';
import { composeValidators, required } from '../FormStuff/validators';


interface IConversationDisplay {
    printMode?: boolean;
    contact: IContact;
    products?: IProduct[];
    setProduct?: (index: number, product: IProduct) => void
    setContact: (c: IContact) => void;
}
const ConversationDisplay: React.FunctionComponent<IConversationDisplay> = props => {
    const [loading, setLoading] = useState<boolean>();
    const { addNotification } = useNotifications()!;
    const [conv, setConv] = useState<IConversation>({ requestId: "", message: "", imageId: '', productId: '' });
    const { auth } = useAuth()!;
    const onMessage = (data: any) => {
        try {
            setLoading(true)
            //setConv({ requestId: "", message: "", imageId: '' });
            RequestNewContactResponse(addNotification, props.contact, data).then(ccc => {
                setLoading(false)
                props.setContact(ccc)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Contact - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }

    return <>
        {loading === true && <Loading />}
        <div className="container">


            <div className="col-12 p-2"></div>
            <div className="col-12 p-4">
                <div className={`card  bg-white`}>
                    <div className="card-body">
                        <div className="d-flex justify-content-between">

                            <p>Status: {props.contact.status}</p>
                            <p>Type: {props.contact.type}</p>
                            <p><DateDisplay date={props.contact.created} ago={true} /></p>
                        </div>
                        <p className="font-weight-bold pull-left">From: {props.contact.name}</p>
                        <div className="d-flex justify-content-between">
                            <p>Phone: {props.contact.phone}</p>
                            <p>Email: <a href={`mailto:${props.contact.email}`}>{props.contact.email}</a></p>
                        </div>
                        <div className="d-flex justify-content-between">
                            <p>Address: </p><p>{props.contact.addressStreet}, {props.contact.addressTown}{props.contact.addressCity}, {props.contact.addressPostCode}</p>

                        </div>
                        {props.contact.type === 'request' && <div className="d-flex justify-content-between">
                            <p>Manufacturer: {props.contact.manufacturerId !== 'other' ? props.contact.manufacturerId : props.contact.otherManufacturer}</p>
                            <p>Bike: {props.contact.bikeId !== 'other' ? props.contact.bikeId : props.contact.otherBike}</p>
                            <p>Color: {props.contact.color}</p>
                            <p>Year: {props.contact.year}</p>
                        </div>}
                        <div className="text-left">Message : <TextDisplay text={props.contact.message} />
                        </div>
                        <div className="col-12 p-1">
                            {props.contact.parts && <DataTable
                                colHeadings={[
                                    { name: "Part No", field: "partNo", sortable: false },
                                    { name: "Description", field: "description", sortable: false },
                                    {
                                        name: "Images", field: "id", r: (value: any, index) => {
                                            return <div className="row">
                                                {value.images.map((image: IImage, iindex: number) => {
                                                    // console.log(values.parts[index]);

                                                    if (image) {
                                                        let src = image.url;
                                                        //    console.log(image);
                                                        if (image.croppedSrcData) {
                                                            src = image.croppedSrcData;
                                                        }

                                                        return <div key={iindex} className="col-6"><img className="img-fluid w-90" src={src} /></div>
                                                    }
                                                })}

                                            </div>;
                                        }, sortable: false
                                    },
                                    { name: "Qty", field: "quantity", sortable: false },
                                    {
                                        name: "Actions", field: "id", r: (value: any, index) => {
                                            const onChange = (selected: any) => {
                                                props.setProduct && props.setProduct(index, selected)
                                            };

                                            return <div className="btn-group">

                                                {props.printMode !== true ? <> <DebugButton data={value} />
                                                    {value.product ? <>{!props.products && <AddToCartButton data={value.product} showLink={true} />}</> : <>
                                                        {props.products && <>

                                                            redo this bit
                                                        </>}
                                                    </>}
                                                </> : <></>}


                                            </div>;
                                        }, sortable: false
                                    },

                                ]}
                                data={props.contact.parts}
                            />}

                        </div>


                    </div>
                </div>
            </div>
            {props.contact.conversation.length > 0 && <><div className="col-12 p-4">
                <div className="card bg-light text-black">
                    <div className="card-body">
                        {props.contact.conversation.map((value, index) => {
                            const isStaff: boolean = value.author ? true : false;

                            return <div className={`col-12 `} key={index}>
                                <div className="d-flex justify-content-between">
                                    <p>{value.author ? `${value.author.username} [${value.author.type}]` : `${props.contact.name} [guest]`}</p>
                                    <p><DateDisplay date={value.created} ago={true} /></p>
                                </div>
                                <div className="text-left"><TextDisplay text={value.message} /></div>
                                {value.image && <>
                                    {value.image.croppedSrcData ? <img className='img-fluid' src={value.image.croppedSrcData} /> : <img className='img-fluid' src={value.image.url} />}
                                </>}
                                <div className="col-12 p-3"></div><hr />
                            </div>
                        })}
                    </div>
                </div>
            </div><hr /></>}
            {props.printMode !== true && <><div className="col-12 p-2"></div>
                <div className="col-12 p-2">
                    <Form <IConversation>
                        onSubmit={onMessage}
                        initialValues={conv}
                        mutators={{
                            setValue: ([field, value], state, { changeValue }) => {
                                console.log(state, field, value)
                                changeValue(state, field, () => value)
                            }
                        }}
                        render={({ handleSubmit, form, submitting, pristine, values }) => {
                            //do if altered browse notifcation
                            console.log(submitting, pristine)

                            return <form onSubmit={handleSubmit}>
                                <DebugButton data={values} />
                                <h4>SEND RESPONSE</h4>

                                <Field name="message" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div>
                                            <label className="pull-left">Message</label>
                                            <textarea type="text" className="form-control d-4" {...input} placeholder="...." />
                                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                        </div>
                                    )}
                                </Field>
                                {values.image && <>
                                    {values.image.croppedSrcData ? <img src={values.image.croppedSrcData} /> : <img src={values.image.url} />}
                                </>}

                                <Field name="image" aspect="none" component={UploadImages} text="Add Image" onChanged={(data: any) => {
                                    console.log("changed", data)
                                    form.change('imageId', data.id)
                                }} />
                                <div className="col-12 p-2">
                                    <button className="btn btn-primary" type="submit">Send</button>
                                </div>




                            </form>


                        }} />
                </div></>}
        </div>
    </>;
};
export default ConversationDisplay;