import moment from "moment";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import DateDisplay from "../DateDisplay";


const DateField = ({ name, input, meta, input: { value, onChange }, label }: any) => {

  const [startDate, setStartDate] = useState(() => {
    const dm = moment(value, "DD-MM-YYYY")
    if (dm.isValid()) {
      return dm.toDate();
    } else {
      return null;
    }

  });
  console.log("_____________________", value, typeof value, "000000000000", startDate)

  const years = [1990, 1991, 1992, 1993]
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  return (<><label>{(label ? label : 'Date')}</label>
    <DatePicker
      dateFormat="dd/MM/yyyy"
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        increaseYear,
        decreaseYear,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <button type="button" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault()
            decreaseYear()
          }} disabled={prevMonthButtonDisabled}>
            {"<<"}
          </button>

          <button type="button" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault()
            decreaseMonth()
          }} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>

          <DateDisplay date={date} />


          <button type="button" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault()
            increaseMonth()
          }} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
          <button type="button" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault()
            increaseYear()
          }} disabled={nextMonthButtonDisabled}>
            {">>"}
          </button>
        </div>
      )}
      wrapperClassName=" w-100"
      className="form-control"
      selected={startDate} // needs to be checked if it is valid date
      disabledKeyboardNavigation
      name={name}

      onChange={(date: any) => {
        console.log(date, typeof date)
        setStartDate(date)

        const dm = moment(date, "DD-MM-YYYY")
        console.log(dm.format('DD-MM-YY'));
        onChange(dm.format('DD-MM-YY'));
        // On Change, you should use final-form Field Input prop to change the value
        // if (isValid(date)) {
        //   onChange(format(new Date(date), "dd-MM-yyyy"));
        // } else {
        //   onChange(null);
        // }
      }}
    />
    {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
  </>);
};


export default DateField;