import React from 'react';
import { Toast } from "react-bootstrap";

import { useNotifications } from "../../context/notifications";


const Notifications = () => {

  const { notifications, remove } = useNotifications()!;
  return (<>
    {notifications.map((note, index) => {
      // console.log(note)
      //const class:string = ENotificationType[note.type];
      return <Toast className={note.type} key={index} onClose={() => remove && remove(note.id)} show={true} delay={30000} autohide>
        <Toast.Header>
          <strong className="mr-auto">{note.title}</strong>
        </Toast.Header>
        <Toast.Body>{note.payload}</Toast.Body>
      </Toast>
    })}
  </>);
}

export default Notifications;