import React from 'react';
import { faBug } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Error = () => {
    
    return <div className="jumbotron col-12">
        <FontAwesomeIcon icon={faBug} size="5x" />
        <h3>Ooops something went wrong there.</h3>
    </div>;
};

export default Error;