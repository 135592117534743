import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useRef } from 'react';
import { useBarcode } from 'react-barcodes';
import { useReactToPrint } from 'react-to-print';
import { useAuth } from '../context/auth';
import { IProduct } from '../interface/product';
import { IStock } from '../interface/stock';


// <div className="hide">
interface IDText {
    stock: IStock;
    product: IProduct;
    thing: any;
}
const StockLabelDisplay: React.FunctionComponent<IDText> = props => {
    const holder = useRef<HTMLDivElement>(null);
    const { auth } = useAuth()!;
    const handlePrint = useReactToPrint({
        content: () => holder.current,
    });
    const { inputRef } = useBarcode({
        //value: `S${props.stock.barcode.toString().padStart(6, "0")}`,
        value: `AAA`,
        options: {
            format: 'CODE128',
            background: '#ffffff',
        }
    });

    return <div className="">
        <div className="hide">
            <div ref={holder} className="text-center">
                <p>Sheffield British Motorcycles - ADDRESS - {auth.metaData.SUPPORT_CONTACT_PHONE}</p>
                <h1 className="printH1">[{props.product.productCategory && props.product.productCategory.name}] #{props.product.partNumber}</h1>
                <svg className="img-fluid w-50" ref={inputRef} />;
                <h1 className="printH1">{props.product.partNumber}</h1>






            </div>
        </div>

        {handlePrint && <button type="button" className="btn btn-info" onClick={(ev: React.MouseEvent) => {
            ev.preventDefault();
            handlePrint();
        }}>
            Click Here to Print Label <FontAwesomeIcon icon={faPrint} />
        </button>}

    </div>
};


export default StockLabelDisplay