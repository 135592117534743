import { ISupplier } from "../interface/supplier";
import axiosInstance from "./instance";

export const RequestGetSuppliers = (addNotification: any, data?: any): Promise<ISupplier[]> => {
    return new Promise<ISupplier[]>((resolve, reject) => {
        let url = `/suppliers/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetSupplier = (addNotification: any, id: string): Promise<ISupplier> => {
    return new Promise<ISupplier>((resolve, reject) => {

        axiosInstance(addNotification).get(`/suppliers/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewSupplier = (addNotification: any, thing: ISupplier): Promise<ISupplier> => {
    return new Promise<ISupplier>((resolve, reject) => {

        axiosInstance(addNotification).post(`/suppliers/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteSupplier = (addNotification: any, thing: ISupplier): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/suppliers/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateSupplier = (addNotification: any, user: ISupplier): Promise<ISupplier> => {
    return new Promise<ISupplier>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/suppliers/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestSupplierFunction = (addNotification: any, func:string, id: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/suppliers/${func}/${id}`;
        
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
