import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormApi } from 'final-form';
import arrayMutators from 'final-form-arrays';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import { useLocation } from 'react-router-dom';
import { RequestBuyinFunction, RequestNewBuyin } from '../../api/buyin-requests';
import { RequestGetManufacturers } from '../../api/manufacturer-requests';
import { RequestGetProductCategorys } from '../../api/product-category-requests';
import { RequestGetProductManufacturers } from '../../api/product-manufacturer-requests';
import { RequestGetProduct } from '../../api/product-requests';
import { RequestGetStocks } from '../../api/stock-requests';
import { RequestGetStore } from '../../api/store-requests';
import { promiseOptionsManufacturer, promiseOptionsProduct, promiseOptionsProductCategory, promiseOptionsProductManufacturer, promiseOptionsStore } from '../../components/promises';
import DataTable from '../../components/system/DataTable';
import DebugButton from '../../components/system/DebugButton';
import InputCheckboxTemplate from '../../components/system/FormStuff/InputCheckboxTemplate';
import InputSelectTemplate from '../../components/system/FormStuff/InputSelectTemplate';
import InputTextTemplate from '../../components/system/FormStuff/InputTextTemplate';
import { AsyncSelectCreateAdapter, IReactSelectValue, XAsyncSelectAdapter } from '../../components/system/FormStuff/ReactSelect';
import { composeValidators, minValue, required } from '../../components/system/FormStuff/validators';
import ImageDisplay from '../../components/system/ImageDisplay';
import Loading from '../../components/system/loading';
import UploadImages from '../../components/system/UploadImages/UploadImages';
import { ENotificationType, useNotifications } from '../../context/notifications';
import { IImage } from '../../interface/image';
import { IManufacturer } from '../../interface/manufacturer';
import { IProduct } from '../../interface/product';
import { IProductCategory, IProductCategoryDetail } from '../../interface/product-category';
import { IProductManufacturer } from '../../interface/product-manufacturer';
import { IStock } from '../../interface/stock';
import { IStore } from '../../interface/store';






interface IBuyinForm {

    productCategoryDetails?: IProductCategoryDetail[]
    data: any;
    isNewA: boolean;
    lastPurchasePrice: number;
    storeId: IReactSelectValue | undefined;
    productId: IReactSelectValue | undefined;
    productCategoryId: IReactSelectValue | undefined,
    productCategory?: IProductCategory,
    productDescription: string,
    partNumber: string,
    manufacturerIds: any[] | undefined;
    manufacturer?: IManufacturer;
    productManufacturerId: IReactSelectValue | undefined;
    productManufacturer?: IProductManufacturer;
    productDetails: any;
    imageId: string;
    image?: IImage,
    createdTs?: Date,
    imagesObj?: IImage[];
    imagesIds: string[];
    newSalePrice: number,
    qty?: number,
    _totalWeight?: number;
    _weights: number[];
    weight?: number,
    price?: number;
    stock?: number;
    _storeObject?: IStore;
    _productObject?: IProduct;
    _otherStock?: IStock[];
}


const setFocusHack = (fieldId: string) => {
    if (document !== null && document.getElementById !== null) {
        const d = document.getElementById(fieldId)
        if (d !== null) {
            d.focus();
        }
    }
}


interface iToggleButton {
    fieldName: string;
    isVisible: (something: string) => boolean;
    toggleField: (something: string) => void;
}

const ToggleButton = ({ fieldName, toggleField, isVisible }: iToggleButton) => {
    return <button type="button" tabIndex={-1} className='btn btn-warning' onClick={(event: any) => {
        event.preventDefault();
        toggleField(fieldName);
    }}>
        {isVisible(fieldName) ? <FontAwesomeIcon icon={faEyeSlash} /> : <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>}
        {fieldName}
    </button >
}


interface iAdminBuyinActualForm {
    productManufacturer?: IProductManufacturer[];
    productCategories?: IProductCategory[];
    manufacturers?: IManufacturer[];
    values: IBuyinForm;
    handleSubmit: any;
    form: FormApi<IBuyinForm, Partial<IBuyinForm>>

}
const AdminBuyinActualForm = ({ values, handleSubmit, form, productCategories, productManufacturer, manufacturers }: iAdminBuyinActualForm) => {
    const { addNotification } = useNotifications()!;



    const [hiddenFields, setHiddenFields] = useState<string[]>([])
    useEffect(() => {
        //get from local stroage

        const something = window.localStorage.getItem('buyInHiddenFields');
        if (something !== null) {
            const val: string[] = something.split(',')
            setHiddenFields(val);
        }
    }, [])


    const toggleField = (string: string) => {
        const someFields = [...hiddenFields];
        if (hiddenFields.indexOf(string) === -1) {
            someFields.push(string);
        } else {
            someFields.splice(hiddenFields.indexOf(string), 1);
        }

        setHiddenFields(someFields)
        window.localStorage.setItem('buyInHiddenFields', someFields.join(','));

    }


    const isVisible = (string: string) => {
        if (hiddenFields.indexOf(string) === -1) {
            return true;
        } else {
            return false;
        }
    }


    return <form onSubmit={handleSubmit} className="hiContrastForm" id="buyInForm">

        <DebugButton data={JSON.parse(JSON.stringify(values))} alwaysDisplay={true} />
        <div className="row">
            <div className="col-12">



                <Field
                    name="storeId"
                    title="Physical Location"
                    description="Where is this item in the warehouse"
                    component={XAsyncSelectAdapter}
                    promiseOptions={promiseOptionsStore}
                    autoFocus
                    inputId={"storeID"}



                />
                <OnChange name={`storeId`}>
                    {(value, previous) => {
                        const asyncHack = async () => {

                            if (value === null) {
                                form.change('_storeObject', undefined);
                            } else {
                                if (value.value && !value.__isNew__) {
                                    const storeZ = await RequestGetStore(addNotification, value.value)
                                    form.change('_storeObject', storeZ)
                                }
                            }


                        }
                        console.log("storedId", value)
                        asyncHack();
                        setFocusHack('productId')
                    }}
                </OnChange>
                {isVisible('Store Details') && <>
                    {values._storeObject && <div>
                        {values._storeObject._stock &&
                            <DataTable data={values._storeObject._stock} colHeadings={[
                                { field: 'productId', name: 'product', sortable: true },
                                { field: 'qty', name: 'qty', sortable: true }
                            ]} />}

                    </div>}</>}
                <ToggleButton isVisible={isVisible} toggleField={toggleField} fieldName='Store Details' />

                <Field
                    name="productId"
                    title="Part Number"
                    description="Unique Part Number, for custom parts prefix with SBM"
                    component={AsyncSelectCreateAdapter}
                    promiseOptions={promiseOptionsProduct}
                    validate={composeValidators(required)}
                    // onChange={() => { setFocusHack('qty') }}
                    inputId={"productId"}
                />


                <button type='button' className='btn btn-primary' onClick={() => {


                    const gooo = async () => {
                        const ddd = await RequestBuyinFunction(addNotification, 'newSBM')
                        console.log("sssss", ddd.seq)


                        form.change('productId', { value: `SBM-${ddd.seq}`, __isNew__: true, label: `SBM-${ddd.seq}` })
                        setFocusHack('productCategoryId')
                    }
                    gooo();
                }}>SBM Part Number</button>
                <OnChange name={`productId`}>
                    {(value, previous) => {
                        console.log("productId", value)
                        const asyncHack = async () => {


                            if (value === null) {
                                form.change('_productObject', undefined)
                                form.change('weight', undefined)
                                form.change('_otherStock', undefined)
                            } else {
                                if (value.value && !value.__isNew__) {
                                    const productZ = await RequestGetProduct(addNotification, value.value)
                                    form.change('_productObject', productZ)
                                    form.change('weight', productZ.weight)
                                    const stocks = await RequestGetStocks(addNotification, { productId: value.value })
                                    form.change('_otherStock', stocks)
                                    setFocusHack('qty')
                                } else {
                                    setFocusHack('productCategoryId')
                                }
                            }

                        }
                        console.log("storedId", value)
                        asyncHack();

                    }}
                </OnChange>


                {values._productObject && <div>
                    <h2>{values._productObject.partNumber}</h2>
                    {values._productObject.partNumber}
                    <div className='d-flex justify-content-between'>
                        {/* <div>Bike Manufacturer: {values._productObject.manufacturer?.name}</div> */}
                        <div>Part Manufacturer: {values._productObject.productManufacturer?.name}</div>
                        <div>Category: {values._productObject.productCategory?.name}</div>
                        <div>{values._productObject.description}</div>

                    </div>

                    <DebugButton data={JSON.parse(JSON.stringify(values._productObject))} alwaysDisplay={true} />
                </div>}


                {values._otherStock && <DataTable data={values._otherStock} colHeadings={[
                    { field: 'productId', name: 'product', sortable: true },
                    { field: 'qty', name: 'qty', sortable: true }
                ]} />}
                {values.productId && values.productId.__isNew__ === true && <div>
                    <h2>Your adding a new Product</h2>


                    <Field name="productCategoryId"
                        title="Category" component={AsyncSelectCreateAdapter}
                        promiseOptions={promiseOptionsProductCategory}
                        defaultOptions={(productCategories !== undefined ? productCategories.map(d => {
                            return {
                                value: d.id,
                                label: d.name
                            }
                        }) : [])}
                        inputId={"productCategoryId"}
                        description="What is this item"
                    />
                    <OnChange name={`productCategoryId`}>
                        {(value, previous) => {
                            console.log("productId", value)

                            RequestGetProductCategorys(undefined, { id: value.value }).then(dataArr => {
                                console.log("producs", dataArr[0].details)
                                if (dataArr[0] !== undefined) {
                                    form.change('productCategory', dataArr[0]);
                                    form.change('productCategoryDetails', dataArr[0].details);
                                } else {
                                    form.change('productCategoryDetails', []);
                                }
                            })

                            setFocusHack('productManufacturerId')
                        }}
                    </OnChange>



                    {isVisible('productManufacturerId') && <Field name="productManufacturerId"
                        title="Part Manufacturer"
                        component={AsyncSelectCreateAdapter}
                        promiseOptions={promiseOptionsProductManufacturer}
                        defaultOptions={(productManufacturer !== undefined ? productManufacturer.map(d => {
                            return {
                                value: d.id,
                                label: d.name
                            }
                        }) : [])}
                        description="Whom made this"
                        inputId={"productManufacturerId"}
                    />}
                    <ToggleButton isVisible={isVisible} toggleField={toggleField} fieldName='productManufacturerId' />
                    <OnChange name={`productManufacturerId`}>
                        {(value, previous) => {
                            //   console.log("productId", value)
                            setFocusHack('manufacturerId')
                        }}
                    </OnChange>


                    {isVisible('bike-manufacturer') &&
                        <FieldArray name="manufacturerIds">
                            {({ fields }) => (
                                <div className="">
                                    {fields.map((name, index) => {
                                        return <div key={index} className="">
                                            <button className='btn btn-danger' tabIndex={-1} type='button' onClick={() => {
                                                fields.remove(index)
                                            }}>Delete ({index + 1})</button>
                                            <Field name={name}
                                                title={`Bike Manufacturer (${index + 1})`}
                                                component={AsyncSelectCreateAdapter}
                                                promiseOptions={promiseOptionsManufacturer}
                                                defaultOptions={(manufacturers !== undefined ? manufacturers.map(d => {
                                                    return {
                                                        value: d.id,
                                                        label: d.name
                                                    }
                                                }) : [])}
                                                description="What Bike Manufacture is this for"
                                                inputId={"manufacturerId"}
                                            />
                                            {index === 0 && <OnChange name={name}>
                                                {(value, previous) => {
                                                    //   console.log("productId", value)
                                                    setFocusHack('productDescription')
                                                }}
                                            </OnChange>}
                                        </div>
                                    })}
                                    <button className='btn btn-success' tabIndex={-1} type='button' onClick={() => {
                                        fields.push({})
                                    }}>Add Bike Manufacture</button>
                                </div>)}
                        </FieldArray>}
                    <ToggleButton isVisible={isVisible} toggleField={toggleField} fieldName='bike-manufacturer' />

                    {isVisible('productDescription') && <Field name="productDescription">
                        {({ input, meta }) => (<div className='row'>
                            <div className="col-sm-12 col-md-2">
                                <label>Description</label>
                                <p className="descriptionText">Detailed Description</p>
                            </div>
                            <div className="col-sm-12 col-md-8">

                                <textarea id="productDescription" type="text" className="hiContrastFormField" {...input} placeholder="...." />
                            </div>
                            <div className="col-sm-12 col-md-2">
                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                            </div>
                        </div>
                        )}
                    </Field>}
                    <ToggleButton isVisible={isVisible} toggleField={toggleField} fieldName='productDescription' />

                    <FieldArray name="productCategoryDetails">
                        {({ fields }) => (<>
                            <div className="row">
                                {fields.map((name, index) => {

                                    if (values.productCategoryDetails) {
                                        const detail = values.productCategoryDetails[index];
                                        let display = false;
                                        if (!detail.conditionalField || detail.conditionalField === '') {
                                            display = true;
                                        } else {
                                            const condIndex = values.productCategoryDetails.findIndex(d => d.field === detail.conditionalField);
                                            if (condIndex !== -1) {
                                                const cond = values.productCategoryDetails[condIndex].field;

                                                const condVal = values.productDetails[cond];

                                                if (condVal === detail.conditionalValue) {
                                                    display = true;
                                                }
                                            }
                                        }



                                        return <div key={name} className="col-12">
                                            {/* {JSON.stringify(display)}{JSON.stringify(detail)} */}
                                            {display && detail.type === 'text' && <>
                                                <Field name={`productDetails.${detail.field}`}>
                                                    {({ input, meta }) => <InputTextTemplate input={input} meta={meta} text={detail.title} />}
                                                </Field>

                                            </>}



                                            {display && detail.type === 'number' && <>
                                                <Field name={`productDetails.${detail.field}`}
                                                    parse={(value, name) => {
                                                        console.log("parse", value, name)
                                                        return parseInt(value);
                                                    }}
                                                    format={(value, name) => {
                                                        console.log("format", value, name)
                                                        return parseInt(value);
                                                    }}>
                                                    {({ input, meta }) =>
                                                        <InputTextTemplate input={input} meta={meta} iType="number" text={detail.title} />}
                                                </Field>
                                            </>}

                                            {display && detail.type === 'select' && <>
                                                <Field name={`productDetails.${detail.field}`}>
                                                    {({ input, meta }) =>
                                                        <InputSelectTemplate input={input} meta={meta} text={detail.title}><option></option>

                                                            {detail.options && detail.options.map((cat, index) => {
                                                                return <option value={cat.value}>{cat.text}</option>;
                                                            })}
                                                        </InputSelectTemplate>}
                                                </Field>

                                            </>}

                                            {display === true && detail.type === 'yesno' && <Field name={`productDetails.${detail.field}`}>
                                                {({ input, meta }) =>
                                                    <InputCheckboxTemplate input={input} meta={meta} text={detail.title} desc={detail.title} />
                                                }
                                            </Field>}
                                        </div>

                                    } else {
                                        return <>Error</>
                                    }

                                })}
                            </div>
                        </>)}
                    </FieldArray>

                    {isVisible('productManufacturerCode') && <Field
                        name="productManufacturerCode"
                        text="Manufacturer Part Code"
                        className="hiContrastFormField"
                        description="Manufacturer's Part Number"
                        component={InputTextTemplate}
                        inputId={"productManufacturerCode"}
                    />}
                    <ToggleButton isVisible={isVisible} toggleField={toggleField} fieldName='productManufacturerCode' />


                    {isVisible('images') && <div className="col-12">

                        <h3>Product Images</h3>
                        <FieldArray name="imagesObj">
                            {({ fields }) => (
                                <div className="row">
                                    {fields.map((name, index) => {
                                        let src: string = '';


                                        if (values.imagesObj && values.imagesObj[index]) {
                                            const id = values.imagesObj[index].id

                                            return <div key={name} className="col-4">
                                                <ImageDisplay image={values.imagesObj[index]} />
                                                <div>
                                                    {/* <label>Name - doesnt work yet</label>
                                                <Field name={`${name}.name`} component="input" /> */}
                                                </div>
                                                <div className="btn-group">
                                                    {/* {values.imagesObj[index] && values.imagesObj[index].id !== values.imageId && <button className="btn btn-success" type="button" onClick={() => {


                                                    form.change('imageId', id)
                                                    values.imagesObj && form.change('image', values.imagesObj[index])

                                                }}>Set Primary</button>} */}
                                                    <button className="btn btn-danger" tabIndex={-1} type="button" onClick={() => {
                                                        if (values.imagesObj) {
                                                            const imageIds: string[] = values.imagesIds
                                                            const index: number = values.imagesObj && imageIds.findIndex(dd => dd === id);
                                                            imageIds.splice(index, 1);
                                                            form.change('imagesIds', imageIds)
                                                            fields.remove(index)
                                                        }


                                                    }}>Remove</button>
                                                </div>
                                            </div>

                                        }
                                    })}

                                    <div className="col-12 w-100">
                                        <UploadImages text={"Take Photo For this item"} aspect="none" name="product" attachId={"newProduct"} attachTo={"product"} save={(dd: any) => {

                                            const imageIds: string[] = values.imagesIds
                                            imageIds.push(dd.id);


                                            form.change('imagesIds', imageIds)
                                            fields.push(dd)

                                            if (imageIds.length === 1) {
                                                form.change('imageId', dd.id)
                                            }
                                        }} />
                                    </div>
                                </div>
                            )}
                        </FieldArray>
                        <OnChange name={`imagesObj`}>
                            {(value, previous) => {

                                console.log("imageId", value)
                                form.submit()
                            }}
                        </OnChange>

                    </div>}
                    <ToggleButton isVisible={isVisible} toggleField={toggleField} fieldName='images' />

                </div>}


                {isVisible('weight') && <Field
                    name="weight"
                    disabled={(values.productId && values.productId.__isNew__ === false)}

                    className="hiContrastFormField"
                    iType="number"
                    iiType="float"
                    text="Weight"
                    id="weight"
                    description="Wight in (g)"
                    component={InputTextTemplate}
                />}
                <ToggleButton isVisible={isVisible} toggleField={toggleField} fieldName='weight' />
                <Field
                    name={`qty`}

                    validate={composeValidators(required)}
                    id={'qty'}
                    className="hiContrastFormField"
                    iType="number"
                    iiType="int"
                    text="Quantity"
                    description="Number of this item in this place"
                    component={InputTextTemplate}
                />

                {isVisible('weights') && <FieldArray name="_weights">
                    {({ fields }) => (

                        <div className="">
                            <button type='button' className='btn btn-primary' onClick={() => {
                                fields.push(0)
                            }}>Add Weight Total</button>
                            {fields.map((d, dIndex) => <Field
                                key={dIndex}
                                name={d}

                                alidate={composeValidators(required, minValue(1))}
                                id={'qty'}
                                className="hiContrastFormField"
                                iType="number"
                                iiType="int"
                                text={`Weight ${dIndex + 1}`}
                                description="Number of this item in this place"
                                component={InputTextTemplate}
                            />)}

                        </div>
                    )}

                </FieldArray>}
                <ToggleButton isVisible={isVisible} toggleField={toggleField} fieldName='weights' />


                <OnChange name={`_weights`}>
                    {(value, previous) => {
                        if (value != previous) {
                            console.log(value)

                            const valued = value.reduce((sum: number, current: number) => sum + current, 0);

                            form.change('_totalWeight', valued)


                        }
                    }}
                </OnChange>
                {values._totalWeight && <div className='row'>
                    <div className="col-sm-12 col-md-2">
                        Total Weight
                    </div>
                    <div className="col-sm-12 col-md-8">

                        {values._totalWeight}
                        <br />
                        <button type='button' className='btn btn-success' onClick={() => {
                            const total = (values._totalWeight ? values._totalWeight : 0);
                            const weight = (values.weight ? values.weight : 0);
                            form.change('qty', Math.ceil(total / weight))

                        }}>Magic Button</button>
                    </div>
                    <div className="col-sm-12 col-md-2">

                    </div>

                </div>}

                {isVisible('newSalePrice') && <Field
                    name={`newSalePrice`}

                    // validate={composeValidators(required)}
                    component={InputTextTemplate}
                    iType="number"
                    iiType="float"
                    text="Sale Price GBP"
                    description="Sale Price per item"
                />}
                <ToggleButton isVisible={isVisible} toggleField={toggleField} fieldName='newSalePrice' />


                {isVisible('purchasePrice') && <Field
                    name={`purchasePrice`}

                    id={'purchasePrice'}
                    className="hiContrastFormField"
                    iType="number"
                    iiType="float"
                    text="Purchase Price"
                    description="Cost Per item, (blank for current stock)"
                    component={InputTextTemplate}
                />}
                <ToggleButton isVisible={isVisible} toggleField={toggleField} fieldName='purchasePrice' />
                <div className='row'>
                    <div className="col-sm-12 col-md-2">
                        <label>Is New</label>
                        <p className="descriptionText">De-Select if 2nd use stock</p>
                    </div>
                    <div className="col-sm-12 col-md-8">


                        <Field component="input" type="checkbox" name={`isNewA`} />
                    </div>
                    <div className="col-sm-12 col-md-2">

                    </div>
                </div>
            </div>


            <div className="col-12">
                {JSON.stringify(hiddenFields)}
                <DebugButton data={JSON.parse(JSON.stringify(values))} alwaysDisplay={true} />
                <button id="submit" className="btn btn-primary" type="submit">Submit</button>
            </div>
        </div>















    </form>


}

const AdminBuyIn = () => {

    const location = useLocation();

    const [loading, setLoading] = useState<boolean>(true);
    const { addNotification } = useNotifications()!;
    const [productManufacturer, setProductManufacturer] = useState<IProductManufacturer[]>();
    const [productCategories, setProductCategories] = useState<IProductCategory[]>();
    const [manufacturers, setManufacturers] = useState<IManufacturer[]>();

    const [thing, setThing] = useState<IBuyinForm>();


    useEffect(() => {
        const load = async () => {
            try {

                if (thing === undefined && loading == true) {

                    setProductManufacturer(await RequestGetProductManufacturers(addNotification, {}));
                    setManufacturers(await RequestGetManufacturers(addNotification, {}));
                    setProductCategories(await RequestGetProductCategorys(addNotification, {}));

                    let query: any = queryString.parse(location.search)
                    const catId = (query.productCategoryId ? query.productCategoryId : '')
                    setThing({ isNewA: true, qty: 1, lastPurchasePrice: 0, productDetails: {}, _weights: [], imageId: '', storeId: undefined, manufacturerIds: [{}], productManufacturerId: undefined, productId: undefined, data: {}, productDescription: '', productCategoryId: catId, partNumber: '', price: 0, stock: 0, imagesIds: [], imagesObj: [], newSalePrice: 0, })

                    setLoading(false)
                }

            } catch (ex: any) {
                console.error(ex)
            }

        }

        load()
    }, []);

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);

            await RequestNewBuyin(addNotification, data);


            setThing({
                isNewA: true,
                lastPurchasePrice: 0,
                productDetails: {},
                _weights: [],
                qty: 1, imageId: '', storeId: data.storeId, productManufacturerId: data.productManufacturerId, manufacturerIds: data.manufacturerIds,
                productId: undefined, data: {}, productDescription: '', productCategoryId: data.productCategoryId, partNumber: '', price: 0, stock: 0, imagesIds: [], imagesObj: [], newSalePrice: 0, productCategoryDetails: data.productCategoryDetails
            })
            addNotification && addNotification('Done', 'You have added stock', ENotificationType.Success);
            setLoading(false)
        } catch (tryExc: any) {
            console.log(tryExc)

            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }

    // const focusOnError = createDecorator<IBuyinForm>()
    return (<>
        {loading === true && <Loading />}


        {loading === false && <div className="row">
            <div className="col-12 bold-cursor">

                <Form <IBuyinForm>
                    onSubmit={onSubmit}
                    initialValues={thing}
                    // decorators={[focusOnError]}
                    mutators={{
                        // expect (field, value) args from the mutator
                        ...arrayMutators,
                        setValue: ([field, value], state, { changeValue }) => {
                            changeValue(state, field, () => value)
                        }
                    }}
                    render={({ handleSubmit, form, values }) => {
                        return <AdminBuyinActualForm handleSubmit={handleSubmit} form={form} values={values} manufacturers={manufacturers} productCategories={productCategories} productManufacturer={productManufacturer} />
                    }}
                />

            </div>

        </div>
        }
    </>
    );
}

export default AdminBuyIn;