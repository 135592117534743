import React, { useEffect, useState } from "react";
import { Link, Route, Switch, useRouteMatch } from "react-router-dom";
import { RequestDeleteCheckout, RequestGetCheckouts } from '../../../api/checkout-requests';
import { RequestNewLog } from "../../../api/log-requests";
import DebugButton from "../../../components//system/DebugButton";
import DataTable from "../../../components/system/DataTable";
import DateDisplay from "../../../components/system/DateDisplay";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { ICheckout } from "../../../interface/checkout";
import { ELogType, ESeverity } from "../../../interface/log";
import AdminCheckout from './Admin.Checkout';


const AdminCheckoutsPge = ({ }: any) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [things, setThings] = useState<ICheckout[]>();
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;



    {/*<!-- OPTIONAL 1 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }



    useEffect(() => {
        try {
            if (loading === true) {

                {/*<!-- OPTIONAL 1 -->*/ }
                RequestGetCheckouts(addNotification).then(thing => {
                    //   console.log(stores)
                    setThings(thing);
                    setLoading(false);
                })

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Checkouts - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }, [loading]);

    const onDelete = (value: ICheckout) => {
        try {
            const conf = window.confirm("Delete?");
            if (conf) {
                RequestDeleteCheckout(addNotification, value).then(res => {
                    //  console.log(res);
                    setThings([]);
                    setLoading(true);
                    addNotification && addNotification('Success', 'Checkout Deleted!', ENotificationType.Primary);
                });
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Checkouts - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }



    return (<>

        {things && <div className="col-12">

            {/*<!-- OPTIONAL 2 -->*/}
            <h2>Orders</h2>
            <p className="bg-primary">orders list of all orders on the system</p>

            <p className="bg-primary">types: web (orders from website), phone - telephone orders, in-store- walk in checkouts - legacy orders from old buisness, these are not stock referenced or product referenced</p>
            <p className="bg-primary">status: started - first stage of checkout complete, </p>

            <DataTable

                colHeadings={[
                    { name: "Invoice Number", field: "invoiceNumber", sortable: true },
                    { name: "Name", field: "name", sortable: true },
                    { name: "Status", field: "status", sortable: true },
                    { name: "Type", field: "type", sortable: true },
                    {
                        name: "Date", field: "created", sortable: true, r: (value: any, index) => {
                            return <>

                                {value.created !== null && <DateDisplay date={value.created} />}

                            </>
                        }
                    },
                    {
                        name: "Total", field: "totalTotal", sortable: true, r: (value: any, index) => {
                            return <>

                                {value.totalTotal !== null && value.totalTotal && value.totalTotal.toFixed(2)}

                            </>
                        }
                    },
                    { name: "Gateway", field: "gatewayType", sortable: true },
                    {
                        name: "Actions", field: "id", r: (value: any, index) => {
                            return <div className="btn-group">
                                <DebugButton data={value} alwaysDisplay={true} />
                                <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link>
                                <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    onDelete(value);
                                }}>Delete</button>
                            </div>;
                        }, sortable: true
                    },

                ]}

                data={things.reverse()}
            />

            {/*<!-- OPTIONAL END -->*/}
        </div>}
    </>
    );
};
const AdminCheckouts = ({ }: any) => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${match.path}/:id`}>
                <AdminCheckout />
            </Route>
            <Route path={`${match.path}`} exact>
                <AdminCheckoutsPge />
            </Route>

        </Switch>
    </>
    );
}

export default AdminCheckouts;