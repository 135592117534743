
import React from 'react';
import {  Route, useRouteMatch } from "react-router-dom";
import Switch from "react-bootstrap/esm/Switch";

// interface IOrderHolderProp {
//     order: IOrder;
// }
// const OrderHolder = ({ order }: IOrderHolderProp) => {
//     const [notesVisible, setNotesVisible] = useState<boolean>(false)
//     const locaion = useLocation();
//     return <>
//         <div className="row">
//             {order.state === OrderState.Error ? <p>Order Error</p> : <>
//                 <div style={{ width: "12.5%" }} className={`${order.state === OrderState.Placed && 'bg-primary'}`}>{OrderState.Placed}</div>
//                 <div style={{ width: "12.5%" }} className={`${order.state === OrderState.Started && 'bg-primary'}`}>{OrderState.Started}</div>
//                 <div style={{ width: "12.5%" }} className={`${order.state === OrderState.Packaged && 'bg-primary'}`}>{OrderState.Packaged}</div>
//                 <div style={{ width: "12.5%" }} className={`${order.state === OrderState.Posted && 'bg-primary'}`}>{OrderState.Posted}</div>
//                 <div style={{ width: "12.5%" }} className={`${order.state === OrderState.Delivered && 'bg-primary'}`}>{OrderState.Delivered}</div>
//                 <div style={{ width: "12.5%" }} className={`${order.state === OrderState.Reviewed && 'bg-primary'}`}>{OrderState.Reviewed}</div>
//             </>}

//         </div>
//         <div className="row">
//             <div className="col-6">
//                 <h4>Order Notes</h4>

//                 {notesVisible === true && <>
//                     <table className="table">
//                         <thead>
//                             <tr>
//                                 <th scope="col">#</th>
//                                 <th scope="col">First</th>
//                                 <th scope="col">Note</th>
//                                 <th scope="col">Handle</th>
//                             </tr>
//                         </thead>
//                         <tbody>
//                             {order.stateLog.map((log, index) => {
//                                 return <tr key={index}>
//                                     <th scope="row">{index}</th>
//                                     <td>{log.title}</td>
//                                     <td>{log.notes}</td>
//                                     <td>@mdo</td>
//                                 </tr>
//                             })}

//                         </tbody>
//                     </table>
//                 </>}
//                 <div className="col-12">
//                     <button onClick={(ev: React.MouseEvent) => {
//                         setNotesVisible(!notesVisible);
//                     }} className="btn btn-success">{notesVisible === false ? "show order notes" : 'hide order notes'}</button>
//                 </div>
//             </div>
//             <div className="col-6">
//                 <h4>Order Options</h4>
//                 <div className="btn-group">
//                     <Link className="btn btn-primary" to={`${locaion.pathname}/${order.id}`}>Edit Order</Link>
//                     <button className="btn btn-success">do something</button>
//                 </div>
//             </div>
//         </div>

//     </>
// }


const MyProfileOrders = () => {
    let match = useRouteMatch();
    return (
        <>
            <h3>Orders</h3>
            <Switch>
                <Route path={`${match.path}`} exact>
                    <>
                        {/* {user.orders.map(order => {
                            return <div key={order.id} className="col-12">
                                <h3>{order.id}</h3>
                                <p>{order.state}</p>
                                <OrderHolder order={order} />
                            </div>
                        })} */}
                    </>
                </Route>
                <Route path={`${match.path}/:orderId`}>
                   <>some order</>
                </Route>
            </Switch>

        </>
    );
}

export default MyProfileOrders;