import React from 'react';
import { Link } from "react-router-dom"
import CartDisplay from "./CartDisplay";

function Cart() {

    return (
        <>
           <h2>Cart</h2>

           <CartDisplay />
        
           <Link to="checkout">Checkout</Link>
        </>
    );
}

export default Cart;