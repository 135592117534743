
export interface ILog {
    _id?:string;
    type:ELogType;
    requestId:string;
    sessionId:string;
    userId:string;
    jobId?:string;
    payload?:any;
    created?:Date;
    severity:ESeverity;
    text:string;
    thing?:string;
    thingId?:string;
}

export enum ELogType{
    "Unknown"="void",
    "http"="Http Request",
    "system"="System Generated",
    "event"="User Event",
    "mongo"="Mongodb Error",
    "reactException"="React Errors",
    "reactUse"="React Use Stats",
    "paymentGateway"="Payment Gateway"
}

export enum ESeverity{
   "Trivial"=0,
   "Debug"=1, // green
   "Note"=2, // blue
   "Issue"=3, // orange
   "Serious"=4 // red
}