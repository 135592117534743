import { useState } from "react";

interface IInput {
    text: string,
    className?: string;
    iType?: string,
}
const InputTextTemplate = ({ id, description, className, input: { onChange, value }, meta, text, iType, iiType }: IInput & any): any => {
    // console.log(input)
    const inpClass = (className !== undefined ? className + "" : 'form-control')
    // const t: string = iType === undefined ? 'text' : iType;

    const [val, setValue] = useState(() => {


        if (!isNaN(value) && value !== undefined) {

            return value
        }
    })

    let inputProps: any = {
        id, type: "text", className: inpClass,
    }


    const onFieldUpdate = (value: any) => {
        console.log("onFieldUpdate", value, iType, iiType)
        setValue(value.target.value)
        if (iType === 'number') {
            if (iiType === 'int') {
                onChange(parseInt(value.target.value))
            } else {
                onChange(parseFloat(value.target.value))
            }
        } else {
            onChange(value)
        }

    }


    const onFocusEvent = (focusEvent: any) => {
        console.log("onFocusEvent", focusEvent)
        const input = focusEvent.target;

        input.select();
    }

    const increment = () => {
        console.log("increment", iType)
        if (iType === 'number') {
            let number = parseFloat(val);
            number = number + 1;
            if (iiType === 'int') {
                setValue(`${number}`)
                onChange(parseInt(`${number}`))
            } else {
                setValue(`${number}`)
                onChange(parseFloat(`${number}`))
            }
        }
    }

    const decrement = () => {
        console.log("decrement", iType)
        if (iType === 'number') {
            let number = parseFloat(val);
            number = number - 1;
            if (iiType === 'int') {
                setValue(`${number}`)
                onChange(parseInt(`${number}`))
            } else {
                setValue(`${number}`)
                onChange(parseFloat(`${number}`))
            }
        }
    }
    return <div className="row pb-2 pt-2">
        <div className="col-sm-12 col-md-2">
            <label>{text}</label>
            <p className="descriptionText">{description}</p>
        </div>
        <div className="col-sm-12 col-md-8">
            <input {...inputProps} value={val} onChange={onFieldUpdate} onFocus={onFocusEvent} placeholder="...." />
        </div>
        <div className="col-sm-12 col-md-2">
            {iType === 'number' && <div className="button-group"><button type="button" tabIndex={-1} className="btn btn-success" onClick={() => {
                increment();
            }}>+</button><button type="button" tabIndex={-1} className="btn btn-success" onClick={() => {
                decrement();
            }}>-</button></div>}
            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
        </div>

    </div>
}

export default InputTextTemplate;