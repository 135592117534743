import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import JSONPretty from 'react-json-prettify';
import { Redirect, useLocation, useRouteMatch } from 'react-router-dom';
import { RequestCheckoutFunction, RequestGetCheckout, RequestNewCheckout, RequestUpdateCheckout } from '../../../api/checkout-requests';
import { RequestNewLog } from '../../../api/log-requests';
import AdminCheckoutShipping from '../../../components/AdminCheckoutShipping';
import AdminCheckoutStockLocations from '../../../components/AdminCheckoutStockLocations';
import DataTable from '../../../components/system/DataTable';
import DateDisplay from '../../../components/system/DateDisplay';
import DebugButton from '../../../components/system/DebugButton';
import Loading from '../../../components/system/loading';
import MoneyDisplay from '../../../components/system/MoneyDisplay';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { ECheckoutState, ICheckout } from '../../../interface/checkout';
import { ELogType, ESeverity } from '../../../interface/log';




interface IAdminCheckoutProp {
    //   types: IProductCategory[];
    //   manufacturers: IManufacturer[];
}
const AdminCheckout = ({ }: IAdminCheckoutProp) => {
    let location = useLocation();
    const [id, setId] = useState<string>();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<ICheckout>();
    const { addNotification } = useNotifications()!;

    //const [stores, setCheckouts] = useState<ICheckout[]>();



    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();


    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/checkouts/:id");

    useEffect(() => {
        return function cleanup() {
            console.log("cleanup", loading, thing)
            setLoading(false);
            setThing(undefined);

        }
    }, []);

    useEffect(() => {
        try {
            const _id = match.params.id;

            if (loading === true && id !== _id) {
                setId(_id)

                {/*<!-- OPTIONAL 2 -->*/ }

                if (id === 'new') {
                    setThing({
                        id: 'new',
                        company: '',
                        type: 'legacy',
                        name: '',
                        userId: '',
                        knownCustomer: "",
                        firstName: '',
                        lastName: '',
                        email: '',
                        totalBuyinValueOfItems: 0,
                        totalProfitOfItems: 0,
                        landline: '',
                        mobile: '',
                        addressBillingCity: '',
                        addressBillingCountry: '',
                        addressBillingCounty: '',
                        addressBillingPostCode: '',
                        addressBillingStreet: '',
                        addressBillingTown: '',
                        addressDeliveryTelephone: '',
                        addressDeliveryCity: '',
                        addressDeliveryCountry: '',
                        addressDeliveryCounty: '',
                        requestId: '',
                        addressDeliveryPostCode: '',
                        addressDeliveryStreet: '',
                        addressDeliveryTown: '',
                        deliveryInstructions: '',
                        cartItems: [],
                        totalTotal: 0,
                        totalWeight: 0,
                        subTotal: 0,
                        shippingTotal: 0,
                        status: ECheckoutState.Started,
                        gatewayType: '',
                        gatewayData: {},
                        gatewayLog: [],
                        totalIncome: 0,
                        gatewayFee: 0,
                    });
                    Promise.allSettled([]).then(() => {
                        setLoading(true)
                        setLoaded(true)
                    });
                } else {
                    const prodCall = () => {
                        return RequestGetCheckout(addNotification, _id).then(thing => {
                            setThing(thing);
                        })
                    }

                    Promise.allSettled([prodCall()]).then(() => {
                        setLoading(true)
                        setLoaded(true)
                    });
                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Checkout - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            let ppp: Promise<ICheckout>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Checkout Created!';
                ppp = RequestNewCheckout(addNotification, data);
            } else {
                notificationText = 'Checkout Saved!';
                ppp = RequestUpdateCheckout(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                setRedirect(true);
            })
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Checkout - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 1 -->*/ }


    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        {redirect && <Redirect to="../checkouts" />}
        {loaded === true && thing ? <>


            {/*<!-- OPTIONAL 1 -->*/}

            <div className="col-12 row">
                <div className="col-12 d-flex justify-content-between">
                    <p>Type: {thing.type}</p>
                    <p>Payment Type: {thing.gatewayType}</p>
                    <p>Status: <span id="orderStatus">{thing.status}</span></p>
                </div>
                <div className="col-3">
                    <h2>Details</h2>
                    <div className="d-flex justify-content-between">
                        <p>Invoice Number:</p>
                        <p id="invoiceNumber">{thing.invoiceNumber}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Name:</p>
                        <p id="orderName">{thing.name}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>First Name:</p>
                        <p id="firstName">{thing.firstName}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Last Name:</p>
                        <p id="lastName">{thing.lastName}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Email:</p>
                        <p id="email">{thing.email}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Telephone:</p>
                        <p id="landline">{thing.landline}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>User:</p>
                        <p id="userId">{thing.userId}</p>
                    </div><div className="d-flex justify-content-between">
                        <p>Recept</p>
                        <p><button className="btn btn-info" id={`btnPdfInvoice`} type="button" onClick={(ev: React.MouseEvent) => {
                            ev.preventDefault();
                            RequestCheckoutFunction(undefined, 'pdfInvoice', { checkoutId: thing.id }).then(something => {
                                //       console.log("jhk__________", something.payload);
                                const linkSource = `data:application/pdf;base64,${something.payload}`;
                                const downloadLink = document.createElement("a");


                                downloadLink.href = linkSource;
                                downloadLink.download = `order${thing.id}.pdf`;
                                downloadLink.click();
                            }).catch(Errr => {
                                console.log("err", Errr)
                            })
                        }}><FontAwesomeIcon icon={faFilePdf} />Recept Sheet</button></p>
                    </div>



                </div>

                <div className="col-3">
                    <h2>Billing Address</h2>
                    <p id="addressBillingStreet">{thing.addressBillingStreet}</p>
                    <p id="addressBillingTown">{thing.addressBillingTown}</p>
                    <p id="addressBillingCity">{thing.addressBillingCity}</p>
                    <p id="addressBillingCountry">{thing.addressBillingCountry}</p>
                    <p id="addressBillingPostCode">{thing.addressBillingPostCode}</p>
                </div>

                <div className="col-3">
                    <h2>Delivery Address</h2>

                    <p id="addressDeliveryStreet">{thing.addressDeliveryStreet}</p>
                    <p id="addressDeliveryTown">{thing.addressDeliveryTown}</p>
                    <p id="addressDeliveryCity">{thing.addressDeliveryCity}</p>
                    <p id="addressDeliveryCountry">{thing.addressDeliveryCountry}</p>
                    <p id="addressDeliveryPostCode">{thing.addressDeliveryPostCode}</p>
                </div>

                <div className="col-3">
                    <h2>Money</h2>
                    <div className="d-flex justify-content-between">
                        <p>Sub-Total:</p>
                        <p id="subTotal">£ <MoneyDisplay value={thing.subTotal} /></p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Shipping:</p>
                        <p id="shippingTotal">£ <MoneyDisplay value={thing.shippingTotal} /></p>

                    </div>
                    <div className="d-flex justify-content-between">
                        <p>Grand Total:</p>
                        <p id="totalTotal">£ <MoneyDisplay value={thing.totalTotal} /></p>

                    </div>
                </div>
                <div className="col-12">
                    <DataTable

                        colHeadings={[
                            { name: "Name", field: "productDescription", sortable: true },
                            { name: "Qty", field: "qty", sortable: true },
                            { name: "Price", field: "price", sortable: true },
                            {
                                name: "Actions", field: "id", r: (value: any, index) => {
                                    return <div className="btn-group">
                                        <DebugButton data={value} alwaysDisplay={true} />
                                    </div>;
                                }, sortable: true
                            },

                        ]}

                        data={thing.cartItems}
                    />
                </div>
                <div className="col-12">
                    <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
                        {thing.gatewayLog && thing.gatewayLog.map((log, index) => {
                            return <Tab eventKey={`home_${index}`} title={`${index} [${log.t}] ${log.t}`}>
                                <div className="text-left">
                                    <JSONPretty json={log.d} />
                                    <DateDisplay date={log.ts} />
                                </div>
                            </Tab>
                        })}

                    </Tabs>
                </div>
            </div>


            <div className="col-12">
                <AdminCheckoutStockLocations checkout={thing} setCheckout={setThing} />
                <AdminCheckoutShipping checkout={thing} setCheckout={setThing} />
            </div>

            <div className="col-12">
                Profit = {thing.totalProfitOfItems}
            </div>
            <div className="col-12">
                <h2>Payment Gateway</h2>

                <JSONPretty json={thing.gatewayData} />

            </div>
            <div className="text-left">
                <JSONPretty json={thing} />

            </div>

            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminCheckout;