import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { withGoogleReCaptcha } from 'react-google-recaptcha-v3';
import 'react-html5-camera-photo/build/css/index.css';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { RequestNewImage } from '../../../api/image-requests';
import { NotificationContext } from '../../../context/notifications';
import { IImage } from '../../../interface/image';
import { ISession } from '../../../interface/session';
import { composeValidators, required } from '../FormStuff/validators';
import JsCamera from './Camera';




interface IUploadImagesProp {
  attachTo: string; // vault, gallery, product, registration 
  attachId: string;
  aspect?: any;
  tag?: string;
  showOverRide?: boolean;
  hideButton?: boolean;
  save: (data: any) => void;
  input?: any;
  session?: ISession;
  remoteActivate?: (meta: any) => void;
}

interface IUploadImagesProp {
  attachTo: string; // vault, gallery, product, registration 
  attachId: string;
  aspect?: any;
  tag?: string;
  showOverRide?: boolean;
  hideButton?: boolean;
  save: (data: any) => void;
  input?: any;
  session?: ISession;
  remoteActivate?: (meta: any) => void;
}
class UploadImages extends React.Component<any, any> {

  context!: React.ContextType<typeof NotificationContext>
  private myRef: any;

  constructor(props: any) {
    super(props);
    console.log("________________________________________________________", props);
    this.myRef = React.createRef();

    let aspect: any = { aspect: 1 / 1 };
    if (props.aspect === 'none') {
      //this.setState({ crop: { aspect: props.aspect } })
      aspect = {
        unit: '%', // default, can be 'px' or '%'
        x: 0,
        y: 0,
        width: 100,
        height: 100
      };
    } else if (props.aspect) {
      console.log(props.aspect)
      aspect = { aspect: props.aspect }
    }

    const cameraBool = props.camera !== undefined ? props.camera : true;
    const cropperBool = props.aspect === 'none' ? false : true;

    this.state = {
      cropper: cropperBool,
      camera: cameraBool,
      cameraError: false,
      display: false,
      baseImage: '',
      src: '',
      blob: '',
      height: 0,
      width: 0,
      croppedImageUrl: '',
      crop: aspect
    }

    //console.log(this.props);
  }



  imageRef: any;
  fileUrl: any;

  onSelectFile = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader()
      reader.addEventListener(
        'load',
        () =>
          this.setState({
            src: reader.result, camera: false
          }),
        false
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }
  handleTakePhoto(dataUri: any) {
    // Do stuff with the photo...
    console.log('takePhoto');
    this.setState({
      src: dataUri, camera: false
    })

  }
  onImageLoaded = (image: any) => {
    this.imageRef = image;
    console.log(image.width, image.height);

    if (this.props.aspect === 'none') {
      this.setState({ crop: { width: image.width, height: image.height }, width: image.width, height: image.height });
    } else {
      this.setState({ width: image.width, height: image.height });
    }
    // console.log('onImageLoaded', image);

  }

  onCropComplete = (crop: any) => {
    console.log('onCropComplete', crop)
    this.makeClientCrop(crop);
  }

  onCropChange = (crop: any) => {
    this.setState({ crop })
  }

  async makeClientCrop(crop: any) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  }


  getCroppedImg(image: any, crop: any, fileName: any) {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx: any = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    return new Promise((resolve, reject) => {
      canvas.toBlob((blob: any) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error('Canvas is empty');
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () =>
            this.setState({
              blob: reader.result,
            }),
          false
        )
        reader.readAsDataURL(blob)
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, 'image/jpeg');
    });
  }


  handleCameraError(error: any) {

    this.setState({ cameraError: true })
  }

  render() {
    const { cameraError, camera, display, crop, croppedImageUrl, src, blob } = this.state;
    let actualDisplay: boolean = display;
    if (this.props.showOverRide) {
      actualDisplay = this.props.showOverRide;
    }
    const handleClose = () => this.setState({ display: false });
    const onSubmit = (data: any) => {
      const tag: string = (this.props.tag ? this.props.tag : '')
      let image: IImage = {
        mapMeta: {},
        page: 0,
        mapRegions: [],
        requestId: '',
        assignToType: this.props.attachTo,
        assignToId: this.props.attachId,
        id: 'new',
        name: data.name,
        description: data.description,
        tag: tag,
        url: '',
        ourl: '',
        path: '',
        width: this.state.width,
        height: this.state.height,
        originalPath: '',
        assigned: false,
        assignedTo: [],
        recapture: '',
        otherAssignments: []
      }

      image.crop = crop;
      // image.croppedSrcData = blob;
      image.originalSrcData = src;
      if (this.context) {
        const { addNotification } = this.context;
        this.props.googleReCaptchaProps.executeRecaptcha('upload_images').then((recapture: string) => {
          console.log("recapture", recapture);
          image.recapture = recapture;
          RequestNewImage(addNotification, image).then(response => {
            response.croppedSrcData = blob;
            console.log(this.props)
            if (this.props.onChange) {
              this.props.onChange(response)
            } else if (this.props.input && this.props.input.onChange) {
              this.props.input.onChange(response)
            }
            else if (this.props.save) {
              this.props.save(response);
            }


            if (this.props.onChanged) {
              this.props.onChanged(response)
            } else if (this.props.input && this.props.input.onChanged) {
              this.props.input.onChanged(response)
            }


            this.setState({
              camera: false,
              cameraError: false,
              display: false,
              baseImage: '',
              src: '',
              blob: '',
              croppedImageUrl: '',
              crop: {
                aspect: 1 / 1
              }
            })
            handleClose();
          })
        })

      } else {
        alert("system error contact support")
      }



    }

    return (<>

      <Modal size="xl" show={actualDisplay} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="row">
          <div className="col-sm-12 col-lg-6">
            <div>
              <input id={`inp_${this.props.name ? this.props.name : ''}_File`} className="e2e_File" type="file" accept="image/*" onChange={this.onSelectFile} />
              {/* {JSON.stringify(camera)} */}
              {cameraError === false && <>
                {camera === true ? <>
                  <JsCamera
                    onTakePhoto={(dataUri: any) => { this.handleTakePhoto(dataUri); }}
                    onCameraError={(error: any) => { this.handleCameraError(error); }}
                  />
                  {/* <Camera
                    onTakePhoto={(dataUri: any) => { this.handleTakePhoto(dataUri); }}
                    onCameraError={(error: any) => { this.handleCameraError(error); }}
                  />
                   */}
                </> : <>{this.props.camera !== false && <button type="button" onClick={(ev: React.MouseEvent) => {
                  ev.preventDefault();
                  this.setState({ camera: true })
                }}>Show Camera</button>}</>
                }</>}

            </div>
            {src && (
              <ReactCrop
                src={src}
                crop={crop}
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />
            )}
          </div>

          {src && <div className="col-sm-12 col-lg-6">
            <Form
              initialValues={{ name: 'name', session: this.props.session, confirm: true }}
              onSubmit={onSubmit}

              render={({ handleSubmit, values, form }) => (
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12">
                      <h4>Cropped Image</h4>
                      {croppedImageUrl && (
                        <img alt="Crop" className="img-fluid w-90" src={croppedImageUrl} />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Field name="name" validate={composeValidators(required)} >
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>Name</label>
                            <input id="imageName______" type="text" className="form-control" {...input} placeholder="Name" />
                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-12">
                      <Field name="description">

                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>Description</label>
                            <textarea type="text" className="form-control" {...input} placeholder="Name" />
                            {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <Field
                          name="confirm"
                          component="input"
                          type="checkbox"
                        />{' '}I legally own this image or i have the consent of the copyright owner of this image</div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">

                        {croppedImageUrl ? <button id={`btn_${this.props.name ? this.props.name : ''}_Submit`} type="submit" className="btn btn-success e2e_submit_image">Save</button> : <p className="bg-warning">Please crop image</p>}
                      </div>
                    </div>
                  </div>
                </form>


              )} />
          </div>}
        </div></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {display === false && <>{this.props.hideButton !== true && <Button
        className='focus'
        tabIndex={-1}
        id={`btn_${this.props.name ? this.props.name : ''}_addImage`}
        onClick={(ev: React.MouseEvent) => {
          ev.preventDefault();
          this.setState({ display: true })
        }}>{this.props.text ? this.props.text : 'Add Images'}</Button>}</>}
    </>);
  }
}



export default withGoogleReCaptcha(UploadImages);