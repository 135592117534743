import React, { useState } from "react";
import { IProduct } from "../interface/product";
import AddToCartButton from "./AddToCartButton";

interface IProductsProd {
  products: any[];

  onDelete?: (thing: any) => void;
}

const Products = ({ products, onDelete }: IProductsProd) => {
  const [mode] = useState<string>('table');
  //let match = useRouteMatch();

  switch (mode) {
    case 'table':
      return (<>
        <table className="table">
          <thead>
            <tr>
              <th scope="col" style={{ "width": "20%" }}>#</th>
              <th scope="col" style={{ "width": "30%" }}>Picture</th>
              <th scope="col" style={{ "width": "30%" }}>Part Number</th>
              <th scope="col" style={{ "width": "20%" }}>Price</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product: IProduct, index: number) => {
              return <tr key={index}>
                <th scope="row">{index}</th>
                <td>{product.image && <img src={`${product.image.url}`} alt="" className="img-fluid w-75" />}</td>
                <td>{product.partNumber}</td>
                <td>
                  <AddToCartButton data={product} showLink={true} />
                  <br />
                </td >

              </tr >
            })}

          </tbody >
        </table >
      </>);
    case 'col':
      return (<>

      </>);

    default: {
      return <></>;
    }
  }

}

export default Products;