import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useLocation } from "react-router";
import { Link } from 'react-router-dom';
import { RequestRegister } from '../../api/auth-requests';
import DebugButton from "../../components/system/DebugButton";
import { composeValidators, mustBeEmail, required } from '../../components/system/FormStuff/validators';
import Loading from "../../components/system/loading";
import { ENotificationType, useNotifications } from '../../context/notifications';

const RegisterBuyerForm = (props: any) => {
    const { addNotification } = useNotifications()!
    let [loading, setLoading] = useState<boolean>(false);
    const [finished, setFinished] = useState<boolean>(false);
    let [response, setResponse] = useState<any>();
    // const [other, setOther] = useState<any>({});
    // const [startedLoading, setStartedLoading] = useState<boolean>(false);
    // const [countries, setCo] = useState<ICheckoutCountry[]>();
    const { executeRecaptcha } = useGoogleReCaptcha();
    let location = useLocation();

    useEffect(() => {
        //console.log("get product types");
        //  const query = qs.parse(props.location.search.substring(1));
        //setQuery({ name: query.cat });
        setLoading(false)
    }, [props])
    return (<>
        {loading === true && <Loading />}
        {finished === true ? <>thanks for your message, we will contact you shortly</> : <Form
            onSubmit={(data: any) => {
                console.log(data);
                executeRecaptcha && executeRecaptcha('register').then((recapture: string) => {
                    data.recapture = recapture;
                    RequestRegister(addNotification, data).then(reg => {
                        console.log(reg);
                        setResponse(reg);

                        if (reg.id) {
                            setFinished(true)
                        } else {

                        }
                    }).catch(ez => {
                        addNotification && addNotification('Error', 'Username / Email Exists please try again', ENotificationType.Warning);
                    });
                });
            }}
            validate={(values: any) => {
                const errors: any = {};
                if (values.agree !== true) {

                    errors.agree = 'you must agree to terms'
                }
                return errors;
            }}
            render={({ handleSubmit, form, submitting, pristine, values, errors }) => {

                return <form onSubmit={handleSubmit}>

                    <div className="p-4">
                        <h2>Registration Form</h2><DebugButton data={values} />
                    </div>

                    <div className="card cardrequest">
                        <div className="card-body">
                            <div className="p-2">
                                <Field name="firstName" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div className="input-container">
                                            <label className={` ${input.value !== '' && 'filled'}`}>First Name:</label>
                                            <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="p-2">
                                <Field name="lastName" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div className="input-container">
                                            <label className={` ${input.value !== '' && 'filled'}`}>Last Name:</label>
                                            <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="p-2">
                                <Field name="phone" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div className="input-container">
                                            <label className={` ${input.value !== '' && 'filled'}`}>Mobile Phone:</label>
                                            <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="p-2">
                                <Field name="email" validate={composeValidators(required, mustBeEmail)}>
                                    {({ input, meta }) => (
                                        <div className="input-container">
                                            <label className={` ${input.value !== '' && 'filled'}`}>E-Mail:</label>
                                            <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className="p-2">
                                <Field name="username" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div className="input-container">
                                            <label className={` ${input.value !== '' && 'filled'}`}>Username:</label>
                                            <input type="text" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                        </div>
                                    )}
                                </Field>
                            </div>
                            <div className="p-2">
                                <Field name="password" validate={composeValidators(required)}>
                                    {({ input, meta }) => (
                                        <div className="input-container">
                                            <label className={` ${input.value !== '' && 'filled'}`}>Password:</label>
                                            <input type="password" className={`form-control ${meta.touched && meta.error && 'form-error'}`} {...input} placeholder="" />
                                        </div>
                                    )}
                                </Field>
                            </div>
                        </div></div>

                    <div className="p-4 pull-left">
                        <div className="row">
                            <div className="col-1 flex0">
                                <Field
                                    name="agree"
                                    component="input"
                                    type="checkbox"
                                    className=""
                                    id="agree"
                                />
                            </div>
                            <div className="col-10">
                                <label htmlFor="agree" className="pr-3"> By clicking here you are agreeing to our <Link to="/terms">Terms and Conditions</Link>
                                    {errors && errors['agree'] && <span className="text-danger paddingquantity">(required)</span>}</label>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-1 flex0">
                                <Field
                                    name="marketing"
                                    component="input"
                                    type="checkbox"
                                    // value={true}
                                    className=""
                                    id="marketing"
                                />
                            </div>
                            <div className="col-10">
                                <label htmlFor="marketing" className="pr-3">Click here to opt-in our Mailing List</label>
                            </div>
                        </div>
                    </div>


                    <div className="button-group p-4">
                        <button className="signin-button buttonfix" type="submit">REGISTER</button>
                    </div>

                </form>
            }} />}

    </>);
}

export default RegisterBuyerForm;