import arrayMutators from 'final-form-arrays';
import React from "react";
import { Field, Form } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { promiseOptionsProductCategory, promiseOptionsProductManufacturer } from './promises';
import { BasicAsyncSelectAdapter } from './system/FormStuff/ReactSelect';


interface IAdminProductsSearchForm {
    onSubmit: (data: any) => void;
    search: any;
    promiseOptionsStore: (v: string) => Promise<any>;
    setRedirect: (d: boolean) => void;
    setRedirectTo: (d: string) => void;
    setSearch: (d: any) => void;
}

const AdminProductsSearchForm = ({ onSubmit, search, promiseOptionsStore, setRedirect, setRedirectTo, setSearch }: IAdminProductsSearchForm) => {

    return <Form <any>

        onSubmit={onSubmit}

        initialValues={search}
        mutators={{
            // expect (field, value) args from the mutator
            ...arrayMutators,
            setValue: ([field, value], state, { changeValue }) => {
                changeValue(state, field, () => value)
            }
        }}
        render={({ handleSubmit, form, submitting, pristine, values }) => {


            return <form onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-sm-12 col-lg-3'>
                        Part Number
                        <Field
                            name="partNumber"
                            component={BasicAsyncSelectAdapter}
                            inputId={'partNumber'}
                            promiseOptions={promiseOptionsStore}
                        />
                        <OnChange name='partNumber'>
                            {(value, previous) => {

                                if (value !== previous) {
                                    setRedirect(true);
                                    setRedirectTo(`/admin/products/${value.value}`)
                                }
                            }}
                        </OnChange>
                    </div>
                    <div className='col-sm-12 col-lg-3'>
                        Category
                        <Field name="_productCategoryId"
                            title="Category" component={BasicAsyncSelectAdapter}
                            promiseOptions={promiseOptionsProductCategory}
                            inputId={"productCategoryId"}
                            description="What is this item"
                        />
                        <OnChange name='_productCategoryId'>
                            {(value, previous) => {

                                if (value !== previous) {
                                    handleSubmit()
                                }
                            }}
                        </OnChange>
                    </div>
                    <div className='col-sm-12 col-lg-3'>
                        Manufacturer
                        <Field name="_productManufacturerId"
                            title="Part Manufacturer"
                            component={BasicAsyncSelectAdapter}
                            promiseOptions={promiseOptionsProductManufacturer}
                            description="Whom made this"
                            inputId={"productManufacturerId"}
                        />

                        <OnChange name='_productManufacturerId'>
                            {(value, previous) => {

                                if (value !== previous) {
                                    handleSubmit()
                                }
                            }}
                        </OnChange>
                    </div>
                    <div className='col-sm-12 col-lg-2'>
                        description
                        <Field
                            name={`_description`}

                            component='input'

                            type='text'
                            className="form-control"
                            id="description"
                        />


                        <OnChange name='_description'>
                            {(value, previous) => {

                                if (value !== previous) {
                                    handleSubmit()
                                }
                            }}
                        </OnChange>
                    </div>
                    <div className='col-sm-12 col-lg-1'>
                        <br />
                        <button type="button" className='btn btn-warning' onClick={() => {
                            setSearch({})
                        }}>Reset</button>

                    </div>
                </div>
            </form>

        }} />

}

export default AdminProductsSearchForm
