import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { RequestNewLog } from '../../api/log-requests';
import { ELogType, ESeverity } from '../../interface/log';
const ErrorHandler = (error: Error, info: { componentStack: string }) => {
    // Do something with the error
    // E.g. log to an error logging client here

    console.log("ErrorHandler", error, info)

    RequestNewLog(undefined, {

        type: ELogType.reactException,
        requestId: '',
        sessionId: '',
        userId: '',
        payload: { error: error, info: info },
        severity: ESeverity.Issue,
        text: 'react exception error'
    })

}
const ErrorFallback = ({ error, resetErrorBoundary }: any) => {
    return (
        <div role="alert">
            <p>Something went wrong:</p>
            {!process.env.NODE_ENV || process.env.NODE_ENV === 'development' && <pre>{error.message}</pre>}
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
}


interface IEBoundaryProps {
    title: string
}

const EBoundary: React.FunctionComponent<any> = props => {
    return <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={ErrorHandler}
        onReset={() => {
            // reset the state of your app so the error doesn't happen again
        }}
    >
        {props.children}
    </ErrorBoundary>
}

export default EBoundary;