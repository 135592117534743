import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { ICheckoutItem } from "../interface/checkout";
import { IProduct } from "../interface/product";
import { ENotificationType, useNotifications } from "./notifications";
const defaultCart: ICheckoutItem[] = []
type CartContextType = {
  items: ICheckoutItem[];
  addToCart: (data: IProduct, qty?: number) => void;
  remove: (item: ICheckoutItem) => void;
  lessQty: (item: ICheckoutItem) => void;
  moreQty: (item: ICheckoutItem) => void;
  clearCart: () => void;
};
const CartContext = React.createContext<CartContextType | undefined>(
  undefined
);
type Props = {
  children: React.ReactNode
};
export const CartProvider = ({
  children
}: Props) => {

  // attempt to load cart from Local store
  const { addNotification } = useNotifications()!;

  const [items, setItems] = React.useState<ICheckoutItem[]>(
    defaultCart
  );


  const saveCartItems = (value: ICheckoutItem[]) => {

    localStorage.setItem('cart', JSON.stringify(value));
    setItems(value);
  }

  const remove = (item: ICheckoutItem) => {
    let cartA: ICheckoutItem[] = [...items]

    const index = cartA.findIndex(line => line.id === item.id);
    cartA.splice(index, 1);
    saveCartItems(cartA);

  }

  const lessQty = (item: ICheckoutItem) => {
    let cartA: ICheckoutItem[] = [...items]

    const index = cartA.findIndex(line => line.id === item.id);
    let selectedItem = cartA[index];
    selectedItem.qty--
    if (selectedItem.qty === 0) {
      cartA.splice(index, 1);
    } else {
      cartA[index] = selectedItem;

    }
    addNotification && addNotification('Shopping Cart', 'updated  "' + item.partNumber + '"', ENotificationType.Success);
    saveCartItems(cartA);

  }

  const moreQty = (item: ICheckoutItem) => {
    let cartA: ICheckoutItem[] = [...items]

    const index = cartA.findIndex(line => line.id === item.id);
    let selectedItem = cartA[index];
    selectedItem.qty++;
    selectedItem.itemTotal = selectedItem.qty * selectedItem.price;
    selectedItem.weightTotal = selectedItem.qty * selectedItem.weight;
    if (item.stock && selectedItem.qty > item.stock) {
      cartA.splice(index, 1);
      addNotification && addNotification('Shopping Cart', 'failed to add "' + item.partNumber + '" not enough stock, please make a request from our request page', ENotificationType.Danger);

    } else {
      cartA[index] = selectedItem;
      addNotification && addNotification('Shopping Cart', 'updated  "' + item.partNumber + '"', ENotificationType.Success);
      saveCartItems(cartA);
    }


  }
  const clearCart = () => {
    let cartA: ICheckoutItem[] = [];


    saveCartItems(cartA);


  }
  const addToCart = (data: IProduct, qty?: number) => {
    if (!qty) qty = 1;
    console.log("addToCart", data, qty)
    let cartA: ICheckoutItem[] = [...items]



    const index = cartA.findIndex(line => line.productId === data.id);


    if (index === -1) { //check if already in cart
      // add to cart 
      let url = '';
      if (data.image) {
        url = data.image.url;
      }
      if (data.stock && data.newSalePrice && data.stock) {
        if (qty <= data.stock) {
          const cartProduct: ICheckoutItem = {
            id: uuidv4(),
            partNumber: data.partNumber,
            productDescription: data.partNumber,
            stock: data.stock,
            productId: data.id,
            price: data.newSalePrice,
            qty: qty,
            weight: data.weight,
            image: data.image,
            itemTotal: data.newSalePrice,
            weightTotal: data.weight
          }
          cartA.push(cartProduct);
          addNotification && addNotification('Shopping Cart', 'added "' + data.partNumber + '"', ENotificationType.Success);
        } else {
          addNotification && addNotification('Shopping Cart', 'failed to add "' + data.partNumber + '" not enough stock, please make a request from our request page', ENotificationType.Danger);

        }

      }


    } else {
      const toBeStock: number = cartA[index].qty + qty
      //cartA[index].qty = cartA[index].qty+1
      if (data.stock) {
        if (toBeStock <= data.stock) {
          cartA[index].qty = toBeStock;
          cartA[index].itemTotal = toBeStock * cartA[index].price;
          cartA[index].weightTotal = toBeStock * cartA[index].weight;
          addNotification && addNotification('Shopping Cart', 'updated cart ' + data.partNumber + " X " + toBeStock, ENotificationType.Success);

        } else {
          addNotification && addNotification('Shopping Cart', 'failed to update qty of "' + data.partNumber + '" not enough stock, please make a request from our request page', ENotificationType.Danger);

        }
      }


    }
    saveCartItems(cartA);

  }
  React.useEffect(() => {
    // We'd get the theme from a web API / local storage in a real app
    // We've hardcoded the theme in our example
    // const currentTheme = "lightblue";
    // setCart(currentTheme);

    const localStorageStr = localStorage.getItem('cart');
    let initCart: ICheckoutItem[] = [];
    if (localStorageStr) {
      initCart = JSON.parse(localStorageStr);
    }
    setItems(initCart);


  }, []);

  return (
    <CartContext.Provider value={{ items, addToCart, remove, lessQty, moreQty, clearCart }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () =>
  React.useContext(CartContext);