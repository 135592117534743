import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useCart } from '../../context/cart';
import { ICheckoutItem } from '../../interface/checkout';


const CartDisplay = () => {
    const { items, remove, lessQty, moreQty } = useCart()!;
    return (<>

        <div className="col-6">

            {items.map((item: ICheckoutItem, index: number) => {
                return <div className="col-12" key={index}>
                    <ButtonGroup>
                        <Button style={{ width: "5%" }} onClick={(ev: React.MouseEvent) => {
                            ev.preventDefault();
                            remove(item)
                        }} variant="danger"><FontAwesomeIcon icon={faTrashAlt} /></Button>

                        <Button variant="primary" style={{ width: "15%" }} onClick={(ev: React.MouseEvent) => {
                            ev.preventDefault();
                            lessQty(item)
                        }}>-</Button>

                        <Link style={{ width: "55%" }} to="/cart" className="btn btn-link">{item.partNumber} ({item.qty}) £{(item.price * item.qty).toFixed(2)}</Link>

                        <Button style={{ width: "15%" }} onClick={(ev: React.MouseEvent) => {
                            ev.preventDefault();
                            moreQty(item)
                        }}>+</Button>
                    </ButtonGroup>
                </div>
            })}

        </div>
    </>
    );
}

export default CartDisplay;