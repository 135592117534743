import { IStock } from "../interface/stock";
import axiosInstance from "./instance";

export const RequestGetStocks = (addNotification: any, data?: any): Promise<IStock[]> => {
    return new Promise<IStock[]>((resolve, reject) => {
        let url = `/stocks/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetStock = (addNotification: any, id: string): Promise<IStock> => {
    return new Promise<IStock>((resolve, reject) => {

        axiosInstance(addNotification).get(`/stocks/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewStock = (addNotification: any, thing: IStock): Promise<IStock> => {
    return new Promise<IStock>((resolve, reject) => {

        axiosInstance(addNotification).post(`/stocks/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteStock = (addNotification: any, thing: IStock): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/stocks/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateStock = (addNotification: any, user: IStock): Promise<IStock> => {
    return new Promise<IStock>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/stocks/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestStockFunction = (addNotification: any, func: string, id: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/stocks/_func/${func}/${id}`;

        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

export const RequestStockFunctionPost = (addNotification: any, func: string, data: any): Promise<any> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/stocks/_func/${func}`;

        axiosInstance(addNotification).post(url, data).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}
// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
