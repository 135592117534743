import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useRouteMatch } from 'react-router';
import { RequestGetProduct } from '../../api/product-requests';
import AddToCartButton from '../../components/AddToCartButton';
import DebugButton from '../../components/system/DebugButton';
import TextDisplay from '../../components/system/TextDisplay';
import { useAuth } from '../../context/auth';
import { useNotifications } from '../../context/notifications';
import { IProduct } from '../../interface/product';



const Product = () => {
    const { auth } = useAuth()!;
    const [loading, setLoading] = useState<boolean>(true);
    const { addNotification } = useNotifications()!;
    const [thing, setThing] = useState<IProduct>()
    const match: any = useRouteMatch("/product/:id");

    useEffect(() => {
        console.log("match", match);
        const id = match.params.id;
        if (thing === undefined) {
            RequestGetProduct(addNotification, id).then(dd => {
                console.log(dd)
                setThing(dd)
                setLoading(false)
            });
        }
    }, [match])
    return (
        <>

            {thing && <>
                <Helmet>
                    <title>{thing && thing.partNumber} / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
                </Helmet>
                <div className="row">

                    <div className="col-6">
                        <h2>{thing && thing.partNumber}</h2>
                        <h3>{thing && thing.productCategory && thing.productCategory.name}</h3>
                        <DebugButton data={thing} />
                        <br />
                        <div className="text-left">
                            <TextDisplay text={thing.description} />
                        </div>
                    </div>
                    <div className="col-6">
                        {thing.image && <img src={thing.image.url} className="img-fluid w-75" />}
                        <AddToCartButton data={thing} />
                        <div className="row">
                            {thing.imagesObj && thing.imagesObj.map((img, iindex) => {
                                if (img.id !== thing.imageId) {
                                    return <div className="col-6"><img key={`dsdi${iindex}`} src={img.url} className="img-fluid" /></div>
                                }
                            })}
                        </div>
                    </div>
                </div></>}

        </>
    );
}

export default Product;