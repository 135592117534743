

import React from 'react';
import { Route, Switch, useRouteMatch } from "react-router-dom";
import CartForm from "./CartForm";

const Checkout = () => {
  let match = useRouteMatch();


  // const { addNotification } = useNotifications()!;
  return (
    <>
      <div className="standarddiv">
        <h2>Checkout</h2>

        <Switch>
          <Route path={`${match.path}/complete`}>
            <>
              thanks for ordering you will get a confirmation email soon
            </>
          </Route>
          <Route path={`${match.path}`} exact>
            <CartForm />
          </Route>

        </Switch>

      </div>


    </>
  );
}

export default Checkout;