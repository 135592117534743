import axios, { AxiosInstance } from "axios";
import { ENotificationType } from "../context/notifications";
const AUTH_KEY:string = process.env.REACT_APP_AUTH_KEY ? process.env.REACT_APP_AUTH_KEY : 'auted'
const axiosInstance = (newNote:any):AxiosInstance => {
    const localStorageStr = localStorage.getItem(AUTH_KEY);
    console.log("ls", localStorageStr)
    let token: string = 'unset';
    if (localStorageStr) {
        token = JSON.parse(localStorageStr).token;
    }
    const instance:AxiosInstance = axios.create({
        baseURL: '/api',
        timeout: 20000,
        headers: { 'X-Custom-Header': token }
    });
    instance.interceptors.response.use((resp) => resp, (error) => {
        // whatever you want to do with the error
        console.log(error);
        newNote && newNote('Error', 'Api Error', ENotificationType.Danger)
      });
      
    return instance;
};

export default axiosInstance;