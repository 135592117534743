import React from 'react';
import { v4 as uuidv4 } from 'uuid';

export interface INotification{
  id:string;
  title:string;
  type: ENotificationType;
  payload: string;
}

export enum ENotificationType{
  "Primary"="text-white bg-primary",
  "Secondary"="text-white bg-secondary",
  "Success"="text-white bg-success",
  "Warning"="text-white bg-warning",
  "Danger"="text-white bg-danger",
}
const defaultT:INotification[] = [];

type NotificationContextType = {
  notifications: INotification[];
  addNotification?: (title: string, payload: string, type: ENotificationType) => void;
  remove?: (id:string) => void;
};
export const NotificationContext = React.createContext<NotificationContextType | undefined>({
  notifications:[]}
);

type Props = {
  children: React.ReactNode;
};
export const NotificationProvider = ({ children }: Props) => {
  const [notifications, setNotifications] = React.useState<INotification[]>(defaultT);

  const addNotification = (title: string, payload: string, type:ENotificationType)=>{
    let nnn = [...notifications];
    nnn.push({
      id:uuidv4(),
      title:title,
      type:type, 
      payload: payload,

    });
    setNotifications(nnn)
  }

  const remove = (id: string)=>{
    let nnn = [...notifications];
    const index = nnn.findIndex(dd=>dd.id === id);
    nnn.splice(index, 1);
    setNotifications(nnn)
  }


  React.useEffect(() => {
    // We'd get the Auth from a web API / local storage in a real app
    // We've hardcoded the Auth in our example
   // const currentAuth = "buye";
  //  setAuth(currentAuth);
  }, []);

  return (
    <NotificationContext.Provider value={{ notifications, addNotification, remove }}>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => React.useContext(NotificationContext);