import React from 'react';
import { Spinner } from 'react-bootstrap';


const Loading = ()=>{
    return <div className="loading_overlay">
          <Spinner animation="border" variant="primary" />

    </div>
}

export default Loading;