import React from 'react';
import { v4 as uuidv4 } from 'uuid';


type AdminSidebarContextType = {
  display: boolean;

  toggle?: () => void;
};
export const AdminSidebarContext = React.createContext<AdminSidebarContextType | undefined>({
  display:true}
);
type Props = {
  children: React.ReactNode;
};
export const AdminSidebarProvider = ({ children }: Props) => {
  const [display, setDisplay] = React.useState<boolean>(true);



  const toggle = ()=>{
   setDisplay(!display)
  }




  return (
    <AdminSidebarContext.Provider value={{ display, toggle }}>
      {children}
    </AdminSidebarContext.Provider>
  );
};

export const useAdminSidebars = () => React.useContext(AdminSidebarContext);