import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom';
import { RequestDeleteBuyin, RequestGetBuyins } from '../../../api/buyin-requests';
import { RequestNewLog } from '../../../api/log-requests';
import DataTable from '../../../components/system/DataTable';
import DebugButton from '../../../components/system/DebugButton';
import Loading from '../../../components/system/loading';
import { useAuth } from '../../../context/auth';
import { ENotificationType, useNotifications } from '../../../context/notifications';
import { IBuyin } from '../../../interface/buyin';
import { ELogType, ESeverity } from '../../../interface/log';
import AdminBuyin from './Admin.Buyin';


const AdminBuyinsPge = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [things, setThings] = useState<IBuyin[]>();
    let match = useRouteMatch();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;


    useEffect(() => {
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react stores onunload'
        });
    }
    // eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }

    // eslint-disable-next-line
    {/*<!-- OPTIONAL END -->*/ }



    useEffect(() => {
        try {
            if (loading === true) {
                // eslint-disable-next-line
                {/*<!-- OPTIONAL 2 -->*/ }
                RequestGetBuyins(addNotification).then(thing => {
                    //   console.log(stores)
                    setThings(thing);
                    setLoading(false);
                })
                // eslint-disable-next-line
                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Buyins - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }

    }, [loading, addNotification]);

    const onDelete = (value: IBuyin) => {
        try {

            const conf = window.confirm("Delete?");
            if (conf) {
                setLoading(true);
                RequestDeleteBuyin(addNotification, value).then(res => {
                    //  console.log(res);
                    let thingsD: IBuyin[] = [];
                    if (things) {
                        thingsD = [...things];
                    }
                    const index = thingsD.findIndex(thing => thing._id === value._id);
                    thingsD.splice(index, 1);

                    setThings(thingsD);
                    setLoading(false);
                    addNotification && addNotification('Success', 'Buyin Deleted!', ENotificationType.Primary);
                });
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Buyins - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }



    return (<>
        {loading === true && <Loading />}
        {things && <div className="col-12">
            <h2>Buyins Admin</h2>
            <p className="bg-primary">this is the list of all purchases in to the system like orders but opposite.</p>

            <Helmet>
                <title>Buyins / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>

            </Helmet>

            <Link className="btn btn-success" to="/admin/buyIn">New</Link>
            {/*<!-- OPTIONAL 3 -->*/}
            <DataTable

                colHeadings={[
                    { name: "Name", field: "name", sortable: false },
                    { name: "totalPurchaseValue", field: "totalPurchaseValue", sortable: true },
                    {
                        name: "Actions", field: "id", r: (value: any, index) => {
                            return <div className="btn-group">
                                <DebugButton data={value} />
                                <Link className="btn btn-primary" to={`${match.url}/${value.id}`}>Edit</Link>
                                <button className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                    ev.preventDefault();
                                    onDelete(value);
                                }}>Delete</button>
                            </div>;
                        }, sortable: false
                    },

                ]}

                data={things}
            />

            {/*<!-- OPTIONAL END -->*/}
        </div>}
    </>
    );
};
const AdminBuyins = () => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            <Route path={`${match.path}/:id`}>
                <AdminBuyin />
            </Route>
            <Route path={`${match.path}`} exact>
                <AdminBuyinsPge />
            </Route>

        </Switch>
    </>
    );
}

export default AdminBuyins;