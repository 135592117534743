import { IStore } from "../interface/store";
import axiosInstance from "./instance";

export const RequestGetStores = (addNotification: any, data?: any): Promise<IStore[]> => {
    return new Promise<IStore[]>((resolve, reject) => {
        let url = `/stores/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetStore = (addNotification: any, id: string): Promise<IStore> => {
    return new Promise<IStore>((resolve, reject) => {

        axiosInstance(addNotification).get(`/stores/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewStore = (addNotification: any, thing: IStore): Promise<IStore> => {
    return new Promise<IStore>((resolve, reject) => {

        axiosInstance(addNotification).post(`/stores/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteStore = (addNotification: any, thing: IStore): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/stores/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateStore = (addNotification: any, user: IStore): Promise<IStore> => {
    return new Promise<IStore>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/stores/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}



/*

export const RequestInstallationLoopFunction = (addNotification: any, func: string, id: string): Promise<any> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/installationLoops/_func/${func}/${id}`;
        console.log(":S")
        axiosInstance(addNotification).get(url).then(response => {
            console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

*/
export const RequestStoreFunction = (addNotification: any, func: string, query: any): Promise<any> => {

    return new Promise<any[]>((resolve, reject) => {

        let url = `/stores/_func/${func}`;

        axiosInstance(addNotification).post(url, query).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
{/*<!-- OPTIONAL 1 -->*/ }

// eslint-disable-next-line
{/*<!-- OPTIONAL END -->*/ }
