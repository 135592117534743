import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { useAuth } from '../context/auth';



const Home = () => {
    const { auth } = useAuth()!;
    return (
        <div>
            <Helmet>
                <title>Home / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>
            <div className="mainpage" id="homePage">
                <Carousel>
                    <Carousel.Item interval={2000}>
                        <img src="/slider1.jpg" className="card-img-top" alt="..."></img>

                        <Carousel.Caption>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="/slider2.jpg" className="card-img-top" alt="..."></img>
                        <Carousel.Caption>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img src="/slider3.jpg" className="card-img-top" alt="..."></img>
                        <Carousel.Caption>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="container">
                <hr className="gold" />
                <div className="row">
                    <div className="col-sm">
                        <div className="card cardfix">
                            <img src="/newteam.jpg" className="card-img-top" alt="..."></img>

                            <div className="card-body">
                                <h5 className="card-title">our catalogue</h5>
                                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.Some quick example text to build on the card title and make up the bulk of the card's content</p>
                                <div className="right">
                                    <a href="#" className="signin-button p-2">Discover</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="card cardfix">
                            <img src="/request.jpg" className="card-img-top" alt="..."></img>
                            <div className="card-body">
                                <h5 className="card-title">missing parts?</h5>
                                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content. Some quick example text to build on the card title and make up the bulk of the card's content</p>
                                <div className="right">
                                    <a href="#" className="signin-button p-2">Discover</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm">
                        <div className="card cardfix">
                            <img src="/stunt1contactus.jpg" className="card-img-top" alt="..."></img>
                            <div className="card-body">
                                <h5 className="card-title">get in touch</h5>
                                <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.Some quick example text to build on the card title and make up the bulk of the card's content</p>
                                <div className="right">
                                    <a href="#" className="signin-button p-2">Discover</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="gold" />

                <div>
                    <Carousel>
                        <Carousel.Item interval={2000}>
                            <img src="/promo1.jpg" className="card-img-top" alt="..."></img>
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>
                <div className="bottomfix">
                </div>
            </div>
        </div>


    );
}

export default Home;