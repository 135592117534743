import arrayMutators from "final-form-arrays";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { Helmet } from "react-helmet";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { v4 as uuid } from 'uuid';
import { RequestGetCollections, RequestNewCollectionBackup } from "../../../api/collections-requests";
import DebugButton from "../../../components/system/DebugButton";
import Loading from "../../../components/system/loading";
import { useAuth } from "../../../context/auth";
import { useNotifications } from "../../../context/notifications";
import { useSocket } from "../../../context/socket";





const att: any[] = [];
const formatBytes = (bytes: number, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const AdminCollection = ({ types }: any) => {
    //   let match = useRouteMatch();
    // let params = useParams();
    const [started, setStarted] = useState(false);
    const { addNotification } = useNotifications()!;

    const [loading, setLoading] = useState<boolean>(true);


    const [things, setThings] = useState<any[]>();
    const [startTime, setStart] = useState<Date>();
    const [endTime, setEnd] = useState<Date>();
    const [imports, setImports] = useState<any[]>();
    const { auth } = useAuth()!;
    // const { setPageTitle } = usePageTitle()!;
    // useEffect(() => {

    //     setPageTitle && setPageTitle('System', 'admin');

    // }, [])

    const [result, setResult] = useState<any>();
    useEffect(() => {
        if (started === false) {
            setStarted(true);

            RequestGetCollections(addNotification, {}).then((collections: any) => {
                console.log(collections);
                setThings(collections.collections.map((sss: any) => {
                    let object = { name: sss.name, backup: true };
                    if (sss.name === 'logs' || sss.name === 'agendaJobs') {
                        object.backup = false;
                    }
                    return object;
                }));

                setImports(collections.import)
                setLoading(false);
            })
        }
    }, [])
    const doBackup = (dd: any) => {
        RequestNewCollectionBackup(addNotification, { backup: dd.d, title: dd.title, id: uuid() }).then(s => {
            console.log("doingbackup", dd);
            setResult(s)
            setStart(new Date(Date.now()))
        })
    }
    const doRestore = (dd: any) => {
        RequestNewCollectionBackup(addNotification, { restore: dd.file, id: uuid() }).then(s => {
            console.log("dddd", dd);
            setResult(s)
            setStart(new Date(Date.now()))
        })
    }

    // const [debugs, setDebugs] = useState<any[]>([]);

    const dddd = (something: any) => {
        console.log('something', something, att)
        //   debugs.push(something)

        if (something.at === 'complete') {
            setEnd(new Date(Date.now()))
        }
        const d = { ...result }
        if (something.at === 'gzCommand') {
            d.gzCommand = something.gzCommand;
            setResult(d)
        }
        att.push(something)
        //setDebugs([...debugs]);
    }


    const socket = useSocket();
    useEffect(() => {
        // as soon as the component is mounted, do the following tasks:

        // emit USER_ONLINE event
        //     socket && socket.emit("USER_ONLINE", userId);

        // subscribe to socket events
        if (socket !== undefined) {

            socket.socket && socket.socket.on("syscollection_progress", dddd);
        }
        return () => {
            // before the component is destroyed
            // unbind all event handlers used in this component
            //   socket.off("JOIN_REQUEST_ACCEPTED", handleInviteAccepted);

            if (socket !== undefined) {

                socket.socket && socket.socket.off("syscollection_progress", dddd);
            }
        };
    }, [socket]);

    let duration = '';
    if (endTime && startTime) {
        duration = moment.duration(moment(endTime).diff(moment(startTime))).humanize()
    }





    return (<>

        {(loading === false && things) ? <>
            <Helmet>
                <title>Pipe / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>



            {result === undefined ? <>
                <h2>Export</h2><Form <any>
                    onSubmit={doBackup}
                    initialValues={{ d: things }}
                    mutators={{
                        ...arrayMutators,
                    }}
                    render={({ handleSubmit, form, submitting, pristine, values }) => {
                        //do if altered browse notifcation
                        //  console.log(submitting, pristine)

                        return <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-12">
                                    <button type="button" className="btn btn-danger" onClick={(ev: React.MouseEvent) => {
                                        ev.preventDefault();
                                        const d = values.d.map((ddd: any) => {
                                            ddd.backup = false;
                                            return ddd;
                                        })

                                        form.change('d', d)
                                    }}>Deselect All</button>

                                    <DebugButton data={values} />
                                    <FieldArray name="d">
                                        {({ fields }) => (<>
                                            <div className="row">
                                                {fields.map((name, index) => (
                                                    <div key={name} className="col-6">
                                                        {things[index].name}


                                                        <Field component="input" type="checkbox" name={`${name}.backup`} />
                                                    </div>
                                                ))}
                                            </div>
                                        </>)}
                                    </FieldArray>
                                    <Field name="title">
                                        {({ input, meta }) => (
                                            <div>
                                                <label>Title</label>
                                                <input type="text" className="form-control" {...input} placeholder="...." />
                                                {meta.touched && meta.error && <span className="bg-warning">{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <button className="btn btn-primary" type="submit">Save</button>
                                </div>

                            </div>

                        </form>

                    }} />
                <h2>Import</h2>
                {imports && imports.map((ddd, index) => {
                    return <button key={index} className="btn btn-info" type="button" onClick={(ev: React.MouseEvent) => {
                        ev.preventDefault();
                        doRestore(ddd)
                    }}>{ddd.file} [{formatBytes(ddd.size)}]</button>
                })}

            </> : <div className="text-left row">
                <Redirect to="/admin/backupMonitor" />
            </div>}

        </> : <Loading />}
    </>
    );
}


const AdminSystemCollectionsPage = () => {
    let match = useRouteMatch();
    return (<>
        <Switch>
            {/* <Route path={`${match.path}/:id`}>
                <AdminLog />
            </Route> */}
            <Route path={`${match.path}`} exact>
                <AdminCollection />
            </Route>

        </Switch>
    </>
    );
}





export default AdminSystemCollectionsPage;





