import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Prompt, Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { RequestGetContact, RequestNewContact, RequestNewContactResponse, RequestSetContactStatus, RequestUpdateContact } from "../../../api/contact-requests";
import { RequestNewLog } from "../../../api/log-requests";
import { RequestGetProducts } from "../../../api/product-requests";
import ConversationDisplay from "../../../components/system/ContactsStuff/ConversationDisplay";
import DebugButton from "../../../components/system/DebugButton";
import Loading from "../../../components/system/loading";
import { useAuth } from "../../../context/auth";
import { ENotificationType, useNotifications } from "../../../context/notifications";
import { IContact } from "../../../interface/contact";
import { ELogType, ESeverity } from "../../../interface/log";
import { IProduct } from "../../../interface/product";




const AdminContact = () => {
    let location = useLocation();
    const [formChanged, setFormChanged] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [redirect, setRedirect] = useState<boolean>(false);
    const [thing, setThing] = useState<IContact>();
    const { addNotification } = useNotifications()!;
    const { auth } = useAuth()!;

    {/*<!-- OPTIONAL 1 -->*/ }
    //const [clients, setClients] = useState<IClient[]>();
    const componentRef = useRef<HTMLDivElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    //const [response, setResponse] = useState<IConversation>({ requestId: "", message: "", imageId: '', productId:'' });
    const [products, setProducts] = useState<IProduct[]>();
    {/*<!-- OPTIONAL END -->*/ }

    const match: any = useRouteMatch("/admin/contacts/:id");

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser)
        window.addEventListener('unload', logOnUnload)
        return () => {
            window.removeEventListener('beforeunload', alertUser)
            window.removeEventListener('unload', logOnUnload)
            logOnUnload()
        }
    }, [])
    //log unload page?
    const logOnUnload = async () => {
        return RequestNewLog(undefined, {
            type: ELogType.reactUse,
            requestId: '',
            sessionId: '',
            userId: '',
            payload: '',
            severity: ESeverity.Trivial,
            text: 'react store onunload'
        });
    }
    //alert user if form data has changed
    const alertUser = (e: any) => {
        if (formChanged) {
            e.preventDefault()
            e.returnValue = true
        }
    }

    useEffect(() => {
        try {
            const id = match.params.id;
            console.log(id);
            if (loading === true) {


                {/*<!-- OPTIONAL 2 -->*/ }
                const products = () => {
                    return RequestGetProducts(addNotification, {}).then(thing => {
                        setProducts(thing);
                    })
                }
                if (id === 'new') {
                    setThing({
                        parts: [],
                        addressCity: '', addressCountry: 'GB', addressPostCode: '', addressStreet: '', addressTown: '', addressCounty: '', terms: false,
                        marketing: false,
                        manufacturerId: '', bikeId: '', otherManufacturer: '', otherBike: '', color: '', year: 0, addMaillist: false,
                        userId: '', id: 'new', email: '', phone: '', name: '', message: '', other: {}, requestId: '', conversation: [], status: 'new', type: 'contact'
                    })
                    Promise.allSettled([products()]).then(() => {
                        setLoading(false)
                    });
                } else {
                    const prodCall = () => {
                        return RequestGetContact(addNotification, id).then(thing => {
                            setThing(thing);
                        })
                    }

                    Promise.allSettled([products(), prodCall()]).then(() => {
                        setLoading(false)
                    });
                }

                {/*<!-- OPTIONAL END -->*/ }
            }
        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Contact - useEffect', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }



    }, [match, loading, location]);

    const onSubmit = (data: any) => {
        try {
            setLoading(true)
            setFormChanged(false)
            let ppp: Promise<IContact>;
            let notificationText: string;
            if (!data._id) {
                notificationText = 'Contact Created!';
                ppp = RequestNewContact(addNotification, data);
            } else {
                notificationText = 'Contact Saved!';
                ppp = RequestUpdateContact(addNotification, data);
            }

            ppp.then(thing => {
                console.log(thing);
                setThing(thing);
                addNotification && addNotification('Success', notificationText, ENotificationType.Primary);
                //  setRedirect(true);
                setLoading(false)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Contact - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }


    {/*<!-- OPTIONAL 3 -->*/ }

    const onMessage = (data: any) => {
        try {
            setLoading(true)
            //setConv({ requestId: "", message: "", imageId: '' });
            thing && RequestNewContactResponse(addNotification, thing, data).then(ccc => {
                setLoading(false)
                setThing(ccc)
            })
        } catch (ex) {
            setLoading(false)
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Contact - onSubmit', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }

    const onArchive = (value: IContact) => {
        try {

            setLoading(true);
            RequestSetContactStatus(addNotification, value, 'archived').then(res => {
                setThing(res)
                setLoading(false);
                addNotification && addNotification('Success', 'Contact Updated!', ENotificationType.Primary);
            });

        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Contacts - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }
    const onUnArchive = (value: IContact) => {
        try {

            setLoading(true);
            RequestSetContactStatus(addNotification, value, 'new').then(res => {
                setThing(res)
                setLoading(false);
                addNotification && addNotification('Success', 'Contact Updated!', ENotificationType.Primary);
            });

        } catch (ex) {
            RequestNewLog(addNotification, { type: ELogType.reactException, text: 'React try catch exception - Contacts - onDelete', payload: ex, requestId: '', sessionId: '', userId: '', severity: ESeverity.Issue })
            addNotification && addNotification('Error', 'Caught Error', ENotificationType.Warning);
        }
    }
    {/*<!-- OPTIONAL END -->*/ }


    return (<>
        <Prompt
            when={formChanged}
            message={() => 'Are you sure you want to leave this page?'}
        />
        {redirect && <Redirect to="../contacts" />}

        {(loading === false && thing) ? <>
            <Helmet>
                <title>Contact / Admin / {auth.metaData && auth.metaData.SYSTEM_NAME && auth.metaData.SYSTEM_NAME}</title>
            </Helmet>

            {/*<!-- OPTIONAL 4 -->*/}
            <div className="btn-group">
                <button type="button" className="btn btn-info" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    handlePrint && handlePrint();

                }}>
                    <FontAwesomeIcon icon={faPrint} size={'2x'} />
                </button>
                <DebugButton data={thing} />

                {thing.status !== 'archived' && <button className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    onArchive(thing);
                }}>Archive</button>}
                {thing.status === 'archived' && <button className="btn btn-success" onClick={(ev: React.MouseEvent) => {
                    ev.preventDefault();
                    onUnArchive(thing);
                }}>Un-Archive</button>}
                <Link className="btn btn-primary" to={`/conv/contact/${thing.id}`}>Public Link</Link>
            </div>
            <ConversationDisplay products={products} contact={thing} setContact={setThing} setProduct={(index, product) => {
                const ddd: any[] = [...thing.parts]
                //   console.log(index, product)
                if (ddd[index - 1]) {
                    ddd[index - 1].productId = product.id;
                    ddd[index - 1].product = product;
                }

                setThing({ ...thing, parts: ddd });
                onSubmit(thing);
                onMessage({ requestId: "", message: `We've found ${product.partNumber} please use this page to add to your cart`, imageId: '', productId: product.id });
            }} />
            <div style={{ display: 'none' }}>
                <div ref={componentRef}>
                    <ConversationDisplay printMode={true} products={products} contact={thing} setContact={setThing} setProduct={(index, product) => {
                        const ddd: any[] = [...thing.parts]
                        console.log(index, product)
                        if (ddd[index - 1]) {
                            ddd[index - 1].productId = product.id;
                            ddd[index - 1].product = product;
                        }

                        setThing({ ...thing, parts: ddd });
                        onSubmit(thing);
                        onMessage({ requestId: "", message: `We've found ${product.partNumber} please use this page to add to your cart`, imageId: '', productId: product.id });
                    }} />

                </div>
            </div>

            {/* <div className="text-left">
                <JSONPretty json={thing} />

            </div> */}

            {/*<!-- OPTIONAL END -->*/}
        </> : <Loading />}

    </>
    );
}

export default AdminContact;