import { IRequest } from "../interface/request";
import axiosInstance from "./instance";

export const RequestGetRequests = (addNotification: any, data?: any): Promise<IRequest[]> => {
    return new Promise<IRequest[]>((resolve, reject) => {
        let url = `/requests/`;
        if (data) {
            url += "?";
            for (let [key, value] of Object.entries(data)) {
                url += key + '=' + value + "&";
            }
        }
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

export const RequestGetRequest = (addNotification: any, id: string): Promise<IRequest> => {
    return new Promise<IRequest>((resolve, reject) => {

        axiosInstance(addNotification).get(`/requests/${id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            //  console.log(response);
            resolve(response)

        }).catch(exc => {
            //   console.log(exc);
            reject(exc);
        });
    })
}


export const RequestNewRequest = (addNotification: any, thing: IRequest): Promise<IRequest> => {
    return new Promise<IRequest>((resolve, reject) => {

        axiosInstance(addNotification).post(`/requests/`, thing).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestDeleteRequest = (addNotification: any, thing: IRequest): Promise<boolean> => {
    return new Promise<boolean>((resolve, reject) => {

        axiosInstance(addNotification).delete(`/requests/delete/${thing.id}`).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}
export const RequestUpdateRequest = (addNotification: any, user: IRequest): Promise<IRequest> => {
    return new Promise<IRequest>((resolve, reject) => {

        axiosInstance(addNotification).patch(`/requests/${user.id}`, user).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            console.log(response);
            resolve(response)

        }).catch(exc => {
            console.log(exc);
            reject(exc);
        });
    })
}

export const RequestRequestFunction = (addNotification: any, func:string, id: string): Promise<any[]> => {
    return new Promise<any[]>((resolve, reject) => {
        let url = `/requests/${func}/${id}`;
        
        axiosInstance(addNotification).get(url).then(response => {
            //   console.log(response);
            if (response === undefined) reject();
            return response.data;
        }).then(response => {
            // console.log(response);
            resolve(response)

        }).catch(exc => {
            // console.log(exc);
            reject(exc);
        });
    })
}

// eslint-disable-next-line
    {/*<!-- OPTIONAL 1 -->*/ }
    export const RequestNewRequestPublic = (addNotification: any, thing: IRequest): Promise<IRequest> => {
        return new Promise<IRequest>((resolve, reject) => {
    
            axiosInstance(addNotification).post(`/requests/public`, thing).then(response => {
                //   console.log(response);
                if (response === undefined) reject();
                return response.data;
            }).then(response => {
                console.log(response);
                resolve(response)
    
            }).catch(exc => {
                console.log(exc);
                reject(exc);
            });
        })
    }
    
        // eslint-disable-next-line
        {/*<!-- OPTIONAL END -->*/ }
    